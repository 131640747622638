import axios from "axios";
import { common_axios } from "../utils/AxiosSettings";

export async function getQCFeedbackFields(caseID, option) {
    let res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcreport/?case=${caseID}&option=${option}`
    );

    return res.data;
}

export async function submitQCReport(data) {
    let res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcreport/`,
        { ...data }
    );

    return res.data;
}
export async function getDraftQCDetails(userId, caseID) {
    let res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcdraftstatus/?user_id=${userId}&case_id=${caseID}`
    );

    return res.data;
}
export async function submitDraftQCReport(data) {
    let res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcdraftstatus/`,
        { ...data }
    );

    return res.data;
}

export async function updateDraftQCStatus(data) {
    let res = await common_axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcdraftstatus/`,
        { ...data }
    );

    return res.data;
}
export async function getQCcaseReport(caseID, version) {
    let url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcCaseDetails/?case=${caseID}`;

    if (version) {
        url += `&version=${version}`;
    }

    const res = await common_axios.get(url);

    return res;
}

export async function getQualityInspectionData(month, year, region) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcdashboard/?month=${month}&year=${year}&Region=${region}`
    );
    return res;
}
