import { Button, Divider, Modal, message } from "antd";
import React from "react";
import "../../../common.css";

const TourDescriptionModal = ({
    currentStep,
    setCurrentStep,
    title,
    description,
    handleTourFinish,
    endTour,
    showRole,
}) => {
    return (
        <div
            style={{
                marginTop: "-25px",
                textAlign: "left",
                padding: "0px 15px",
                maxHeight:
                    currentStep == 0 || currentStep == 7 ? "180px" : "153px",
            }}>
            <h3>{title}</h3>
            <Divider
                style={{
                    margin: "15px 0px",
                    borderTop: "1px solid #BEBEBE",
                }}
            />
            {description.map((desc) => (
                <p>{desc}</p>
            ))}
            <>
                {" "}
                <div style={{ textAlign: "right", marginTop: "10px" }}>
                    {currentStep > 0 && (
                        <button
                            type="secondary"
                            className="secondaryButton"
                            onClick={() => {
                                !showRole && currentStep == 7
                                    ? setCurrentStep(5)
                                    : setCurrentStep(currentStep - 1);
                            }}>
                            Previous
                        </button>
                    )}
                    <button
                        type="primary"
                        className="primaryButton"
                        onClick={() => {
                            currentStep < 7
                                ? !showRole && currentStep == 5
                                    ? setCurrentStep(7)
                                    : setCurrentStep(currentStep + 1)
                                : handleTourFinish();
                        }}>
                        {currentStep == 0
                            ? "Let's Go!"
                            : currentStep < 7
                            ? "Next"
                            : endTour
                            ? "Exit!"
                            : "Begin!"}
                    </button>
                </div>
            </>
        </div>
    );
};

export default TourDescriptionModal;
