import React, { useState } from "react";
import {
    Row,
    Col,
    Button,
    Upload,
    Modal,
    Table,
    Descriptions,
    message,
} from "antd";
import { common_axios } from "../../../utils/AxiosSettings";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import styles from "../Holiday.module.css";
import CaseReportBlock from "../../../components/Utils/CaseReportBlock";
import { useRecoilState } from "recoil";

import { selectedMonthYearRecoil } from "../../../RecoilState/HolidayCalender/selectedMonthYearRecoil";
import useEventMetricsUpdater from "../../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";

const BulkUploadCalender = ({ FactorySelected, setUploadFalse }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [file, setFile] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [totalHolidays, setTotalHolidays] = useState(0);
    const navigate = useNavigate();
    const [selectedMonthYear, setSelectedMonthYear] = useRecoilState(
        selectedMonthYearRecoil
    );
    const { updateEventMetrics } = useEventMetricsUpdater();
    const handleUploadFile = (method, eventName, actionType) => {
        // Perform file validation and processing here
        if (file != null) {
            setModalVisible(false);
            // Simulate API call
            const url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/bulkholidayupload/`;
            const formData = new FormData();
            formData.append("csv_file", file);
            formData.append("method", method);
            formData.append("Factory", FactorySelected);

            common_axios
                .post(url, formData)
                .then((response) => {
                    const holidays = response.data.message_body.Holidays;
                    const updatedHolidays = Object.values(holidays).map(
                        (holiday) => ({
                            ...holiday,
                            key: holiday.eventName,
                        })
                    );
                    setTotalEntries(updatedHolidays.length);
                    setTotalHolidays(
                        updatedHolidays.filter(
                            (item) => item.errorResponse === ""
                        ).length
                    );

                    setDataSource(updatedHolidays);
                    const successMessage = "The File was successfully uploaded";
                    //setting filter value as default current month and year
                    const currentDate = new Date();
                    const defaultMonthYear = currentDate
                        .toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                        })
                        .split("/")
                        .reverse()
                        .join("-");
                    setSelectedMonthYear(defaultMonthYear);

                    if (response.status === 201) {
                        const additionalMessage = response.data.message;
                        const combinedMessage = `${successMessage}. ${additionalMessage}`;
                        message.success(combinedMessage);
                        setUploadFalse();
                    } else if (response.status === 200) {
                        message.success(response.data.message);
                    } else {
                        const additionalMessage = response.data.message;
                        const combinedMessage = `${successMessage} and ${additionalMessage}`;
                        message.warning(combinedMessage);
                        setDataSource([]); // clearing the prev data state
                    }
                    updateEventMetrics(eventName, actionType);
                })
                .catch((error) => {
                    message.error(
                        `Error uploading file: ${error.response.data.errorMessage}`
                    );
                    //setting filter value as default current month and year
                    const currentDate = new Date();
                    const defaultMonthYear = currentDate
                        .toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                        })
                        .split("/")
                        .reverse()
                        .join("-");
                    setSelectedMonthYear(defaultMonthYear);
                    setDataSource([]); // clearing the prev data state
                });
        } else if (file === null) {
            message.error("Please Upload a file");
        }
    };

    const downloadSampleCSV = () => {
        const url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/sampleCSV`;

        common_axios
            .get(url, {
                responseType: "blob", // Important to specify the response type as 'blob' to download a file
            })
            .then((response) => {
                message.info("Sample CSV is being Downloded");
                // Create a temporary link element to trigger the file download
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute("download", "sample.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                // Handle any error that occurred during the request
                console.error("Error downloading sample CSV:", error);
            });
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const openModal = () => {
        setModalVisible(true);
    };

    const columns = [
        { title: "Event Name", dataIndex: "eventName" },
        { title: "Start Date", dataIndex: "startDate" },
        { title: "End Date", dataIndex: "endDate" },
        { title: "Error", dataIndex: "errorResponse" },
    ];
    const onFileChange = (info) => {
        const fileList = info.fileList;
        if (fileList.length > 0) {
            const file = fileList[0].originFileObj;
            setFile(file);
        } else {
            setFile(null);
        }
    };
    const debouncehandler = debounce(downloadSampleCSV, 500);
    const debouncedHandleUploadFile = debounce((action) => {
        handleUploadFile(action);
    }, 500);

    return (
        <div
            className={styles.mainContainer}
            style={{
                border: "1px solid #4318FF",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
            }}>
            <Row
                style={{
                    borderBottom: "1px solid #BEBEBE",
                    padding: "0 0 1rem 0",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    marginLeft: "2rem",
                }}>
                <Col flex={1} style={{ textAlign: "justify" }}>
                    <a onClick={setUploadFalse}>
                        <img src="/assets/images/back/Group4495.svg"></img>
                    </a>
                </Col>
                <Col
                    flex={22}
                    style={{
                        textAlign: "justify",
                        marginLeft: "1rem",
                    }}>
                    <h1>Bulk Data</h1>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12} style={{ width: "100%" }}>
                    <Row gutter={[0, 56]}>
                        <Col offset={1}>
                            <h3>Master Data</h3>
                        </Col>
                        <Col offset={3}>
                            <Button
                                style={{
                                    padding: "5rem 10rem 5rem 10rem",
                                    width: "100%",
                                    borderRadius: "5px",
                                    border: "1px solid #1B2559",
                                    height: "2.5rem",
                                }}
                                onClick={openModal}>
                                <UploadOutlined />
                                Upload File from Computer
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={[0, 56]}>
                        <Col offset={15}>
                            <button
                                onClick={debouncehandler}
                                className="secondaryButton">
                                <DownloadOutlined />
                                Download Sample Template
                            </button>
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={22}>
                            <CaseReportBlock
                                label="Total Entries:"
                                displayMessage={totalEntries}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={22}>
                            <CaseReportBlock
                                label="Total Holidays:"
                                displayMessage={totalHolidays}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        rowClassName={(record) => {
                            if (record.errorResponse != "") {
                                return "error-row";
                            }
                            return "";
                        }}
                        pagination={false}
                    />
                </Col>
            </Row>
            <Row className={styles.footer}>
                <Col>
                    <button
                        className="secondaryButton"
                        onClick={() => {
                            setUploadFalse();
                        }}>
                        Cancel
                    </button>
                </Col>
                <Col>
                    <button
                        onClick={() =>
                            debouncedHandleUploadFile(
                                "UPLOAD",
                                "BulkUplaodholidayCalendar",
                                "Bulk upload holiday calendar - Upload"
                            )
                        }
                        className="primaryButton">
                        <UploadOutlined /> Upload
                    </button>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                destroyOnClose={true}
                title="File Upload"
                width={900}
                footer={[
                    <button
                        key="cancel"
                        className="secondaryButton"
                        onClick={closeModal}>
                        Cancel
                    </button>,
                    <button
                        key="ok"
                        type="primary"
                        className="primaryButton"
                        onClick={() => handleUploadFile("ITERATE")}>
                        Confirm
                    </button>,
                ]}
                onCancel={closeModal}
                afterClose={closeModal}>
                <Row>
                    <Col offset={0}>
                        <h3>Master Data</h3>
                    </Col>
                    <Col style={{ width: "80%" }} offset={1}>
                        {
                            <Upload.Dragger
                                accept=".csv"
                                multiple={false}
                                maxCount={1}
                                beforeUpload={(file) => {
                                    const fileType = file.type;
                                    const isCSV =
                                        fileType === "text/csv" ||
                                        fileType === "application/vnd.ms-excel";
                                    if (!isCSV) {
                                        message.error(
                                            "Please upload a CSV file"
                                        );
                                        return false; // prevent automatic upload
                                    }
                                    if (file.length > 0) {
                                        message.error(
                                            "Only one file can be selected"
                                        );
                                        return false; // prevent automatic upload
                                    }
                                    setFile([file]);
                                    return false; // prevent automatic upload
                                }}
                                onChange={(info) => {
                                    const { status } = info.file;
                                    if (status === "removed") {
                                        setFile([]); // Clear the file state when the file is removed/closed
                                    }
                                    onFileChange(info);
                                }}>
                                <div>
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Click or drag file to this area to
                                        upload
                                    </p>
                                    <p className="ant-upload-hint">
                                        Support for a single upload.
                                    </p>
                                </div>
                            </Upload.Dragger>
                        }
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default BulkUploadCalender;
