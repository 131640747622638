import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { postUserMetricData } from "../Adapters/UserMetricCalls";
import { userMetricState } from "../RecoilState/userMerticState";
import { userState } from "../RecoilState/userState";
import { tokenState } from "../RecoilState/tokenState";
import { useCookies } from "react-cookie";
import { calculateSessionDuration } from "../utils/CalculateSessionDuration";
import {
    setNavigateMatricCallback,
    setRefreshTokenCallback,
} from "../utils/AxiosSettings";
import { message } from "antd";
const useLogout = () => {
    const [userMetricData, setUserMetricData] = useRecoilState(userMetricState);
    const resetUser = useResetRecoilState(userState);
    const resetToken = useResetRecoilState(tokenState);
    const [token, setToken] = useRecoilState(tokenState);
    const [roleCookie, , removeRoleCookie] = useCookies(["role-token-cookie"]);
    const [userCookie, setUserCookie, removeUserCookie] = useCookies([
        "user-token-cookie",
    ]);
    const [tokenCookie, setTokenCookie, removeTokenCookie] = useCookies([
        "token",
    ]);
    const handleLogout = async (logoutType) => {
        if (localStorage.getItem("flag") === "false") {
            localStorage.removeItem("flag");
            const logoutTime =
                logoutType === "Session Logout"
                    ? userMetricData?.userMetrics?.logoutTimestamp
                    : new Date().toUTCString();
            try {
                let updatedUserMetricState;
                if (localStorage.getItem("userMetricState")) {
                    updatedUserMetricState = {
                        ...userMetricData,
                        userMetrics: {
                            ...userMetricData?.userMetrics,
                            logoutType: logoutType,
                            logoutTimestamp: logoutTime,
                            sessionDuration: calculateSessionDuration(
                                userMetricData?.userMetrics.loginTimestamp,
                                logoutTime
                            ),
                        },
                    };
                }

                // Make API call to post user metric data
                await postUserMetricData(updatedUserMetricState);

                return updatedUserMetricState;
            } catch (error) {
                console.error("Error during logout:", error);
            } finally {
                resetUser();
                resetToken();
                removeRoleCookie("role-token-cookie", { path: "/" });
                removeUserCookie("user-token-cookie", { path: "/" });
                localStorage.removeItem("current-selected-role");
                localStorage.removeItem("available-role-cookie");
                localStorage.removeItem("userMetricState");
                setToken("");
                removeTokenCookie("token", { path: "/" });
                window.location.href = "/";
            }
        } else if (!localStorage.getItem("flag")) {
            message.error("Already logged out! Please log in again.");
            window.location.href = "/";
        }
    };
    const handleToken = (token) => {
        const jwtPayload = JSON.parse(
            window.atob(token?.refresh?.split(".")[1])
        );
        setToken({ ...token, expires: jwtPayload.exp });
        const userMetricDetails = {
            ...userMetricData,
            userMetrics: {
                ...userMetricData.userMetrics,
                logoutTimestamp: new Date(jwtPayload.exp * 1000).toUTCString(),
            },
        };
        setUserMetricData(userMetricDetails);
        localStorage.setItem(
            "userMetricState",
            JSON.stringify(userMetricDetails)
        );
    };
    setNavigateMatricCallback(handleLogout);
    setRefreshTokenCallback(handleToken);
    return { handleLogout, handleToken };
};
export default useLogout;
