import React, { useEffect, useState } from "react";
import styles from "../../IncentiveDashboard/Charts/Charts.module.css";
import { Line } from "@ant-design/plots";

export default function ComplainceChartRe({ caseData }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        caseData && setData(caseData);
    }, [caseData]);
    const COLOR_PLATE_10 = [
        "#5B8FF9",
        "#5AD8A6",
        "#5D7092",
        "#F6BD16",
        "#E8684A",
        "#6DC8EC",
        "#9270CA",
        "#FF9D4D",
        "#269A99",
        "#FF99C3",
    ];

    const config = {
        data,
        xField: "type",
        yField: "value",
        seriesField: "caseType",
        legend: { position: "top-right" },
        yAxis: {
            title: {
                text: "---------- Number of Cases ----------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                formatter: (v) =>
                    `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
            line: {
                style: {
                    lineWidth: 2,
                    stroke: "#13161b",
                },
            },
        },
        color: COLOR_PLATE_10,
        point: {
            shape: ({ caseType }) => {
                return caseType === "All Cases" ? "circle" : null;
            },
            style: ({ type, caseType }) => {
                return {
                    r: caseType === "All Cases" ? 4 : 0,
                };
            },
        },
        xAxis: {
            title: {
                text: " ----------------------------- Factories -----------------------------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                autoHide: false,
            },
            line: {
                style: {
                    lineWidth: 2,
                    stroke: "#13161b",
                },
            },
        },
    };
    return (
        <>
            <div
                className={styles.container + " " + styles.compliance}
                style={{ pageBreakinside: "avoid", pageBreakAfter: "always" }}
                id="compliance-chart">
                <h1>Compliance Variable Closure</h1>
                <Line {...config} />
            </div>
        </>
    );
}
