import React, { useEffect, useState } from "react";
import DynamicTable from "../../../Utils/DynamicTable/DynamicTable";
import _ from "lodash";
import { Button, Input, Select, Space } from "antd";
import DateTimeFormatter from "../../../Utils/DateTimeFormatter";
import useFetchRegion from "../RegionHooks/FetchRegionHook";
import DeleteRegionButton from "../RegionAction/DeleteRegion";
import { useRecoilState } from "recoil";
import { regionState } from "../../../../RecoilState/regionState";
import { useNavigate } from "react-router";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { userState } from "../../../../RecoilState/userState";

const RegionTableView = () => {
    const navigate = useNavigate();
    const [regions, setRegions] = useRecoilState(regionState);
    const [user] = useRecoilState(userState);
    const { fetchRegionData, data, loading, error } = useFetchRegion();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item, index) => ({
            text: formatter(item),
            value: formatter(item),
            key: `${formatter(item)}_${index}`,
        }));

    useEffect(() => {
        if (regions.length > 0) {
            setIsLoading(false);
        } else {
            setIsLoading(loading);
        }
    }, [loading]);
    useEffect(() => {
        if (regions.length === 0) {
            fetchRegionData();
        }
        if (data) {
            setRegions(data);
        }
    }, []);

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    const columns = [
        {
            title: "Region Name",
            dataIndex: "Name",
            key: "Name",
            filterMode: "tree",
            width: 150,
            filterSearch: true,
            sorter: (a, b) => a.Name - b.Name,
            filters: filterData(regions, (item) => item.Name),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Code = record.Name.toString().toLowerCase().trim();
                return Code.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Last Modified",
            dataIndex: "last_modified",
            key: "last_modified",
            width: 150,
            sorter: (a, b) => a.last_modified - b.last_modified,
            render: (text) => <DateTimeFormatter dateTimeString={text} />,
        },
        {
            title: "Factories",
            dataIndex: "Factories",
            key: "Factories",

            render: (factories) => factories.map((f) => f.Code).join(", "),
        },
        {
            title: "Regional Admin",
            dataIndex: "regionaAdmin",
            key: "regionaAdmin",
            width: 150,
            render: (admins) => admins.join(", "),
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.regionaAdmin - b.regionaAdmin,
            filters: filterData(data, (item) => item.regionaAdmin),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const regionaAdmin = record.regionaAdmin
                    .toString()
                    .toLowerCase()
                    .trim();
                return regionaAdmin.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Action",
            key: "action",
            width: 80,
            fixed: "right",
            render: (text, record) => (
                <Space>
                    <span
                        onClick={() =>
                            navigate(`/UpdateRegion`, {
                                state: {
                                    id: record.id,
                                },
                            })
                        }>
                        {user.role == "LEAD_SUPERVISOR" ? (
                            <EyeOutlined style={{ fontSize: "18px" }} />
                        ) : (
                            <EditOutlined style={{ fontSize: "18px" }} />
                        )}
                    </span>
                    {user?.group_permissions?.includes(
                        "change_factoryregion"
                    ) && (
                        <DeleteRegionButton
                            icon={<DeleteOutlined />}
                            region={record}
                        />
                    )}
                </Space>
            ),
        },
    ];

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return <DynamicTable data={regions} columns={columns} />;
};

export default RegionTableView;
