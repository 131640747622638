import { useRecoilState } from "recoil";
import { userMetricState } from "../RecoilState/userMerticState";
import { tokenState } from "../RecoilState/tokenState";
const useEventMetricsUpdater = () => {
    const [userMetric, setUserMetricState] = useRecoilState(userMetricState);
    const [token] = useRecoilState(tokenState);

    const updateEventMetrics = (eventName, actionType, dataName, eventData) => {
        if (!eventName || !actionType) {
            // eventName and actionType are required, do nothing if missing
            return;
        } else if (token?.expires >= parseInt(Date.now() / 1000)) {
            setUserMetricState((prevUserMetricState) => {
                const { eventMetrics } = prevUserMetricState;

                if (eventMetrics[eventName]) {
                    // Event already exists, add a new timestamp
                    const timestampedEvent = {
                        actionType,
                        timestamp: new Date().toUTCString(),
                        ...(eventData && { [dataName]: eventData }),
                    };

                    const updatedEvents = [
                        ...eventMetrics[eventName],
                        timestampedEvent,
                    ];

                    const updatedUserMetricState = {
                        ...prevUserMetricState,
                        eventMetrics: {
                            ...eventMetrics,
                            [eventName]: updatedEvents,
                        },
                    };

                    // Set the updated user metric state in local storage
                    localStorage.setItem(
                        "userMetricState",
                        JSON.stringify(updatedUserMetricState)
                    );

                    return updatedUserMetricState;
                } else {
                    // Event does not exist, add the whole new event
                    const timestampedEvent = {
                        actionType,
                        timestamp: new Date().toUTCString(),
                        ...(eventData && { [dataName]: eventData }),
                    };

                    const updatedUserMetricState = {
                        ...prevUserMetricState,
                        eventMetrics: {
                            ...eventMetrics,
                            [eventName]: [timestampedEvent],
                        },
                    };

                    // Set the updated user metric state in local storage
                    localStorage.setItem(
                        "userMetricState",
                        JSON.stringify(updatedUserMetricState)
                    );

                    return updatedUserMetricState;
                }
            });
        }
    };

    return { updateEventMetrics };
};

export default useEventMetricsUpdater;
