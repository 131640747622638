import React, { Suspense, useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";
import CaseCountList from "./CaseCountList/CaseCountList";
import styles from "./IncentiveDashboard.module.css";
import TeamStructure from "./TeamStructure/TeamStructure";
import InstructionSetComponent from "./InstructionSet/InstructionSet.jsx";
import axios from "axios";
import { DatePicker, Layout, Space, Spin } from "antd";
import { useRecoilState } from "recoil";
import { common_axios } from "../Home";
import { factoryState } from "../../RecoilState/FactoryState";
import { CalendarOutlined } from "@ant-design/icons";
import {
    getTotalCaseData,
    getWinnerOfFactoryByIncentive,
} from "../../Adapters/IncentiveCalls";
import TThreeChart from "./Charts/TThreeChart";
import AwarenessCaseCount from "./CaseCountList/AwarenessCaseCount";
import { GuidelinesModal } from "./Guidelines/GuidelinesModal";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { userState } from "../../RecoilState/userState";
import CollapsableMenu from "../CollapsableMenu";
import EligibilityCriterias from "./EligibilityCriterias/EligibilityCriterias.jsx";
import FactoryListMenu from "../FactoryListMenu";

import Dropdown from "../../@setproduct-ui/core/Dropdown";
import useEventMetricsUpdater from "../../hooks/useEventMetricsUpdater";
import { FactoryData } from "../../RecoilState/FactoryData.js";
import { debounce } from "lodash";

const { RangePicker } = DatePicker;
const QualityChart = React.lazy(() => import("./Charts/QualityChart"));
const ComplianceChart = React.lazy(() => import("./Charts/ComplianceChart"));
const ChartEligible = React.lazy(() => import("./Charts/ChartEligible"));
const dateFormat = "YYYY-MM-DD";

const calendarIcon = <CalendarOutlined style={{ fontSize: "28px" }} />;

export default function IncentiveDashboard() {
    const [startDate, setStartDate] = useState(null);
    const printRef = React.useRef();
    const [endDate, setEndDate] = useState(null);
    const [winner, setWinner] = useState(null);
    const [totalCases, setTotalCase] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [criteriaModalOpen, setCriteriaModalOpen] = useState(false);
    const [user] = useRecoilState(userState);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [FactoryDataList, setFactoryDataList] = useRecoilState(FactoryData);
    const [updateMerticFlag, setUpdateMerticFlag] = useState(false);

    // Factory API call
    useEffect(() => {
        //this api is getting all the data related to incentive, total case, eligible case , cases at various stages and cases at t3 stages
        FactorySelected &&
            user?.company_fk &&
            getTotalCaseData(
                startDate,
                endDate,
                FactorySelected.id,
                user?.company_fk
            ).then((res) => {
                setTotalCase(res.data);
            });

        getWinnerOfFactoryByIncentive(user?.company_fk).then((res) => {
            setWinner(res?.data);
        });
    }, [startDate, endDate, FactorySelected, user, updateMerticFlag]);
    const handleDateChange = (value) => {
        if (value) {
            setStartDate(value[0].format(dateFormat));
            setEndDate(value[1].format(dateFormat));
        }
    };

    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0,
    });

    const download = (
        image,
        { name = "Incentive report", extension = "jpg" } = {}
    ) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };
    const downloadScreenshot = (eventName, actionType) => {
        takeScreenShot(printRef.current).then(download);
        updateEventMetrics(eventName, actionType);
    };
    const debouncehandler = debounce(downloadScreenshot, 500);
    return (
        <>
            <Layout>
                <CollapsableMenu></CollapsableMenu>
                <div
                    id="element-to-print"
                    ref={printRef}
                    className={styles.incentiveContainer}>
                    <div className={styles.boxOne}>
                        <div className={styles.leftBoxOne}>
                            <div className={styles.wrapperBoxOne}>
                                <div id="IncentiveDashboard">
                                    <h1>Incentive Dashboard</h1>
                                </div>
                                {totalCases?.FactorySelected?.eligible ===
                                true ? (
                                    <div className={styles.eligbleHeading}>
                                        <span>
                                            <img
                                                style={{ width: "20px" }}
                                                src="/assets/images/Incentive/eligible.svg"
                                            />
                                        </span>
                                        <p>Eligible</p>
                                    </div>
                                ) : (
                                    <div className={styles.eligbleHeading}>
                                        <span>
                                            <img
                                                style={{ width: "20px" }}
                                                src="/assets/images/Incentive/ineligible.svg"
                                            />
                                        </span>
                                        <p>Ineligible</p>
                                    </div>
                                )}

                                {user?.role === "SUPER_ADMIN" && (
                                    <button
                                        className={styles.editCriteria}
                                        onClick={() =>
                                            setCriteriaModalOpen(true)
                                        }>
                                        <span>
                                            <img
                                                style={{
                                                    width: "20px",
                                                    paddingRight: "5px",
                                                }}
                                                src="/assets/images/Incentive/settings.svg"
                                            />
                                        </span>

                                        <p>Eligibility Criterias </p>
                                    </button>
                                )}
                            </div>
                            <div
                                className={styles.wrapperBoxOne}
                                style={{ marginTop: "2rem" }}>
                                <div className={styles.dateBox2}>
                                    <div className={styles.calenderSymbol}>
                                        <CalendarOutlined
                                            style={{ fontSize: "28px" }}
                                        />
                                    </div>
                                    <div className={styles.rangePickerStyle}>
                                        <RangePicker
                                            onChange={handleDateChange}
                                            bordered={false}
                                            size="large"
                                            suffixIcon={false}
                                            className="antd-calendar"
                                            dropdownClassName="antd-dropDate"
                                            dateFormat={dateFormat}
                                        />
                                    </div>
                                </div>
                                {(user.role === "SUPER_ADMIN" ||
                                    user.role === "LEAD_SUPERVISOR" ||
                                    user.role === "REGIONAL_ADMIN") && (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginLeft: "1rem",
                                        }}>
                                        {FactoryDataList ? (
                                            <Dropdown
                                                style={{
                                                    height: "fit-Content",
                                                    padding: "1rem",
                                                }}
                                                view="filled"
                                                color="primary"
                                                text={
                                                    FactorySelected
                                                        ? `${FactorySelected?.Code} - ${FactorySelected?.Location}`
                                                        : `${FactoryDataList[0]?.Code} - ${FactoryDataList[0]?.Location}`
                                                }
                                                position="bottom-left"
                                                content={
                                                    <FactoryListMenu
                                                        factoryData={
                                                            FactoryDataList
                                                        }
                                                    />
                                                }
                                                dense="false"
                                                className={styles.dropdown}
                                            />
                                        ) : (
                                            <Spin />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.rightBoxOne}>
                            <div className={styles.winner}>
                                <h1>
                                    {winner?.winner
                                        ? ` 🥳 ${winner?.month} Winner Unit - ${winner.winner}`
                                        : `No Winner in ${winner?.month}`}
                                </h1>
                            </div>
                            <div className={styles.btnWrapperBoxOne}>
                                <button
                                    className={styles.guidlines}
                                    onClick={() => setModalOpen(true)}>
                                    <img src="/assets/images/Incentive/alert-circle.svg" />
                                    <p>Guidelines</p>
                                </button>

                                <button
                                    onClick={() =>
                                        debouncehandler(
                                            "incentivesDownloadReport",
                                            "Incentives Download report"
                                        )
                                    }
                                    className={styles.download}
                                    id="button-download">
                                    <img src="/assets/images/Incentive/download.svg" />
                                    <p> Download report</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.boxTwo}>
                        <div className={styles.caseCount}>
                            <div>
                                <h5>
                                    {Number(totalCases?.Cases?.Resolved) +
                                        Number(totalCases?.Cases?.Closed) +
                                        Number(totalCases?.Cases?.InProcess)}
                                </h5>
                                <div>Total number of cases</div>
                            </div>
                            <div className={styles.caseBox}>
                                <CaseCountList caseData={totalCases?.Cases} />
                            </div>
                        </div>
                        <div className={styles.caseCount}>
                            <div>
                                <h5>{totalCases?.Awareness?.programs}</h5>
                                <div> Awareness Programe Initiatives</div>
                            </div>
                            <div className={styles.caseBox}>
                                <AwarenessCaseCount
                                    caseData={totalCases?.Awareness}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.left}></div>
                    <div id="chart-grid" className={styles.chartOne}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <ChartEligible caseData={totalCases?.Eligible} />
                        </Suspense>
                    </div>
                    <div id="chart-grid" className={styles.chartTwo}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <ComplianceChart caseData={totalCases} />
                        </Suspense>
                    </div>
                    <div id="chart-grid" className={styles.chartTwo}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <TThreeChart caseData={totalCases} />
                        </Suspense>
                    </div>
                    <div id="chart-grid" className={styles.chartThree}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <QualityChart caseData={totalCases} />
                        </Suspense>
                    </div>
                </div>
                <GuidelinesModal
                    metricData={totalCases?.metrics}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                />
                {user?.role === "SUPER_ADMIN" && (
                    <EligibilityCriterias
                        criteriaModalOpen={criteriaModalOpen}
                        setCriteriaModalOpen={setCriteriaModalOpen}
                        setUpdateMerticFlag={setUpdateMerticFlag}
                        updateEventMetrics={updateEventMetrics}
                    />
                )}
            </Layout>
        </>
    );
}
