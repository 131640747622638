import axios from "axios";

//no authentication required for these apis
export function ResetPasswordFinalWithToken(Token, Uidb64, passwordInput) {
    const res = axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/password-reset-complete/`,
        {
            token: Token,
            uidb64: Uidb64,
            password: passwordInput,
        }
    );
    return res;
}
export function ResetLinkSendByEmail(email) {
    const res = axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/request-reset-email/`,
        {
            email: email,
        }
    );
    return res;
}
