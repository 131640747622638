import React, { useEffect, useState } from "react";
import { Collapse, Divider, Spin, message } from "antd";
import styles from "../CaseReport.module.css";
import { useRecoilState } from "recoil";
import { getQCFeedbackFields, submitQCReport } from "../../../Adapters/QCcalls";
import CompleteAuditModal from "../../../components/QualityChecker/Modals/CompleteAuditModal";
import { QCCaseReportDataState } from "../../../RecoilState/QCCaseReportDataState";
import { useNavigate } from "react-router";
import FeedbackRatings from "./FeedbackRatings";
import ReasonRemarksDetails from "./ReasonRemarksDetails";

export default function QCForm({
    readOnly,
    status,
    openCompleteAction,
    setOpenCompleteAction,
    QCcaseData,
    generalReport,
}) {
    const navigate = useNavigate();
    const [caseData] = useRecoilState(QCCaseReportDataState);
    const [checkedList, setCheckedList] = useState(QCcaseData?.Reason || []);

    const [error, setError] = useState([]);
    const [remark, setRemark] = useState(QCcaseData?.Remark || "");
    const [rateFields, setRateFields] = useState();
    const [polarFields, setPolarFields] = useState();
    const [selectedPolarChoices, setSelectedPolarChoices] = useState();
    const header = [
        {
            value: "Very Bad",
            src: "/assets/images/QCReport/veryBad.svg",
            label: "Very Bad",
        },
        {
            value: "Bad",
            src: "/assets/images/QCReport/Bad.svg",
            label: "Bad",
        },
        {
            value: "Neutral",
            src: "/assets/images/QCReport/Neutral.svg",
            label: "Neutral",
        },
        {
            value: "Good",
            src: "/assets/images/QCReport/Good.svg",
            label: "Good",
        },
        {
            value: "Excellent",
            src: "/assets/images/QCReport/Excellent.svg",
            label: "Excellent",
        },
    ];
    const onRemarkChange = (e) => {
        setRemark(e.target.value);
    };
    const [reasonOptions, setReasonOptions] = useState();

    const [selectedRateChoices, setSelectedRateChoices] = React.useState(
        QCcaseData?.Rate || {}
    );
    const [otherReason, setOtherReason] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCheckedList(QCcaseData?.Reason || []);
        setRemark(QCcaseData?.Remark || "");
        setSelectedRateChoices(QCcaseData?.Rate || {});
        setSelectedPolarChoices(QCcaseData?.Polar || {});
    }, [QCcaseData]);

    useEffect(() => {
        setLoading(true);
        if (caseData?.case?.id && status && status != "Pending" && !readOnly) {
            getQCFeedbackFields(caseData?.case?.id, status.toLowerCase())
                .then((res) => {
                    if (res) {
                        setRateFields(res.message_body.Rate);
                        setPolarFields(res.message_body.Polar);
                        res.message_body.Reason
                            ? setReasonOptions(() => {
                                  let reason = [];
                                  res.message_body.Reason.forEach((element) => {
                                      reason.push(element.description);
                                  });
                                  return reason;
                              })
                            : setReasonOptions();
                        if (!readOnly) {
                            setCheckedList([]);
                            setRemark("");
                            setOtherReason("");
                            setSelectedRateChoices({});
                            setSelectedPolarChoices({});
                        }
                    }
                })
                .catch((err) => {})
                .finally(() => {
                    setLoading(false);
                });
        } else if (readOnly) {
            setRateFields((prev) => {
                const rate = [];
                QCcaseData?.Rate &&
                    Object.keys(QCcaseData?.Rate).map((item, index) => {
                        rate.push({
                            description: item,
                            mandatory: false,
                        });
                    });
                return rate;
            });

            if (QCcaseData?.Reason.length > 0) {
                setReasonOptions([]);
            }
            setLoading(false);
        }
    }, [status, caseData?.case?.id]);

    const handleCheckboxChange = (question, choice) => {
        if (choice == 0) {
            setSelectedRateChoices(() => {
                const temp = { ...selectedRateChoices };
                delete temp[question];
                return temp;
            });
        } else {
            setSelectedRateChoices({
                ...selectedRateChoices,
                [question]: choice,
            });
        }
    };
    const onChange = (list) => {
        if (reasonOptions.includes("Others") && !list.includes("Others")) {
            setOtherReason("");
        }
        setCheckedList(list);
    };
    const onReasonChange = (e) => {
        setOtherReason(e.target.value);
    };

    const submitReport = async () => {
        let errors = [];
        let reasonList = [...checkedList];
        rateFields.map((element, index) => {
            if (
                element.mandatory &&
                !selectedRateChoices[element.description]
            ) {
                errors.push("Rate");
            }
        });
        polarFields.map((element, index) => {
            if (
                element.mandatory &&
                !selectedPolarChoices[element.description]
            ) {
                errors.push("Polar");
            }
        });

        if (checkedList.includes("Others") && otherReason != "") {
            reasonList[reasonList.indexOf("Others")] = otherReason;
        } else if (reasonOptions) {
            if (
                (checkedList.includes("Others") && checkedList.length == 1) ||
                checkedList == 0
            )
                errors.push("Reason");
        }
        if (errors.length == 0) {
            try {
                const report = await submitQCReport({
                    case: caseData?.case?.id,
                    reportType: status,
                    Rate: selectedRateChoices,
                    Polar: selectedPolarChoices,
                    Reason: reasonList,
                    Remark: remark,
                });

                message.success(report.message);
                navigate(-1);
            } catch (err) {
                if (err.response?.status == 400) {
                    console.log(err.reponse.data);
                }
            }
        } else {
            setError(errors);
        }
        setOpenCompleteAction(false);
    };
    return (
        <>
            {openCompleteAction && (
                <CompleteAuditModal
                    open={openCompleteAction}
                    setOpen={setOpenCompleteAction}
                    status={status}
                    submitReport={submitReport}
                />
            )}
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <Spin />
                </div>
            ) : generalReport ? (
                <>
                    <div className={styles.generalReport}>
                        <h3 style={{ marginBottom: "20px" }}>
                            QC{" "}
                            {caseData?.previous_qcreport?.qcreport_version < 10
                                ? `0${caseData?.previous_qcreport?.qcreport_version}`
                                : caseData?.previous_qcreport
                                      ?.qcreport_version}{" "}
                            Report
                        </h3>
                        <ReasonRemarksDetails
                            reasonOptions={reasonOptions}
                            readOnly={readOnly}
                            checkedList={checkedList}
                            onChange={onChange}
                            otherReason={otherReason}
                            onReasonChange={onReasonChange}
                            error={error}
                            remark={remark}
                            onRemarkChange={onRemarkChange}
                        />
                    </div>
                    <Collapse
                        items={[
                            {
                                key: "1",
                                label: <h3>Feedback Ratings</h3>,
                                children: (
                                    <div style={{ margin: "10px" }}>
                                        <Divider
                                            className={styles.caseRepDivider}
                                        />
                                        <FeedbackRatings
                                            wrapper={true}
                                            readOnly={readOnly}
                                            header={header}
                                            polarFields={polarFields}
                                            selectedRateChoices={
                                                selectedRateChoices
                                            }
                                            rateFields={rateFields}
                                            selectedPolarChoices={
                                                selectedPolarChoices
                                            }
                                            setSelectedPolarChoices={
                                                setSelectedPolarChoices
                                            }
                                            error={error}
                                            handleCheckboxChange={
                                                handleCheckboxChange
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                        bordered={false}
                        defaultActiveKey={["0"]}
                        expandIconPosition={"end"}
                        style={{ backgroundColor: "#f4f7fe" }}
                    />
                </>
            ) : (
                <>
                    <FeedbackRatings
                        readOnly={readOnly}
                        header={header}
                        polarFields={polarFields}
                        selectedRateChoices={selectedRateChoices}
                        rateFields={rateFields}
                        selectedPolarChoices={selectedPolarChoices}
                        setSelectedPolarChoices={setSelectedPolarChoices}
                        error={error}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                    <ReasonRemarksDetails
                        reasonOptions={reasonOptions}
                        readOnly={readOnly}
                        checkedList={checkedList}
                        onChange={onChange}
                        otherReason={otherReason}
                        onReasonChange={onReasonChange}
                        error={error}
                        remark={remark}
                        onRemarkChange={onRemarkChange}
                    />
                </>
            )}
            <Divider className={styles.caseRepDivider} />
        </>
    );
}
