import React from "react";
import { useRecoilState } from "recoil";
import { factoryState } from "../../../RecoilState/FactoryState";
import styles from "./CaseCountList.module.css";

export default function AwarenessCaseCount({ caseData }) {
    const caseItems = [
        { caseCount: caseData?.minRequired, text: "Min Required" },
        {
            caseCount: caseData?.programs,
            text: "Completed",
        },
    ];

    return (
        <>
            {caseItems.map((item, index) => {
                let countCases = 0;
                if (item.text == "Min Required") {
                    countCases = item.caseCount;
                } else if (item.text == "Completed") {
                    countCases = item.caseCount;
                }
                return (
                    <div className={styles.item}>
                        <h3>{countCases}</h3>
                        <p>{item.text}</p>
                    </div>
                );
            })}
        </>
    );
}
