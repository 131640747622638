import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UnsubscribeUserViaEmail } from "../../../Adapters/userCalls";
import { message } from "antd";

const UnsubscribePage = () => {
    const [confirmed, setConfirmed] = useState(false);
    const [id, setId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const idParam = queryParams.get("id");
        setId(idParam);
    }, [location.search]);

    const handleConfirm = async () => {
        try {
            await UnsubscribeUserViaEmail(id);
            setConfirmed(true);
        } catch (error) {
            message.error("Error unsubscribing user");
        }
    };

    const handleCancel = () => {
        navigate("/");
    };

    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "70vh",
                    display: "flex",
                    color: "black",
                    justifyContent: "center",
                    padding: "50px",
                    textAlign: "left",
                }}>
                <div
                    style={{
                        width: "50%",
                    }}>
                    {!confirmed ? (
                        <div>
                            <h2>Are you sure you want to unsubscribe?</h2>

                            <p>
                                By unsubscribing, you'll no longer receive
                                important updates, notifications, and reminders.
                                This means you might miss out on:
                            </p>
                            <ul
                                style={{
                                    listStyleType: "disc",
                                    paddingLeft: "20px",
                                }}>
                                <li>
                                    <b> Time-sensitive information</b> like
                                    deadlines or upcoming events.
                                </li>
                                <li>
                                    <b>Updates</b> about new or in-progress
                                    cases.
                                </li>
                            </ul>
                            <p>
                                If you're certain you want to unsubscribe,
                                please click "Confirm."
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}>
                                <a
                                    href="https://mail.google.com/"
                                    target="_blank">
                                    <button
                                        className="secondaryButton"
                                        onClick={handleCancel}
                                        style={{ marginRight: "10px" }}>
                                        Cancel
                                    </button>
                                </a>
                                <button
                                    className="primaryButton"
                                    onClick={handleConfirm}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h2>You've paused email notifications.</h2>
                            <p>
                                To resume receiving emails, please follow these
                                steps:
                            </p>
                            <ul
                                style={{
                                    listStyleType: "disc",
                                    paddingLeft: "20px",
                                }}>
                                <li>
                                    Go to <b>"My Profile"</b> You can find this
                                    option in the top right corner of the
                                    website or app.
                                </li>
                                <li>
                                    Enable <b>"Email Notifications"</b> Locate
                                    the toggle button for email notifications
                                    and switch it to the "On" position.
                                </li>
                            </ul>
                            <p>
                                Once you've completed these steps, you'll start
                                receiving email updates again.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default UnsubscribePage;
