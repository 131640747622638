import { useState, useEffect } from "react";
import React from "react";
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
    Select,
    Switch,
    Button,
    message,
    Spin,
    Alert,
    InputNumber,
} from "antd";

import { useRecoilState } from "recoil";
import common_axios from "axios";
import { getAllFactoriesOfCompany } from "../../../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../../../RecoilState/tokenState";
import { userState } from "../../../../RecoilState/userState";
import { useNavigate } from "react-router";
import styles from "../../AdminDashboard.module.css";
import { availableRoles } from "../../../../RecoilState/availableRoles";
import { usersList } from "../../../../RecoilState/usersList";
import { factoryState } from "../../../../RecoilState/FactoryState";
import {
    addPlusAssignUser,
    getAllRoles,
    putNewUser,
} from "../../../../Adapters/AdminCalls";
import ExistingDashboardSelector from "../../UserDashboard/UserAction/EditUser/Components/DashboardSelectorComponent/ExistingDashboardSelector";
import { useFetchUserData } from "../../UserDashboard/UserAction/UserHooks/useFechUserDataAdmin";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";

const { Option } = Select;
const CreateUser = ({ regionData }) => {
    const [token, setToken] = useRecoilState(tokenState);
    const [user, setUser] = useRecoilState(userState);
    const [assignedRoles, setAssignedRoles] = useRecoilState(availableRoles);
    const [userDataForm] = Form.useForm();
    const [FactoryList, setFactoryList] = useState([]);

    const [isFormChanged, setIsFormChanged] = useState(false);
    const [dashboardOption, setDashboardOption] = useState("new");
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardEmail, setDashboardEmail] = useState("");
    const [dashboardUser, setDashboardUser] = useState([]);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [phoneNoValidateStatus, setphoneNoValidateStatus] = useState(null);
    const [phoneNoErrorMsg, setPhoneNoErrorMsg] = useState(null);
    const [PhoneNo, setPhoneNo] = React.useState("");
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [stateforRoleFactory, setStateforRoleFactory] = useState({
        role: null,
        factory_fk: null,
    });
    let navigate = useNavigate();
    const genderList = ["Male", "Female", "Others"];
    const { fetchUserData } = useFetchUserData();
    useEffect(() => {
        if (user.company_fk != undefined) {
            getAllFactoriesOfCompany(
                user.company_fk,
                token.access,
                user.role === "REGIONAL_ADMIN" && "region"
            ).then((res) => {
                setFactoryList(res.data.message_body.Factories);
            });
        }
    }, [user.role, user.company_fk]);

    useEffect(() => {
        setStateforRoleFactory({
            role: userDataForm.getFieldValue("Role"),
            factory_fk: userDataForm.getFieldValue("Factory"),
        });
    }, [
        userDataForm.getFieldValue("Role"),
        userDataForm.getFieldValue("Factory"),
    ]);

    useEffect(() => {
        const fetchRoles = async () => {
            if (token.access) {
                try {
                    const res = await getAllRoles(token, "Add User");

                    setAssignedRoles(res.data.Roles);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchRoles();
        if (user?.role === "FACTORY_ADMIN") {
            userDataForm.setFieldsValue({
                Factory: user?.factory_fk,
                Region: user?.region,
            });
        }
    }, []);

    useEffect(() => {
        // If FactorySelected already has a value, do nothing
        if (FactorySelected) return;

        // If user is SUPER_ADMIN or REGIONAL_ADMIN and FactorySelected is not set
        if (user.role === "SUPER_ADMIN" || user.role === "REGIONAL_ADMIN") {
            setFactorySelected(FactoryList[0]);
        } else {
            // Otherwise, set FactorySelected based on the user's factory_fk
            const selectedFactory =
                FactoryList &&
                FactoryList?.find((f) => f.id === user.factory_fk);
            if (selectedFactory) {
                setFactorySelected(selectedFactory);
            }
        }
    }, [FactoryList, user]);

    useEffect(() => {
        const factoryId = userDataForm.getFieldValue("Factory");
        if (factoryId) {
            const foundFactory = FactoryList.find((f) => f.id === factoryId);
            if (foundFactory) {
                userDataForm.setFieldsValue({
                    Region: foundFactory.region,
                });
            }
        }
    });

    useEffect(() => {
        if (userDataForm.getFieldValue("Role") === "SUPER_ADMIN") {
            userDataForm.setFieldsValue({
                hasAccess_Awareness_Program: true,
                hasAccess_BroadCast_Message: true,
                hasAccess_Holiday_Calender: true,
            });
        } else if (userDataForm.getFieldValue("Role") === "REGIONAL_ADMIN") {
            userDataForm.setFieldsValue({
                hasAccess_Awareness_Program: false,
                hasAccess_BroadCast_Message: true,
                hasAccess_Holiday_Calender: true,
            });
        } else if (
            userDataForm.getFieldValue("Role") === "FACTORY_ADMIN" ||
            userDataForm.getFieldValue("Role") === "CM"
        ) {
            userDataForm.setFieldsValue({
                hasAccess_Awareness_Program: false,
                hasAccess_BroadCast_Message: false,
                hasAccess_Holiday_Calender: false,
            });
        } else if (
            userDataForm.getFieldValue("Role") === "CR" ||
            userDataForm.getFieldValue("Role") === "CT" ||
            userDataForm.getFieldValue("Role") === "LEAD_SUPERVISOR"
        ) {
            userDataForm.setFieldsValue({
                hasAccess_Awareness_Program: false,
                hasAccess_BroadCast_Message: false,
                hasAccess_Holiday_Calender: false,
            });
        }
    }, [userDataForm.getFieldValue("Role")]);

    useEffect(() => {
        if (
            userDataForm.getFieldValue("Role") === "DESIGNATED_QC" ||
            userDataForm.getFieldValue("Role") === "LEAD_SUPERVISOR"
        ) {
            setDashboardOption("new");
        }
    }, [dashboardOption, userDataForm.getFieldValue("Role")]);

    function handleUserDataFormSubmit(eventName, actionType) {
        if (isFormChanged) {
            setIsLoading(true);
            const submitData = {
                user_name: userDataForm.getFieldValue("username"),
                name: userDataForm.getFieldValue("Name"),
                email: userDataForm.getFieldValue("Email"),
                role: userDataForm.getFieldValue("Role"),
                factory_fk: userDataForm?.getFieldValue("Factory"),
                company_fk: user.company_fk,
                gender: userDataForm.getFieldValue("Gender"),
                mobile_number: userDataForm.getFieldValue("phoneNo"),
            };
            if (
                userDataForm.getFieldValue("Role") === "REGIONAL_ADMIN" ||
                userDataForm.getFieldValue("Role") === "SUPER_ADMIN"
            ) {
                submitData.user_permissions = {
                    hasAccess_Awareness_Program: "false", //though the Regional admin will get all permissions
                    hasAccess_BroadCast_Message: "false",
                    hasAccess_Holiday_Calender: "false",
                };
                submitData.region_fk = userDataForm.getFieldValue("Region");
            } else if (
                userDataForm.getFieldValue("Role") === "FACTORY_ADMIN" ||
                userDataForm.getFieldValue("Role") === "CM" ||
                userDataForm.getFieldValue("Role") === "LEAD_SUPERVISOR"
            ) {
                submitData.user_permissions = {
                    hasAccess_Awareness_Program: "false",
                    hasAccess_BroadCast_Message: "false",
                    hasAccess_Holiday_Calender: "false",
                };
            } else if (
                userDataForm.getFieldValue("Role") === "CT" ||
                userDataForm.getFieldValue("Role") === "CR"
            ) {
                submitData.user_permissions = {
                    hasAccess_Awareness_Program: userDataForm.getFieldValue(
                        "hasAccess_Awareness_Program"
                    )
                        ? "true"
                        : "false",
                    hasAccess_BroadCast_Message: "false",
                    hasAccess_Holiday_Calender: "false",
                };
            } else {
                submitData.user_permissions = {
                    hasAccess_Awareness_Program: userDataForm.getFieldValue(
                        "hasAccess_Awareness_Program"
                    )
                        ? "true"
                        : "false",
                    hasAccess_BroadCast_Message: userDataForm.getFieldValue(
                        "hasAccess_BroadCast_Message"
                    )
                        ? "true"
                        : "false",
                    hasAccess_Holiday_Calender: userDataForm.getFieldValue(
                        "hasAccess_Holiday_Calender"
                    )
                        ? "true"
                        : "false",
                };
            }

            if (dashboardOption === "existing") {
                const fromDetails = dashboardUser.roles.find(
                    (u) => u.role === userDataForm.getFieldValue("Role")
                );
                // Adjusting the structure for addPlusAssignUser
                const formattedDataForAddPlusAssign = {
                    to: submitData,
                    from: fromDetails.id,
                };
                addPlusAssignUser(formattedDataForAddPlusAssign)
                    .then((response) => {
                        setIsLoading(false);
                        if (response.data?.data?.message) {
                            message.success(response.data.data.message);
                            fetchUserData();
                            navigate("/AdminTableView?key=3");
                        } else if (response?.data.errorMessage) {
                            message.error(response.data.errorMessage);
                            fetchUserData();
                        } else {
                            message.error(
                                "There was a problem while creating the user"
                            );
                            fetchUserData();
                        }
                    })
                    .catch(handleError); // Error handling function call
            } else {
                putNewUser(submitData)
                    .then((response) => {
                        setIsLoading(false);
                        message.success(response.data.message);
                        fetchUserData();
                        navigate("/AdminTableView?key=3");
                    })
                    .catch(handleError);
            }
            updateEventMetrics(eventName, actionType);
            // Error handling function
            function handleError(error) {
                if (
                    error.response?.data?.errorMessage?.email ===
                    "base user model with this email already exists."
                ) {
                    setIsLoading(false);
                    message.error("This User already exists");
                    const errormsg = ["This User already exists"];
                    userDataForm.setFields([
                        {
                            name: "Email",
                            errors: errormsg,
                        },
                    ]);
                } else if (
                    error?.response?.data?.errorMessage?.user_name ===
                    "base user model with this user name already exists."
                ) {
                    setIsLoading(false);
                    message.error("User with this Username already exists");
                    const errormsg = ["User with this Username already exists"];
                    userDataForm.setFields([
                        {
                            name: "username",
                            errors: errormsg,
                        },
                    ]);
                } else {
                    setIsLoading(false);
                }
            }
        }
    }

    function handleFormChange() {
        setIsFormChanged(true);
    }

    const handleDashboardOptionChange = (e) => {
        const newValue = e.target.value;
        const userRole = userDataForm.getFieldValue("Role");

        if (["DESIGNATED_QC", "LEAD_SUPERVISOR"].includes(userRole)) {
            setDashboardOption("new");
        } else {
            setDashboardOption(newValue);
        }
    };

    const handleNumberChange = (value) => {
        setPhoneNo(value);
        if (value !== null) {
            const phoneNoValidateStatus = value?.toString().length === 10;
            if (phoneNoValidateStatus) {
                setphoneNoValidateStatus("success");
                setPhoneNoErrorMsg(null);
            } else {
                setphoneNoValidateStatus("error");
                setPhoneNoErrorMsg("Enter Mobile No of 10 Digits");
            }
        } else {
            setphoneNoValidateStatus("success");
            setPhoneNoErrorMsg(null);
        }
    };

    return (
        <>
            {" "}
            {isLoading && <Spin />}
            <div className={isLoading ? ` ${styles.blur}` : null}>
                <Form
                    name="User Detail Form"
                    form={userDataForm}
                    onValuesChange={handleFormChange}
                    onFinish={() =>
                        handleUserDataFormSubmit(
                            "createUser",
                            "Add User - Create User "
                        )
                    }>
                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Email "
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please enter the Email Address",
                                    },
                                    {
                                        type: "email",
                                        message:
                                            "Please enter valid Email Address",
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Region"
                                name="Region"
                                rules={[
                                    {
                                        required:
                                            user.role === "REGIONAL_ADMIN" ||
                                            userDataForm.getFieldValue(
                                                "Role"
                                            ) === "LEAD_SUPERVISOR"
                                                ? false
                                                : true,
                                        message: "Please select a Region",
                                    },
                                ]}>
                                <Select
                                    defaultValue={
                                        user.role === "REGIONAL_ADMIN" ||
                                        user.role === "FACTORY_ADMIN"
                                            ? user?.region
                                            : ""
                                    }
                                    disabled={
                                        user.role === "REGIONAL_ADMIN" ||
                                        user.role === "FACTORY_ADMIN" ||
                                        userDataForm.getFieldValue("Role") ===
                                            "LEAD_SUPERVISOR" ||
                                        [
                                            "CR",
                                            "CM",
                                            "CT",
                                            "FACTORY_ADMIN",
                                        ].includes(
                                            userDataForm.getFieldValue("Role")
                                        )
                                    }>
                                    {regionData.map((region) => (
                                        <Option
                                            key={region.id}
                                            value={region.id}>
                                            {region.Name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="User Name"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the Username",
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9]+$/,
                                        message:
                                            "Username must be alphanumeric",
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Factory"
                                name="Factory"
                                rules={[
                                    {
                                        required: ![
                                            "REGIONAL_ADMIN",
                                            "DESIGNATED_QC",
                                            "LEAD_SUPERVISOR",
                                        ].includes(
                                            userDataForm.getFieldValue("Role")
                                        ),
                                        message:
                                            "Please select a Factory Number",
                                    },
                                ]}>
                                <Select
                                    onChange={(factory) => {
                                        setStateforRoleFactory((prevState) => ({
                                            ...prevState,
                                            factory_fk: factory.id,
                                        }));
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={
                                        [
                                            "REGIONAL_ADMIN",
                                            "DESIGNATED_QC",
                                            "LEAD_SUPERVISOR",
                                        ].includes(
                                            userDataForm.getFieldValue("Role")
                                        ) || user?.role === "FACTORY_ADMIN"
                                    }>
                                    {Object.values(FactoryList).map(
                                        (factory) => (
                                            <Option
                                                defaultValue={
                                                    user?.role ===
                                                    "FACTORY_ADMIN"
                                                        ? user?.factory_fk
                                                        : factory.id
                                                }
                                                key={factory.id}
                                                value={factory.id}
                                                label={`${factory.Code} - ${factory.Location}`}>
                                                {`${factory.Code} - ${factory.Location}`}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Name"
                                name="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the Name",
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Dashboard Option"
                                name="DashboardOption">
                                <Radio.Group
                                    disabled={[
                                        "DESIGNATED_QC",
                                        "LEAD_SUPERVISOR",
                                    ].includes(
                                        userDataForm.getFieldValue("Role")
                                    )}
                                    value={dashboardOption}
                                    onChange={handleDashboardOptionChange}>
                                    <Radio value="new">New Dashboard</Radio>
                                    <Radio value="existing">
                                        Existing Dashboard
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ width: "100%" }}></Row>
                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                name="phoneNo"
                                label="Phone Number"
                                className="phoneNo"
                                validateStatus={phoneNoValidateStatus}
                                help={phoneNoErrorMsg}
                                rules={[
                                    {
                                        pattern: /^[0-9]+$/,
                                        message: "Please input numbers only!",
                                    },
                                    {
                                        required: true,
                                        message:
                                            "Please enter the Phone number",
                                    },
                                ]}>
                                <InputNumber
                                    maxLength={10}
                                    value={PhoneNo}
                                    prefix="+91"
                                    controls={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    onChange={(e) => handleNumberChange(e)}
                                />
                            </Form.Item>
                        </Col>{" "}
                        <Col style={{ width: "50%" }}>
                            {dashboardOption === "existing" && (
                                <ExistingDashboardSelector
                                    key={JSON.stringify(stateforRoleFactory)} //added this becasue the component wasn't rendering again.
                                    searchbyRole={true}
                                    role={stateforRoleFactory}
                                    setUser={setDashboardUser}
                                    onEmailChange={(value) => {
                                        setDashboardEmail(value);
                                    }}
                                    factoryData={FactoryList}
                                    //parentForm={userDataForm}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Gender"
                                name="Gender"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the Gender",
                                    },
                                ]}>
                                <Select>
                                    {genderList.map((value) => (
                                        <Option value={value}>{value}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Assigned role"
                                name="Role"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a Role",
                                    },
                                ]}>
                                <Select
                                    onChange={(role) => {
                                        setStateforRoleFactory((prevState) => ({
                                            ...prevState,
                                            role: role,
                                        }));
                                    }}>
                                    {Array.isArray(assignedRoles) &&
                                        assignedRoles.map((role) => (
                                            <Option key={role} value={role} on>
                                                {role}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Enable Awareness Programme"
                                name="hasAccess_Awareness_Program"
                                valuePropName="checked">
                                <Switch
                                    disabled={[
                                        "CM",
                                        "REGIONAL_ADMIN",
                                        "SUPER_ADMIN",
                                        "FACTORY_ADMIN",
                                        "DESIGNATED_QC",
                                        "LEAD_SUPERVISOR",
                                    ].includes(
                                        userDataForm.getFieldValue("Role")
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Enable Upload holiday calendar"
                                name="hasAccess_Holiday_Calender"
                                valuePropName="checked">
                                <Switch
                                    disabled={[
                                        "CR",
                                        "CM",
                                        "CT",
                                        "REGIONAL_ADMIN",
                                        "SUPER_ADMIN",
                                        "FACTORY_ADMIN",
                                        "DESIGNATED_QC",
                                        "LEAD_SUPERVISOR",
                                    ].includes(
                                        userDataForm.getFieldValue("Role")
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col style={{ width: "50%" }}>
                            <Form.Item
                                label="Enable broadcast message"
                                name="hasAccess_BroadCast_Message"
                                valuePropName="checked">
                                <Switch
                                    disabled={[
                                        "CR",
                                        "CM",
                                        "CT",
                                        "REGIONAL_ADMIN",
                                        "SUPER_ADMIN",
                                        "FACTORY_ADMIN",
                                        "DESIGNATED_QC",
                                        "LEAD_SUPERVISOR",
                                    ].includes(
                                        userDataForm.getFieldValue("Role")
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Row justify="end" style={{ padding: "0 1rem" }}>
                            <Col>
                                <button
                                    className="primaryButton"
                                    htmlType="submit">
                                    Create User
                                </button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default CreateUser;
