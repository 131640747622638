import { Checkbox, Col, Divider, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import styles from "../CaseReport.module.css";

const CheckboxGroup = Checkbox.Group;

const ReasonRemarksDetails = ({
    reasonOptions,
    readOnly,
    checkedList,
    onChange,
    otherReason,
    onReasonChange,
    error,
    remark,
    onRemarkChange,
}) => {
    return (
        <>
            <div className={styles.rate}>
                <Row gutter={[16, 32]} justifyContent="space-between">
                    {reasonOptions && (
                        <Col span={12}>
                            <Row
                                gutter={[16, 32]}
                                align="top"
                                className={styles.rate}>
                                <Col span={6} align="top">
                                    <h4 className={!readOnly && "required"}>
                                        Reasons
                                    </h4>
                                </Col>
                                <Col span={16} className={styles.reason}>
                                    {readOnly ? (
                                        <>
                                            {checkedList.map((item) => (
                                                <Row>
                                                    <li>{item}</li>
                                                </Row>
                                            ))}
                                            <Divider
                                                className={
                                                    styles.caseRepDivider
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            <CheckboxGroup
                                                options={reasonOptions}
                                                value={checkedList}
                                                onChange={onChange}
                                            />
                                            {reasonOptions.includes(
                                                "Others"
                                            ) && (
                                                <TextArea
                                                    showCount
                                                    maxLength={20}
                                                    rows={2}
                                                    value={otherReason}
                                                    onChange={onReasonChange}
                                                    disabled={
                                                        checkedList.includes(
                                                            "Others"
                                                        )
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            )}
                                            {error &&
                                                error.length > 0 &&
                                                error.find(
                                                    (t) => t == "Reason"
                                                ) && (
                                                    <span
                                                        className={
                                                            styles.errorMsg
                                                        }>
                                                        {
                                                            "Please select atleast one reason"
                                                        }
                                                    </span>
                                                )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col span={12}>
                        <Row
                            gutter={[16, 32]}
                            align="top"
                            className={styles.rate}>
                            <Col span={6}>
                                <h4>Remark</h4>
                            </Col>
                            <Col span={14}>
                                {readOnly ? (
                                    <>
                                        <Row className={styles.reason}>
                                            {remark}
                                        </Row>
                                        <Divider
                                            className={styles.caseRepDivider}
                                        />
                                    </>
                                ) : (
                                    <TextArea
                                        showCount
                                        maxLength={500}
                                        rows={3}
                                        value={remark}
                                        onChange={onRemarkChange}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ReasonRemarksDetails;
