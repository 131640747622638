import { Button, Modal, message } from "antd";
import React from "react";
import "../../../common.css";

const CompleteAuditModal = ({ open, setOpen, status, submitReport }) => {
    return (
        <Modal
            title={"Please Confirm"}
            open={open}
            footer={[
                <button
                    key="cancel"
                    className="secondaryButton"
                    onClick={() => setOpen(false)}>
                    Cancel
                </button>,
                <button
                    key="ok"
                    className="primaryButton"
                    onClick={submitReport}>
                    Confirm
                </button>,
            ]}
            width={350}
            closeIcon>
            <div>
                <p>
                    Are you sure you want to <b>{status} the case</b>? This way
                    the case will be{" "}
                    {status == "Reopen"
                        ? "re-inestigated."
                        : "marked as complete for auditing."}
                </p>
            </div>
        </Modal>
    );
};

export default CompleteAuditModal;
