import React from "react";
import { Form, Input, Button, message } from "antd";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { atLoginOperations, getUserDetails } from "../../../Adapters/userCalls";
import { useNavigate } from "react-router";
import { dataAtLoginState } from "../../../RecoilState/dataAtLoginState";
import { Cookies } from "react-cookie";

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const [user, setUser] = useRecoilState(userState);
    const [loading, setLoading] = useState(false);
    const [reset_password_form] = Form.useForm();
    const [dataAtLogin, setDataAtLogin] = useRecoilState(dataAtLoginState);
    const cookies = new Cookies();

    const onFinish = async (values) => {
        var decoded = jwt_decode(cookies.get("role-token-cookie")?.access);
        setLoading(true);

        getUserDetails(decoded.user_id, cookies.get("role-token-cookie"))
            .then((res) => {
                setUser(res.data.message_body.User);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err, "ERROR User details error");
                setLoading(false);
            });
        const data = {
            new_password: values.confirmPassword,
            old_password: values.oldPassword,
        };
        const options = {
            headers: {
                Authorization: `Bearer ${
                    cookies.get("role-token-cookie")?.access
                }`,
            },
        };

        atLoginOperations("password", data, options)
            .then((res) => {
                message.success("Password reset successful!");

                if (dataAtLogin?.language_preference_needed === "true") {
                    navigate("/languagePreference");
                } else {
                    window.location.href = "/home";
                }
            })
            .catch((error) => {
                if (error?.response?.data?.new_password) {
                    message.error(error?.response?.data?.new_password);
                } else if (error?.response?.data?.errorMessage) {
                    if (
                        error?.response?.data?.errorMessage ===
                        "Old password does not match"
                    ) {
                        const errormsg = [
                            "Old password does not match, Please type the correct password",
                        ];
                        reset_password_form.setFields([
                            {
                                name: "oldPassword",
                                errors: errormsg,
                            },
                        ]);
                    }
                    message.error(error?.response?.data?.errorMessage);
                } else {
                    message.error("Password reset failed. Please try again!");
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}>
            <div
                style={{
                    width: "550px",
                    borderRadius: "3%",
                    border: "1px solid #ccc",
                    padding: "20px",
                }}>
                <Form
                    name="reset_password_form"
                    form={reset_password_form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    scrollToFirstError>
                    <Form.Item
                        label="Old Password"
                        name="oldPassword"
                        rules={[
                            {
                                required: true,
                                message: "Please input your old password!",
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: "Please input your new password!",
                            },
                            {
                                pattern:
                                    /^(?=.*[A-Z])(?=.*\d)(?=.*\S)[A-Za-z\d\S]{8,}$/,
                                message:
                                    "Your password must be at least 8 characters long, contain at least one capital letter, one number, and one special character.",
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Confirm New Password"
                        name="confirmPassword"
                        dependencies={["newPassword"]}
                        rules={[
                            {
                                required: true,
                                message: "Please confirm your new password!",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue("newPassword") === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        "The two passwords that you entered do not match!"
                                    );
                                },
                            }),
                        ]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
