import { Modal, Form, Radio, Input, Switch, message, Button } from "antd";
import React, { useEffect, useState } from "react";
import { patchRoleDataOfUser } from "../../../../../../Adapters/AdminCalls";
import { useFetchUserData } from "../../UserHooks/useFechUserDataAdmin";

const EditRoleModal = ({ user, role, visible, onCancel, onSave, fetcher }) => {
    const [editRoleForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { fetchUserData } = useFetchUserData();

    useEffect(() => {
        if (role) {
            editRoleForm.setFieldsValue({
                Role: role.role,
                Factory: role.Code,
                hasAccess_BroadCast_Message:
                    role?.user_permissions?.includes("add_broadcastmessage") ||
                    role?.group_permissions?.includes("add_broadcastmessage"),
                hasAccess_Awareness_Program:
                    role.user_permissions?.includes("add_awarenessprogram") ||
                    role?.group_permissions?.includes("add_awarenessprogram"),
                hasAccess_Holiday_Calender:
                    role.user_permissions?.includes("add_holidaycalendar") ||
                    role?.group_permissions?.includes("add_holidaycalendar"),
            });
        }
    }, [role]);

    function handleFormChange(changedValues, allValues) {
        setIsFormChanged(true);
    }
    function handleCancel() {
        if (isFormChanged) {
            setShowConfirmModal(true);
        } else {
            onCancel();
        }
    }
    async function handleRoleDataFormSubmit() {
        if (isFormChanged) {
            setIsLoading(true);

            const formData = {
                role: editRoleForm.getFieldValue("Role"),
                company_fk: user.company_fk,
                factory_fk: role.factory_fk,
                user_permissions: {
                    hasAccess_Awareness_Program: editRoleForm.getFieldValue(
                        "hasAccess_Awareness_Program"
                    )
                        ? "true"
                        : "false",
                    hasAccess_BroadCast_Message: editRoleForm.getFieldValue(
                        "hasAccess_BroadCast_Message"
                    )
                        ? "true"
                        : "false",
                    hasAccess_Holiday_Calender: editRoleForm.getFieldValue(
                        "hasAccess_Holiday_Calender"
                    )
                        ? "true"
                        : "false",
                },
            };

            try {
                const response = await patchRoleDataOfUser(role.id, formData);
                setIsLoading(false);
                message.success(response.data.message);
                fetchUserData();
                fetcher();
                onCancel();
            } catch (error) {
                setIsLoading(false);
                message.error(error?.response?.data?.errorMessage);
            }
        } else {
            message.info("No changes were made, Thank you");
            onCancel();
        }
    }

    return (
        <>
            <Modal
                title="Edit Role"
                visible={visible}
                confirmLoading={isLoading}
                onCancel={handleCancel}
                closeIcon={true}
                footer={[
                    <button
                        key="Cancel"
                        onClick={handleCancel}
                        className="secondaryButton">
                        Cancel
                    </button>,
                    <button
                        key="Save"
                        onClick={handleRoleDataFormSubmit}
                        className="primaryButton">
                        Save
                    </button>,
                ]}>
                <Form
                    id="EditRoleForm"
                    name="Role Detail Form"
                    form={editRoleForm}
                    onValuesChange={handleFormChange}>
                    <Form.Item name="Factory" label="Factory Number">
                        <Input disabled value={role?.Code} />
                    </Form.Item>
                    <Form.Item label="Role" name="Role">
                        <Input disabled value={role?.role} />
                    </Form.Item>
                    <Form.Item
                        label="Enable Awareness Programme"
                        name="hasAccess_Awareness_Program"
                        valuePropName="checked"
                        initialValue={
                            role?.role === "FACTORY_ADMIN" ? true : false
                        }
                        hidden={
                            role?.role === "FACTORY_ADMIN" ||
                            role?.role === "REGIONAL_ADMIN"
                                ? true
                                : false
                        }>
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label="Enable broadcast message"
                        name="hasAccess_BroadCast_Message"
                        valuePropName="checked"
                        initialValue={
                            role?.role === "FACTORY_ADMIN" ? true : false
                        }
                        hidden={
                            role?.role === "FACTORY_ADMIN" ||
                            role?.role === "REGIONAL_ADMIN"
                                ? true
                                : false
                        }>
                        <Switch
                            disabled={
                                role?.role === "CR" ||
                                role?.role === "CM" ||
                                role?.role === "CT" ||
                                role?.role === "FACTORY_ADMIN"
                                    ? true
                                    : false
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Upload Holiday Calendar"
                        name="hasAccess_Holiday_Calender"
                        valuePropName="checked"
                        initialValue={
                            role?.role === "FACTORY_ADMIN" ? true : false
                        }
                        hidden={
                            role?.role === "FACTORY_ADMIN" ||
                            role?.role === "REGIONAL_ADMIN"
                                ? true
                                : false
                        }>
                        <Switch
                            disabled={
                                role?.role === "CR" ||
                                role?.role === "CM" ||
                                role?.role === "CT" ||
                                role?.role === "FACTORY_ADMIN"
                                    ? true
                                    : false
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {showConfirmModal && (
                <Modal
                    title="Unsaved Changes"
                    visible={true}
                    closeIcon={true}
                    footer={[
                        <button
                            key="Cancel"
                            onClick={() => setShowConfirmModal(false)}
                            className="secondaryButton">
                            Cancel
                        </button>,
                        <button
                            key="Save"
                            onClick={() => {
                                setShowConfirmModal(false);
                                onCancel();
                            }}
                            className="primaryButton">
                            Exit
                        </button>,
                    ]}>
                    <p>Are you sure you want to discard the changes?</p>
                </Modal>
            )}
        </>
    );
};

export default EditRoleModal;
