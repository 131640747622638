import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Column } from "@ant-design/plots";
import styles from "../../IncentiveDashboard/Charts/Charts.module.css";
export default function QualifiedCharts({ caseData }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        caseData && setData(caseData);
    }, [caseData]);

    const config = {
        data,
        legend: false,
        xField: "type",
        yField: "value",
        seriesField: "caseType",
        isStack: true,
        color: ({ caseType }) => {
            if (caseType === "Qualified") {
                return "rgba(82, 130, 235, 1)";
            } else if (caseType === "Unqualified") {
                return "rgba(255, 0, 0, .6)";
            }
        },
        yAxis: {
            title: {
                text: "---------- Number of Cases ----------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                autoHide: false,
            },
        },

        xAxis: {
            title: {
                text: " ----------------------------- Factories -----------------------------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                autoHide: false,
            },
        },
    };
    return (
        <div className={styles.compliance + " " + styles.container}>
            <Row>
                <Col span={16}>
                    <h1>Cases Qualified v/s Not-Qualified for Incentive</h1>
                </Col>
                <Col span={8} className={styles.eligibleRightContainer}>
                    <Row align="middle">
                        <img src="/assets/images/Incentive/unqualifiedReporting.svg" />
                        <span>Unqualified cases</span>
                    </Row>
                    <Row>
                        <img src="/assets/images/Incentive/qualifiedReporting.svg" />
                        <span>Qualified cases</span>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {" "}
                    <Column {...config} />
                </Col>
            </Row>
        </div>
    );
}
