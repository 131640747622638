import React, { useEffect } from "react";
import { Tabs, message } from "antd";
import Basic from "./Basic";
import Variables from "./Variables";
import PreviewMessage from "./Preview";
import { useState } from "react";
import { getPreviewMessageData } from "../../../Adapters/BroadcastmessageCalls";
const NewMessage = ({
    mainTabKey,
    draftFlag,
    draftMain,
    preFilledDraftData,
    setShowEditTabs,
}) => {
    const [activeTab, setActiveTab] = useState("1");
    const [isTemplateValue, setIsTemplateValue] = useState(false);
    const [previewScreenData, setPreviewScreenData] = useState();
    const [prefilledInputValue, setPrefilledInputValue] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const getPreviewScreensDataHandler = async (translatedDataObj) => {
        setIsLoading(true);

        // Validation step: Check if any key has an empty string as a value
        const hasEmptyValues = Object.values(translatedDataObj).some(
            (value) => value === ""
        );

        if (hasEmptyValues) {
            console.error("Validation failed: Some values are empty strings.");
            setIsLoading(false);
            message.error(
                "All fields are required, Please fill values in all fields."
            );
            return; // Stop the function if validation fails
        }
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/filtermsgs/`
        );
        let objectString = JSON.stringify(translatedDataObj);

        queryUrl.searchParams.append("inputs", objectString);
        const res = await getPreviewMessageData(queryUrl);

        try {
            if (res.status === 200) {
                setPreviewScreenData(res?.data.message_body);
                setIsLoading(false);
                setActiveTab("3");
                setTabState((prevState) => ({
                    ...prevState,
                    preview: {
                        ...prevState.preview,
                        disabled: "true",
                    },
                }));
            } else {
                setIsLoading(false);
                message("There was an error fetching data from the API. Retry");
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        setIsLoading(false);
    }, [isTemplateValue]);

    const [variableData, setVariableData] = useState();
    const [tabState, setTabState] = useState({
        basic: {
            disabled: "true",
        },
        variable: {
            disabled: "false",
        },
        preview: {
            disabled: "false",
        },
    });
    const items = [
        {
            key: "1",
            label: `01- Filters`,
            disabled: tabState.basic.disabled !== "true",
            children: (
                <Basic
                    isTemplateValue={isTemplateValue}
                    setIsTemplateValue={setIsTemplateValue}
                    setShowEditTabs={setShowEditTabs}
                    setVariableData={setVariableData}
                    draftFlag={draftFlag}
                    draftMain={draftMain}
                    setActiveTab={setActiveTab}
                    setTabState={setTabState}
                    preFilledDraftData={preFilledDraftData}
                    setPrefilledInputValue={setPrefilledInputValue}
                />
            ),
        },
        {
            key: "2",
            label: `02- Variables`,
            disabled: tabState.variable.disabled !== "true",
            children: (
                <Variables
                    activeTab={activeTab}
                    isTemplateValue={isTemplateValue}
                    setIsTemplateValue={setIsTemplateValue}
                    prefilledInputValue={prefilledInputValue}
                    setActiveTab={setActiveTab}
                    variableData={variableData}
                    getPreviewScreensDataHandler={getPreviewScreensDataHandler}
                    draftFlag={draftFlag}
                    isLoading={isLoading}
                />
            ),
        },
        {
            key: "3",
            label: `03- Preview`,
            disabled: tabState.preview.disabled !== "true",
            children: (
                <PreviewMessage
                    setActiveTab={setActiveTab}
                    previewScreenData={previewScreenData}
                    draftID={preFilledDraftData?.draftID}
                />
            ),
        },
    ];
    return (
        <div style={{ minHeight: "65vh" }}>
            <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                items={items}
                onChange={(key) => {
                    setActiveTab(key);
                }}
                onTabClick={(key) => {
                    setActiveTab(key);
                }}></Tabs>
        </div>
    );
};

export default NewMessage;
