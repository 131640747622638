/* Suggestion Box Modal */
import React, { useCallback, useEffect, useState } from "react";
import { Input, message, Form, Col, Row, Select } from "antd";
import styles from "../CaseUpload.module.css";
import {
    createNewCaseWithFile,
    getNumberOfCases,
} from "../../../Adapters/CaseCRUDCalls";
import { useRecoilState } from "recoil";
import { Upload } from "antd";
import { userState } from "../../../RecoilState/userState";
import { FactoryData } from "../../../RecoilState/FactoryData";
import UploadComponent from "../../UploadComponent/UploadHeaderFile";
import useEventMetricsUpdater from "../../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";
const key = "suggestmsg";
const { Option } = Select;

export default function SuggestionBox({ modalCloser, languageList }) {
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const [user, setUser] = useRecoilState(userState);
    const [caseUploaded, setCaseUploaded] = React.useState(false);
    const [caseDetails, setCaseDetails] = React.useState("");
    const [suggestionForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [handleUpload, setHandleUpload] = useState(false);
    const [uploadFinished, setUploadFinished] = useState(false);
    const [fileList, setFileList] = useState([]);
    const { updateEventMetrics } = useEventMetricsUpdater();

    const allowedFileTypes = [
        "jpeg",
        "jpg",
        "png",
        "svg",
        "pdf",
        "csv",
        "pps",
        "ppt",
        "pptx",
        "txt",
        "xls",
        "xlsx",
        "xlsm",
        "gif",
        "doc",
        "docx",
        "tex",
        "rtf",
        "mp4",
        "mpg",
        "mpeg",
        "mov",
        "avi",
        "log",
        "aif",
        "mp3",
        "mpa",
        "wav",
        "wma",
    ];

    const onUploadComplete = () => {
        setUploadFinished(true);
        setCaseUploaded(true);
        resetFields();
    };

    function handleAnotherCase() {
        resetFields();
        setCaseUploaded(false);
        setLoading(false);
    }

    useEffect(() => {
        suggestionForm.setFieldsValue({
            Factory: user?.factory_fk,
        });
    }, [user]);

    useEffect(() => {
        suggestionForm.setFieldsValue({ file: fileList });
    }, [fileList]);

    useEffect(() => {
        if (!handleUpload) {
            suggestionForm.setFieldsValue({ file: null });
            setFileList([]);
        }
    }, [handleUpload]);
    function resetFields() {
        suggestionForm.setFieldsValue({
            Factory: user?.factory_fk,
            Comments: "",
            Complaint: "",
            file: null,
            workersLanguage: "",
        });
    }

    function handleSubmission(eventName, actionType) {
        {
            setLoading(true);
            message.loading({ content: "Loading...", key });
            getNumberOfCases()
                .then((res) => {
                    let formData = new FormData();
                    formData.append(
                        "Factory",
                        suggestionForm.getFieldValue("Factory")
                    );
                    formData.append(
                        "CommentsByRep",
                        suggestionForm.getFieldValue("Comments")
                    );
                    formData.append("ReportingMedium", "Suggestion Box");
                    formData.append("Company", user?.company_fk);
                    formData.append("CaseStatus", "Assigned to Reporter");
                    formData.append(
                        "MessagebyWorker",
                        suggestionForm.getFieldValue("Complaint")
                    );
                    formData.append(
                        "workerLanguage",
                        suggestionForm.getFieldValue("workersLanguage")
                    );
                    createNewCaseWithFile(formData)
                        .then((res) => {
                            setCaseDetails(res?.data);
                            setHandleUpload(true); // Start uploading the file once the case is created.
                        })
                        .catch((error) => {
                            error?.response?.data?.errorMessage
                                ? message.error(
                                      error?.response?.data?.errorMessage
                                  )
                                : message.error(
                                      "Unable to upload case, please check all the fields"
                                  );
                            setLoading(false);
                            // Handle error appropriately
                        });
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });

            updateEventMetrics(eventName, actionType);
        }
    }
    const debouncedSubmit = debounce(handleSubmission, 500);
    if (caseUploaded === true) {
        return (
            <div>
                <div className={styles.successfulUpload}>
                    <h3>Case is uploaded successfully!</h3>
                    <h1>Case Number - {caseDetails.CaseNumber}</h1>
                    <p>
                        Enquiry about the case can be done by using this case
                        number. Make sure to not share it with any unknown
                        individual or authority. If you have submitted your
                        phone number, updates will be messaged to it.
                    </p>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.anotherCasebtCont}>
                    <button
                        onClick={handleAnotherCase}
                        className={styles.anotherCaseButton}>
                        Upload another case
                    </button>
                </div>
            </div>
        );
    } else {
        return (
            <Form
                name="suggestionForm"
                form={suggestionForm}
                id="suggestionForm"
                onFinish={() =>
                    debouncedSubmit(
                        "caseUploadSubmit",
                        "Suggestion Box Case upload submit"
                    )
                }>
                <Row
                    gutter={[70, 40]}
                    align="top"
                    className={styles.topRowElements}>
                    <Col span={12}>
                        <Form.Item
                            name="Factory"
                            label="Select Factory"
                            className={styles.label}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Factory",
                                },
                            ]}>
                            <Select
                                disabled={
                                    user.role === "SUPER_ADMIN" ? false : true
                                }>
                                {FactoryList.length > 0 &&
                                    FactoryList.map((FactoryItem, index) => (
                                        <Option
                                            key={index}
                                            value={FactoryItem.id}>
                                            {`${FactoryItem.Code}-${FactoryItem.Location}`}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="file"
                            label="File Upload"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const currentFileList =
                                            getFieldValue("file");
                                        // If there are no files, just return (the required rule will catch it)
                                        if (
                                            !currentFileList ||
                                            currentFileList.length === 0
                                        ) {
                                            return Promise.resolve();
                                        }
                                        for (let file of currentFileList) {
                                            const fileType = file.name
                                                .split(".")
                                                .slice(-1)[0];
                                            if (
                                                !allowedFileTypes.includes(
                                                    fileType.toLowerCase()
                                                )
                                            ) {
                                                return Promise.reject(
                                                    new Error(
                                                        "File type not allowed!"
                                                    )
                                                );
                                            }
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                                {
                                    required: true,
                                    message: "Please upload a file!",
                                },
                            ]}>
                            <UploadComponent
                                externalFileList={fileList}
                                onExternalFileListChange={(newFileList) =>
                                    setFileList(newFileList)
                                }
                                Files={[]}
                                maxFiles="1"
                                companyFk={user.company_fk}
                                model="case"
                                allowedFileTypes={allowedFileTypes}
                                modelMethod="new"
                                onModelCreated={handleUpload}
                                setOnModalCreated={setHandleUpload}
                                id={caseDetails.id}
                                onUploadComplete={onUploadComplete}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[70, 40]}>
                    <Col span={12}>
                        <Form.Item
                            name="Comments"
                            label="Comments"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Comments",
                                },
                            ]}>
                            <Input.TextArea
                                rows={4}
                                maxLength={300}
                                showCount
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="Complaint"
                            label="Complaint / Query / Suggestion"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please enter Complaint / Query / Suggestion",
                                },
                            ]}>
                            <Input.TextArea
                                rows={4}
                                maxLength={300}
                                showCount
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}></Col>
                </Row>
                <Row gutter={[70, 40]}>
                    <Col span={12}>
                        <Form.Item
                            name="workersLanguage"
                            label="Worker's Language"
                            className={styles.label}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Language",
                                },
                            ]}>
                            <Select>
                                {languageList?.languages?.map((lang, index) => (
                                    <>
                                        <Option key={index} value={lang}>
                                            {lang}
                                        </Option>
                                    </>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.divider}></div>
                <Row>
                    <Col style={{ padding: "1%" }}>
                        <span style={{ color: "red" }}>*</span> means mandatory
                    </Col>
                    <Col span={20} offset={12} style={{ padding: "1%" }}>
                        <button
                            disabled={loading}
                            id="SubmitButton"
                            className={
                                loading
                                    ? `${styles.submitButton} ${styles.disabled}`
                                    : styles.submitButton
                            }
                            title="Submit">
                            Submit
                        </button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
