/* Send message modal with templates and language selection */
import React, { useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { message } from "antd";
import { getTemplateData, sendMessage } from "../../Adapters/SendMessage.js";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState.js";
import SendMessageMain from "./SendMessageMain.jsx";
import { SendMessageFlag } from "../../RecoilState/SendMessageFlag.js";

export default function CaseSendMessage({ visible, hideModal, caseData }) {
    const [templateResData, setTemplateResData] = useState([]);
    const [language, setLanguage] = React.useState("");
    const [template, settemplate] = React.useState("");
    const [textValue, setTextValue] = React.useState("");
    const [templateID, setTemplateID] = React.useState("");
    const [allTemplates, setAllTemplates] = useState({});
    const [user] = useRecoilState(userState);
    const [sendMessageFlag, setSendMessageFlag] =
        useRecoilState(SendMessageFlag);
    useEffect(() => {
        setTemplateID(
            template && language != "" && allTemplates[template][language]
        );

        if (templateID) {
            let res = templateResData.filter(
                (val) => val.templateID === templateID
            );
            setTextValue(res[0].body);
        }
    }, [template, language, templateID]);

    useEffect(() => {
        if (visible) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template`
            );
            //if modal is open at first only we will have the api call uisng comapnyID param

            queryUrl.searchParams.append("companyID", caseData.Company);
            //setting the template category as send message
            queryUrl.searchParams.append("temp_category", "Send Message");

            try {
                getTemplateData(queryUrl).then((res) => {
                    setTemplateResData(res?.data.message_body);

                    setAllTemplates(res?.data.message_body?.templates);
                });
            } catch (error) {
                console.log("error in res");
            }
        }
    }, [visible === true]);

    const sendMessageCall = () => {
        sendMessage(
            templateID,
            caseData.id,
            textValue,
            user.role,
            caseData.Company
        );
    };

    return (
        <>
            <Modal
                title="Send Message"
                centered̦
                visible={visible}
                footer={null}
                onOk={() => {
                    message.success("Message sent successfully");
                    hideModal();
                    sendMessageCall(template);
                }}
                onCancel={() => {
                    hideModal();
                    setSendMessageFlag(!sendMessageFlag);
                }}
                destroyOnClose={true}
                okText="Send Message"
                width={800}>
                <SendMessageMain
                    allTemplates={allTemplates}
                    caseData={caseData}
                    hideModal={hideModal}
                />
            </Modal>
        </>
    );
}
