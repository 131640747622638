import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import {
    MailOutlined,
    HomeOutlined,
    BarChartOutlined,
    GiftOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";

export default function CollapsableMenu() {
    const { Sider } = Layout;
    const [collapsed, setCollapsed] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [user, setUser] = useRecoilState(userState);

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }
    const getFirstAvailableTabKey = (user) => {
        if (
            user?.group_permissions?.includes("add_factoryregion") ||
            user?.group_permissions?.includes("view_factoryregion")
        )
            return "1";
        if (
            user?.group_permissions?.includes("add_factory") ||
            user?.group_permissions?.includes("view_factory")
        )
            return "2";
        if (
            user?.group_permissions?.includes("change_baseusermodel") ||
            user?.group_permissions?.includes("view_baseusermodel")
        )
            return "3";
        return null; // default case if no tabs are available
    };
    const firstAvailableTabKey = getFirstAvailableTabKey(user);
    const formatTitle = (titles) => {
        if (titles.length > 1) {
            return `${titles.slice(0, -1).join(", ")} and ${
                titles[titles.length - 1]
            }`;
        }
        return titles.join("");
    };
    useEffect(() => {
        const items = [];
        if (
            user?.role !== "REGIONAL_ADMIN" &&
            user?.role !== "SUPER_ADMIN" &&
            user?.role !== "FACTORY_ADMIN"
        ) {
            items.push(
                getItem(
                    <Link to="/home" state={{ tabKey: "1" }}>
                        Home
                    </Link>,
                    "home",
                    <HomeOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        if (
            [
                "SUPER_ADMIN",
                "REGIONAL_ADMIN",
                "LEAD_SUPERVISOR",
                "FACTORY_ADMIN",
            ].includes(user?.role)
        ) {
            items.push(
                getItem(
                    <Link
                        to="/home/AllCases?critical=false"
                        state={{ tabKey: "2" }}>
                        All Cases
                    </Link>,
                    "Critical Cases",
                    <img
                        src="/assets/images/icon/adminDashboard/allcases.svg"
                        style={{
                            height: "auto",
                            width: "10%",
                        }}
                    />
                )
            );
        }
        if (user?.role === "REGIONAL_ADMIN") {
            items.push(
                getItem(
                    <Link
                        to="/home/AllCases?critical=true"
                        state={{ tabKey: "1" }}>
                        Critical Cases
                    </Link>,
                    "Critical Cases",
                    <img
                        src="/assets/images/icon/adminDashboard/critical-case.svg"
                        style={{
                            height: "auto",
                            width: "10%",
                        }}
                    />
                )
            );
        }
        if (
            ["SUPER_ADMIN", "REGIONAL_ADMIN", "LEAD_SUPERVISOR"].includes(
                user.role
            )
        ) {
            items.push(
                getItem(
                    <Link to="/QualityInspection">Quality Inspection</Link>,
                    "Quality Inspection",
                    <img
                        src="/assets/images/icon/adminDashboard/qualityInspection.svg"
                        style={{
                            height: "auto",
                            width: "27px",
                        }}
                    />
                )
            );
        }
        if (user?.user_permissions?.includes("view_incentives")) {
            items.push(
                getItem(
                    <Link to="/home/incentive">Incentives</Link>,
                    "incentive",
                    <GiftOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        //Analytics is visible for all the user
        if (user?.user_permissions?.includes("view_analytics")) {
            items.push(
                getItem(
                    <Link to="/home/reports">Analytics</Link>,
                    "analytics",
                    <BarChartOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        const permissionTitles = {
            SUPER_ADMIN: ["Regions", "Factories", "Users"],
            REGIONAL_ADMIN: ["Region", "Factories", "Users"],
            FACTORY_ADMIN: ["Factory", "Users"],
            LEAD_SUPERVISOR: ["Regions", "Factories", "Users"],
        };

        const titles = permissionTitles[user?.role] || [];
        const formattedTitle = formatTitle(titles);

        if (titles.length) {
            items.push(
                getItem(
                    <Link to={`/AdminTableView?key=${firstAvailableTabKey}`}>
                        {formattedTitle}
                    </Link>,
                    "Admin Table View",
                    <img
                        src="/assets/images/icon/adminDashboard/manage.svg"
                        style={{ height: "auto", width: "10%" }}
                    />
                )
            );
        }

        if (
            user?.user_permissions?.includes("add_broadcastmessage") ||
            user?.group_permissions?.includes("add_broadcastmessage")
        ) {
            items.push(
                getItem(
                    <Link to={"/home/BroadcastMsg"}>Broadcast Message</Link>,
                    "Broadcast Message ",
                    <MailOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        if (
            user?.user_permissions?.includes("view_awarenessprogram") ||
            user?.group_permissions?.includes("view_awarenessprogram")
        ) {
            items.push(
                getItem(
                    <Link to={"/home/AwarenessProgramme"}>
                        Awareness Programme
                    </Link>,
                    " Awareness Programme ",
                    <img
                        src="/assets/images/AwarenessProgramme/awarnessP.svg"
                        style={{
                            height: "auto",
                            width: "20px",
                        }}
                    />
                )
            );
        }
        //change the user_permissions?.includes("add_awareness_program"))
        if (
            user?.user_permissions?.includes("view_holidaycalendar") ||
            user?.group_permissions?.includes("view_holidaycalendar")
        ) {
            items.push(
                getItem(
                    <Link to={"/home/HolidayCalendar"}>Holiday Calendar</Link>,
                    " Holiday Calendar",
                    <img
                        src="/assets/images/HolidayCalendar/calendar.svg"
                        style={{
                            height: "auto",
                            width: "20px",
                        }}
                    />
                )
            );
        }
        if (
            user?.group_permissions?.includes("add_policy") ||
            user?.group_permissions?.includes("change_policy")
        ) {
            items.push(
                getItem(
                    <Link to={"/home/PolicyDashboard"}>Policy Dashboard</Link>,
                    "Policy Dashboard",
                    <img
                        src="/assets/images/CollapsableMenu/policy-icon.svg"
                        style={{
                            height: "auto",
                            width: "20px",
                        }}
                    />
                )
            );
        }
        items.push(
            getItem(
                <Link to={"/home/Help"}>Help</Link>,
                "Help",
                <img
                    src="/assets/images/CollapsableMenu/help-circle.svg"
                    style={{
                        height: "auto",
                        width: "20px",
                    }}
                />
            )
        );
        items.push(
            getItem(
                <Link to={"/home/Policies"}>Policies</Link>,
                "Policies",
                <img
                    src="/assets/images/CollapsableMenu/policies.svg"
                    style={{
                        height: "auto",
                        width: "20px",
                    }}
                />
            )
        );

        setMenuItems(items);
    }, []);

    return (
        <Sider
            data-testid="collapsable-menu"
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            theme="light">
            <Menu
                theme="light"
                defaultSelectedKeys={["1"]}
                mode="inline"
                items={menuItems}
            />
        </Sider>
    );
}
