import { Col, Divider, Radio, Rate, Row } from "antd";
import React from "react";
import styles from "../CaseReport.module.css";
import { isEmpty } from "lodash";
const CustomStar = ({ disable, filled, onClick }) => {
    return (
        <div disabled={disable} onClick={onClick}>
            {filled ? (
                <img src="/assets/images/QCReport/StarOutline.svg" />
            ) : (
                <img src="/assets/images/QCReport/Star.svg" />
            )}
        </div>
    );
};

function FeedbackRatings({
    wrapper,
    header,
    rateFields,
    polarFields,
    selectedRateChoices,
    selectedPolarChoices,
    setSelectedPolarChoices,
    error,
    readOnly,
    handleCheckboxChange,
}) {
    return (
        <>
            <Row gutter={[16, 32]} align="top">
                <Col span={7}>
                    {readOnly ? (
                        <h3>Ratings:</h3>
                    ) : (
                        <h3>Rate the following:</h3>
                    )}
                </Col>
                {header.map((item) => (
                    <Col span={3} className={styles.ratingHeader}>
                        <img src={item.src}></img>
                        <br />
                        <h4>{item.label}</h4>
                    </Col>
                ))}
            </Row>
            {rateFields &&
                rateFields.map((element, index) => (
                    <div
                        style={{
                            backgroundColor: wrapper
                                ? "auto"
                                : index % 2 == 0
                                ? "#F4F7FE"
                                : "white",
                        }}
                        className={styles.rating}>
                        <Row gutter={[16, 32]} align="middle" key={index}>
                            <Col span={6}>
                                <h4 className={element.mandatory && "required"}>
                                    {element.description}
                                </h4>
                            </Col>
                            <Col span={15}>
                                <Rate
                                    value={
                                        selectedRateChoices[
                                            element.description
                                        ] || 0
                                    }
                                    onChange={(checkedValues) => {
                                        !readOnly &&
                                            handleCheckboxChange(
                                                element.description,
                                                checkedValues
                                            );
                                    }}
                                    disabled={readOnly}
                                    character={(props) => (
                                        <CustomStar
                                            disable={readOnly}
                                            filled={
                                                props.index <
                                                (selectedRateChoices[
                                                    element.description
                                                ] || 0)
                                            }
                                        />
                                    )}
                                    style={{
                                        justifyContent: "space-around",
                                        width: "105%",
                                        marginLeft: "32px",
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            <Row gutter={[16, 32]} align="top">
                <Col span={6}></Col>
                <Col span={15}>
                    {error &&
                        error.length > 0 &&
                        error.find((t) => t == "Rate") && (
                            <span className={styles.errorMsg}>
                                {"Please fill all the mandatory fields rating"}
                            </span>
                        )}
                </Col>
            </Row>
            {(!wrapper || (wrapper && !isEmpty(selectedPolarChoices))) && (
                <Divider className={styles.caseRepDivider} />
            )}
            <Row gutter={[16, 32]} align="top">
                {readOnly ? (
                    <>
                        {!isEmpty(selectedPolarChoices) && (
                            <>
                                {" "}
                                {Object.keys(selectedPolarChoices).map(
                                    (element, index) => (
                                        <Col span={12}>
                                            <Row
                                                gutter={[16, 32]}
                                                align="middle"
                                                key={index}>
                                                <Col>
                                                    <h4>{element}</h4>
                                                </Col>
                                                <Col>
                                                    <Radio.Group
                                                        value={
                                                            selectedPolarChoices[
                                                                element
                                                            ]
                                                        }>
                                                        <Radio
                                                            value={"Yes"}
                                                            disabled={true}>
                                                            Yes
                                                        </Radio>
                                                        <Radio
                                                            value={"No"}
                                                            disabled={true}>
                                                            No
                                                        </Radio>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                )}
                                {!wrapper && (
                                    <Divider
                                        className={styles.caseRepDivider}
                                    />
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {polarFields?.length > 0 && (
                            <>
                                {polarFields.map((element, index) => (
                                    <Col span={12}>
                                        <Row
                                            gutter={[16, 32]}
                                            align="middle"
                                            key={index}>
                                            <Col>
                                                <h4
                                                    className={
                                                        element.mandatory &&
                                                        "required"
                                                    }>
                                                    {element.description}
                                                </h4>
                                            </Col>
                                            <Col>
                                                <Radio.Group
                                                    onChange={(e) => {
                                                        setSelectedPolarChoices(
                                                            {
                                                                ...selectedPolarChoices,
                                                                [element.description]:
                                                                    e.target
                                                                        .value,
                                                            }
                                                        );
                                                    }}>
                                                    <Radio value={"Yes"}>
                                                        Yes
                                                    </Radio>
                                                    <Radio value={"No"}>
                                                        No
                                                    </Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                                <Row gutter={[16, 32]} align="top">
                                    <Col>
                                        {error &&
                                            error.length > 0 &&
                                            error.find((t) => t == "Polar") && (
                                                <span
                                                    className={styles.errorMsg}>
                                                    {
                                                        "Please select an option for all mandatory fields"
                                                    }
                                                </span>
                                            )}
                                    </Col>
                                </Row>
                                <Divider className={styles.caseRepDivider} />
                            </>
                        )}
                    </>
                )}
            </Row>
        </>
    );
}

export default FeedbackRatings;
