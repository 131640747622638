// userMetricState.js
import { atom } from "recoil";
export const userMetricState = atom({
    key: "userMetricState",
    default: {
        userMetrics: {
            email: "",
            userID: null,
            loginTimestamp: null, // Replace with actual login time
            logoutType: "",
            logoutTimestamp: null, // To be filled in on logout
            sessionDuration: null, // To be filled in on logout
        },
        userRoles: [],
        pageMetrics: {
            pagesVisited: [],
            pageVisitTimestamps: {},
        },
        eventMetrics: {},
    },
});
