import { Select } from "antd";
import React, { useRef, useEffect, useState } from "react";
import styles from "./FilterDropDown.module.css";
const MultiSubSelectDropDown = ({
    labelData,
    dropdownShow,
    setDropdownShow,
    selectedData,
    setSelectedData,
    selectedDeptID,
    preFilledDraftData,
    draftFlag,
}) => {
    const selectAllRef = useRef();
    const [dataObj, setdataObj] = useState({});
    const [flag, setFlag] = useState(false);
    const [flagData, setFlagData] = useState(false);
    const [runCheckAllSubFlag, setRunCheckAllSubFlag] = useState(false);

    // Function to convert an array of label data into an object grouped by department
    // and having Department, SubDepartment, isChecked, isSubDep properties
    // Example dataObj structure:
    // dataObj = {
    //     "CENTRAL PLANNING": [
    //         {
    //             "Department": "CENTRAL PLANNING",
    //             "SubDepartment": "PROCESS PLANNING",
    //             "isChecked": false,
    //             "isSubDep": false
    //         },
    //         // ... additional sub-departments
    //     ],
    //     // ... additional departments
    // }
    // We will be updating isChecked when all the sub-department values are checked as true
    // and will set isSubDep true when that sub-department is checked
    const convertArrToObj = (labelData) => {
        const objDepartment = {};

        if (labelData?.length > 0) {
            labelData.forEach((item) => {
                const departmentKey = item.Department;
                objDepartment[departmentKey] = [
                    ...(objDepartment[departmentKey] ?? []),
                    { ...item, isChecked: false, isSubDep: false },
                ];
            });
        }
        setFlagData(!flagData);
        return objDepartment;
    };
    // Function to toggle the "isSubDep" property of a sub-department
    const checkSub = (department, subdep, index) => {
        setdataObj((prev) => {
            const updatedDepartment = prev[department].map((labelData, i) => {
                if (i === index) {
                    return { ...subdep, isSubDep: !subdep.isSubDep };
                }
                return labelData;
            });

            return { ...prev, [department]: updatedDepartment };
        });

        setFlag((prevFlag) => !prevFlag);
    };
    // Function to toggle "isChecked" and "isSubDep" for all sub-departments in a department
    const checkallsub = (department, e) => {
        setFlag(!flag);

        const arr = [];
        dataObj[department].forEach((labelData) => {
            arr.push({
                ...labelData,
                isChecked: !labelData.isChecked,
                isSubDep: !labelData.isChecked,
            });
        });
        setdataObj((prev) => {
            return { ...prev, [department]: arr };
        });
    };
    // Function to check if all sub-departments in a department have "isSubDep" set to true
    const checkallSubtrue = (department) => {
        for (let i = 0; i < dataObj[department].length; i++) {
            if (!dataObj[department][i].isSubDep) {
                return false;
            }
        }
        return true;
    };

    // Function to handle "Select All" checkbox state change
    const selectAllHandler = (e) => {
        Object.keys(dataObj).map((department) => {
            const arr = [];
            if (e.target.checked) {
                setFlag(!flag);

                dataObj[department].forEach((labelData) => {
                    arr.push({
                        ...labelData,
                        isChecked: true,
                        isSubDep: true,
                    });
                });
                setdataObj((prev) => {
                    return { ...prev, [department]: arr };
                });
            } else if (!e.target.checked) {
                setFlag(!flag);
                dataObj[department].forEach((labelData) => {
                    arr.push({
                        ...labelData,
                        isChecked: false,
                        isSubDep: false,
                    });
                });
                setdataObj((prev) => {
                    return { ...prev, [department]: arr };
                });
            }
        });
    };

    // Function to run checkallSubtrue and update dataObj accordingly
    const runcheckallSubtrue = () => {
        Object.keys(dataObj).forEach((department) => {
            const arr = [];

            // Check if all sub-departments in the current department have isSubDep set to true
            if (checkallSubtrue(department)) {
                // If true, set isChecked to true for all sub-departments
                dataObj[department].forEach((labelData) => {
                    arr.push({
                        ...labelData,
                        isChecked: true,
                        isSubDep: labelData.isSubDep,
                    });
                });
            } else {
                // If not all sub-departments have isSubDep set to true, set isChecked to false for all sub-departments
                dataObj[department].forEach((labelData) => {
                    arr.push({
                        ...labelData,
                        isChecked: false,
                        isSubDep: labelData.isSubDep,
                    });
                });
            }

            // Update dataObj with the modified sub-department data
            setdataObj((prev) => ({ ...prev, [department]: arr }));
        });

        // Set the flag to indicate that runCheckAllSubFlag has been executed
        setRunCheckAllSubFlag(true);
    };
    // Effect to update dataObj based on preFilledDraftData when draftFlag changes
    useEffect(() => {
        if (draftFlag && preFilledDraftData !== undefined) {
            const updatedDataObj = { ...dataObj };

            for (const testData of preFilledDraftData) {
                // Loop through the dataObj and update isSubDep for the matching ids
                for (const departmentKey in updatedDataObj) {
                    const department = updatedDataObj[departmentKey];
                    const matchingSubDept = department.find(
                        (subDepartment) =>
                            subDepartment.SubDepartment ===
                                testData.SubDepartment &&
                            subDepartment.Department === testData.Department
                    );

                    if (matchingSubDept) {
                        matchingSubDept.isSubDep = true;
                        setFlag(!flag);
                    }
                }
            }

            // Update isChecked based on allSubDepsChecked
            Object.keys(updatedDataObj).forEach((deptKey) => {
                const subDepts = updatedDataObj[deptKey];
                const allSubDepsChecked = subDepts.every(
                    (subDept) => subDept.isSubDep
                );

                subDepts.forEach((subDept) => {
                    subDept.isChecked = allSubDepsChecked;
                });
            });

            setdataObj(updatedDataObj);
        }
    }, [draftFlag, preFilledDraftData, flagData]);
    // Effect to update selectedData and set runCheckAllSubFlag when runCheckAllSubFlag changes
    useEffect(() => {
        setSelectedData(dataObj);
        setRunCheckAllSubFlag(false);
    }, [runCheckAllSubFlag]);
    // Effect to update dataObj when labelData changes
    useEffect(() => {
        setdataObj(convertArrToObj(labelData));
        setSelectedData([]);
    }, [labelData]);
    // Effect to update "Select All" checkbox state is all isChecked and isSubDep are ture or false the based on selectedData, dropdownShow, and labelData
    useEffect(() => {
        const areAllValuesChecked =
            Object.keys(selectedData).length > 0 &&
            Object.values(selectedData)
                .flat()
                .every((item) => item.isChecked && item.isSubDep);

        if (selectAllRef.current) {
            selectAllRef.current.checked = areAllValuesChecked;
        }
    }, [selectedData, dropdownShow, labelData]);
    // Effect to run runcheckallSubtrue and update selectedData when flag changes
    useEffect(() => {
        runcheckallSubtrue();
        setSelectedData(dataObj);
    }, [flag]);

    // Utility function to convert a string to PascalCase
    function toPascalCase(str) {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <div
                placeholder="Multiple select field"
                className={styles.dropdownSelect}
                onClick={() => setDropdownShow(!dropdownShow)}>
                {Object.keys(dataObj).length > 0 ||
                preFilledDraftData !== undefined
                    ? Object.keys(dataObj).map((item) => (
                          <div key={item}>
                              {dataObj[item].map(
                                  (val) =>
                                      val.isSubDep &&
                                      `${toPascalCase(val.SubDepartment)}, `
                              )}
                          </div>
                      ))
                    : null}
                <div className={styles.dropSelect}>
                    <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        labelData-icon="down"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                    </svg>
                </div>
            </div>
            {dropdownShow && (
                <div className={styles.dropdownBox}>
                    {Object.keys(dataObj).length > 0 ||
                    preFilledDraftData !== undefined ? (
                        <>
                            <div className={styles.innerDropdownBox}>
                                <input
                                    type="checkbox"
                                    id="Select All"
                                    ref={selectAllRef}
                                    onChange={(e) => selectAllHandler(e)}
                                />
                                <label htmlFor="Select All"> Select All</label>
                            </div>
                            <hr />
                            <div className={styles.parentItem}>
                                {Object.keys(dataObj).map((department) => (
                                    <div
                                        className={styles.itemDataMain}
                                        key={department}>
                                        <div
                                            className={styles.innerDropdownBox}>
                                            <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                    checkallsub(department, e)
                                                }
                                                checked={
                                                    dataObj[department][0]
                                                        .isChecked
                                                }
                                            />
                                            <label htmlFor={department}>
                                                {department}
                                            </label>
                                        </div>
                                        <div className={styles.subItem}>
                                            {dataObj[department].length > 0 &&
                                                dataObj[department].map(
                                                    (subdep, index) => (
                                                        <div
                                                            key={
                                                                subdep.SubDepartment
                                                            }
                                                            className={
                                                                styles.innerDropdownBox
                                                            }>
                                                            <input
                                                                onChange={() =>
                                                                    checkSub(
                                                                        department,
                                                                        subdep,
                                                                        index
                                                                    )
                                                                }
                                                                checked={
                                                                    subdep.isSubDep
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <label
                                                                htmlFor={
                                                                    subdep.SubDepartment
                                                                }>
                                                                {
                                                                    subdep.SubDepartment
                                                                }
                                                            </label>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div>No Data</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MultiSubSelectDropDown;
