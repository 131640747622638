export const pathName = (path, search, role) => {
    switch (path) {
        case "/home": {
            return role === "CR" || role === "CM" || role === "CT"
                ? "caseDashboard"
                : "home";
        }
        case "/home/AllCases":
            return search === "?critical=false" ? "AllCases" : "CriticalCases";
        case "/home/HolidayCalendar": {
            return "holidayCalendar";
        }
        case "/home/AwarenessProgramme":
            return "awarenessProgramme";
        case "/home/PolicyDashboard":
            return "policyDashboard";
        case "/home/BroadcastMsg":
            return "broadcastMsg";
        case "/AdminTableView": {
            if (search === "?key=1") return "manageRegion";
            else if (search === "?key=2") return "manageFactories";
            else if (search === "?key=3") return "manageUsers";
            else return null;
        }
        case "/home/reports":
            return "analytics";
        case "/home/incentive":
            return "incentive";
        case "/UpdateRegion":
            return "updateRegion";
        case "/CreateUserFactory": {
            return search === "?key=1" ? "createUser" : "createFactory";
        }
        case "/QualityInspection":
            return "qualityInspection";
    }
    if (path) {
        const pathValue = path.split("/");
        if (pathValue[1] === "ViewLogs") return "logs";
        else if (pathValue[1] === "CaseReport") return "caseReport";
        else if (pathValue[1] === "EditUser") return "editUser";
    }
    return null;
};
