export function getQCReportTab(complainer, pervious_qc) {
    let i = 1;
    const tab = new Map();
    if (pervious_qc) {
        tab.set(i, "Qc Prev Report");
        i = i + 1;
    }
    tab.set(i, "General");
    i = i + 1;
    tab.set(i, "Details");
    i = i + 1;
    tab.set(i, "Closing Report");
    i = i + 1;
    if (complainer) {
        tab.set(i, "Reply");
        i = i + 1;
    }
    tab.set(i, "View Logs");
    i = i + 1;
    tab.set(i, "Qc Report");
    i = i + 1;
    const mapToObject = (map) => Object.fromEntries(map.entries());
    return mapToObject(tab);
}
