import React from "react";
import { Link } from "react-router-dom";
import { Col, Divider, Row } from "antd";
import DateTimeFormatter from "../../../components/Utils/DateTimeFormatter.jsx";
export default function ReplyFromWorker({ caseData }) {
    return (
        <>
            {Object.keys(caseData).map((key) => {
                const data = caseData[key];

                return (
                    <div
                        style={{
                            padding: "10px",
                            fontSize: "13px",
                            margin: "1rem 0rem ",
                        }}>
                        <Row gutter={[16, 64]} style={{ marginBottom: "20px" }}>
                            <Col span={3} style={{ fontWeight: "1000" }}>
                                <h3>Received on</h3>
                            </Col>
                            <Col span={3}>
                                <DateTimeFormatter
                                    dateTimeString={data?.timestamp}
                                />
                                <Divider
                                    style={{
                                        margin: "0",
                                        borderTop: "1px solid #BEBEBE",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 32]}>
                            <Col span={3}>
                                <h3>Message</h3>
                            </Col>
                            <Col span={3}>
                                <audio
                                    onContextMenu={(e) => e.preventDefault()}
                                    controls
                                    src={data?.recording_url}
                                    controlsList="nodownload">
                                    Your browser does not support the
                                    <code>audio</code> element.
                                </audio>
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </>
    );
}
