// AssignUser.js
import React, { useState, useEffect } from "react";
import { Form, Radio, Input, Select, Button, message, Spin } from "antd";
import ExistingDashboardSelector from "../DashboardSelectorComponent/ExistingDashboardSelector";
import {
    addPlusAssignUser,
    assignCases,
} from "../../../../../../../Adapters/AdminCalls";
import { useNavigate } from "react-router";
import { useFetchUserData } from "../../../UserHooks/useFechUserDataAdmin";
const AssignUser = ({
    role,
    user,
    onCancel,
    setModalFooter,
    navigateToSelection,
}) => {
    const { Option } = Select;
    const [screen, setScreen] = useState("choose"); // choose, newUserDetails, existingUserDetails
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [userType, setUserType] = useState(null);
    const [dashboardEmail, setDashboardEmail] = useState("");
    const { fetchUserData } = useFetchUserData();
    const [dashboardUser, setDashboardUser] = useState([]);
    let navigate = useNavigate();
    const handleUserTypeChange = (e) => {
        setUserType(e.target.value);
        form.resetFields(); // Reset form when changing user type
        setScreen(
            e.target.value === "new" ? "newUserDetails" : "existingUserDetails"
        );
    };

    function handleNewUserSubmit() {
        setIsLoading(true);
        const submitData = {
            user_name: form.getFieldValue("username"),
            name: form.getFieldValue("name"),
            email: form.getFieldValue("Email"),
            role: form.getFieldValue("Role"),
            factory_fk: role.factory_fk, // Assuming the value is passed through props
            company_fk: user.company_fk, // Assuming user is passed through props
            gender: form.getFieldValue("gender"),
            mobile_number: form.getFieldValue("phone"),
            region_fk: role.region_fk,
        };

        switch (submitData.role) {
            case "REGIONAL_ADMIN":
            case "SUPER_ADMIN":
                submitData.user_permissions = {
                    hasAccess_Awareness_Program:
                        role.user_permissions.includes(
                            "add_awarenessprogram"
                        ) ||
                        role.group_permissions.includes("add_awarenessprogram"),
                    hasAccess_BroadCast_Message:
                        role.user_permissions.includes(
                            "add_broadcastmessage"
                        ) ||
                        role.group_permissions.includes("add_broadcastmessage"),
                    hasAccess_Holiday_Calender:
                        role.user_permissions.includes("add_holidaycalendar") ||
                        role.group_permissions.includes("add_holidaycalendar"),
                };
                break;
            case "FACTORY_ADMIN":
            case "CT":
            case "CM":
            case "CR":
                submitData.user_permissions = {
                    hasAccess_Awareness_Program:
                        role.user_permissions.includes(
                            "add_awarenessprogram"
                        ) ||
                        role.group_permissions.includes("add_awarenessprogram"),
                    hasAccess_BroadCast_Message: "false",
                    hasAccess_Holiday_Calender: "false",
                };
                break;
            default:
                submitData.user_permissions = {
                    hasAccess_Awareness_Program: form.getFieldValue(
                        "hasAccess_Awareness_Program"
                    )
                        ? "true"
                        : "false",
                    hasAccess_BroadCast_Message: form.getFieldValue(
                        "hasAccess_BroadCast_Message"
                    )
                        ? "true"
                        : "false",
                    hasAccess_Holiday_Calender: form.getFieldValue(
                        "hasAccess_Holiday_Calender"
                    )
                        ? "true"
                        : "false",
                };
                break;
        }

        const formattedDataForAddPlusAssign = {
            to: submitData,
            from: role.id,
        };
        addPlusAssignUser(formattedDataForAddPlusAssign)
            .then((response) => {
                setIsLoading(false);
                if (response.data?.data?.message) {
                    message.success(response.data.data.message);
                    navigate("/AdminTableView?key=3");
                } else if (response?.data.errorMessage) {
                    message.error(response.data.errorMessage);
                } else {
                    message.error(
                        "There was a problem while creating the user"
                    );
                }
                onCancel();
                fetchUserData();
                // if (user.role === {}) {
                //     navigate("/AdminTableView?key=1");
                // }
            })
            .catch(handleError);

        // Error handling function
        function handleError(error) {
            if (
                error?.response?.data?.email ===
                "base user model with this email already exists."
            ) {
                setIsLoading(false);
                message.error("This User already exists");
                form.setFields([
                    {
                        name: "Email",
                        errors: ["This User already exists"],
                    },
                ]);
            } else if (
                error?.response?.data?.user_name ===
                "base user model with this user name already exists."
            ) {
                setIsLoading(false);
                message.error("User with this Username already exists");
                form.setFields([
                    {
                        name: "Username",
                        errors: ["User with this Username already exists"],
                    },
                ]);
            } else {
                setIsLoading(false);
                message.error(error?.response?.data?.errorMessage);
                console.error(error?.response?.data);
            }
        }
    }
    function handleExistingUserSubmit() {
        // Assuming you've stored these values in the state or gotten them from the form
        const fromRole = role.id;
        const toRole = `${dashboardEmail}`; // Update this accordingly based on your state

        assignCases(fromRole, toRole)
            .then((response) => {
                if (response.data.statusCode === "dashboard_assigned") {
                    message.success(response.data.message);
                    fetchUserData();
                    navigate("/AdminTableView?key=3"); // Or wherever you want to redirect after a successful operation
                } else {
                    // Handle success scenario
                    message.success("Assigned cases successfully");
                    navigate("/AdminTableView?key=3"); // Or wherever you want to redirect after a successful operation
                }
            })
            .catch((error) => {
                // Handle error scenario
                message.error(error?.response?.data?.errorMessage);
            });
    }

    const handleFinish = (values) => {
        if (userType === "new") {
            handleNewUserSubmit();
        } else {
            handleExistingUserSubmit();
            // Call API to assign to existing user here
            // onExistingUser(values.existingUser);
        }
    };

    useEffect(() => {
        switch (screen) {
            case "choose":
                setModalFooter(
                    <>
                        <Button onClick={navigateToSelection}>Back</Button>
                    </>
                );
                break;
            case "newUserDetails":
            case "existingUserDetails":
                setModalFooter(
                    <>
                        <Button onClick={() => setScreen("choose")}>
                            Back
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            form="assignUserForm">
                            Submit
                        </Button>
                    </>
                );
                break;
            default:
                break;
        }
    }, [screen]);
    return (
        <div>
            {screen === "choose" && (
                <div>
                    <Radio.Group onChange={handleUserTypeChange}>
                        <Radio value="new">New User</Radio>
                        <Radio value="existing">Existing User</Radio>
                    </Radio.Group>
                </div>
            )}
            {screen !== "choose" && (
                <Form
                    id="assignUserForm"
                    name="assignUserForm"
                    form={form}
                    onFinish={handleFinish}>
                    {screen === "newUserDetails" && (
                        <>
                            {" "}
                            {isLoading && <Spin />}
                            <Form.Item
                                label="Assigned role"
                                name="Role"
                                initialValue={role.role}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a Role",
                                    },
                                ]}>
                                <Input
                                    disabled={true}
                                    value={role.role}></Input>
                            </Form.Item>
                            {role.role === "REGIONAL_ADMIN" ? (
                                <Form.Item
                                    label="Region"
                                    name="region_fk"
                                    initialValue={`${role.region} `} // Display value
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter a Region",
                                        },
                                    ]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    label="Factory"
                                    name="factory_fk"
                                    initialValue={`${role.Code} ${role.Location}`} // Display value
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter a Factory",
                                        },
                                    ]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            )}
                            <Form.Item
                                label="Email "
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please enter the Email Address",
                                    },
                                    {
                                        type: "email",
                                        message:
                                            "Please enter valid Email Address",
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the username!",
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the name!",
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Phone No"
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please input the phone number!",
                                    },
                                    {
                                        pattern: /^[0-9]{10,15}$/,
                                        message:
                                            "Please enter a valid phone number!",
                                    },
                                ]}>
                                <Input prefix={"+91"} maxLength={10} />
                            </Form.Item>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select the gender!",
                                    },
                                ]}>
                                <Select>
                                    <Select.Option value={"Male"}>
                                        Male
                                    </Select.Option>
                                    <Select.Option value={"Female"}>
                                        Female
                                    </Select.Option>
                                    <Select.Option value={"Not Specified"}>
                                        Not Specified
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </>
                    )}
                    {screen === "existingUserDetails" && (
                        <ExistingDashboardSelector
                            currentUser={user}
                            setUser={setDashboardUser}
                            searchbyRole={false}
                            role={role}
                            user={user}
                            parentForm={form}
                            onEmailChange={(value) => {
                                setDashboardEmail(value);
                            }}></ExistingDashboardSelector>
                    )}
                </Form>
            )}
        </div>
    );
};
export default AssignUser;
