export function getCounterFromCaseStatus(CaseStatus) {
    switch (CaseStatus) {
        case "Unpoened":
            return 0;
        case "Assigned to Reporter":
            return 1;
        case "Assigned to Manager":
            return 2;
        case "Assigned to Troubleshooter":
            return 3;
        case "Assigned to Regional Admin":
            return 3;
        case "Investigation under Regional Admin":
            return 4;
        case "Under Investigation":
            return 4;
        case "Re Investigation":
            return 5;
        case "Regional Admin ReInvestigation":
            return 5;
        case "Resolved":
            return 6;
        case "Closed":
            return 7;
        case "Closed with Positive Response":
            return 7;
        case "Unresponsive":
            return 7;
        case "Assigned to Quality Checker":
            return 8;
        case "Approved by QC":
            return 9;
        case "Completed":
            return 10;
    }
}
