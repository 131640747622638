export function getQCReportTabStatus(tab, statusOrTabName) {
    const statusToTabName = {
        ...tab,
    };

    // For reverse lookup, invert keys and values
    const tabNameToStatus = Object.entries(statusToTabName).reduce(
        (acc, [key, value]) => {
            acc[value] = parseInt(key, 10);
            return acc;
        },
        {}
    );

    if (typeof statusOrTabName === "number") {
        // Forward lookup: status to tab name
        return statusToTabName[statusOrTabName] || "Unknown Status";
    } else if (typeof statusOrTabName === "string") {
        // Reverse lookup: tab name to status
        return tabNameToStatus[statusOrTabName] !== undefined
            ? tabNameToStatus[statusOrTabName]
            : "Unknown Tab Name";
    } else {
        return "Invalid Input Type";
    }
}
