import React, { useEffect, useState } from "react";
import styles from "../../IncentiveDashboard/Charts/Charts.module.css";
import { Pie } from "@ant-design/plots";
import { Row, Col } from "antd";
export default function SeverityChart({ caseData }) {
    const data = [
        { type: "High", value: caseData?.High },
        { type: "Medium", value: caseData?.Medium },
        { type: "Low", value: caseData?.Low },
    ];
    const config = {
        data,
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.75,
        label: {
            type: "spider",
            labelHeight: 28,
            content: "{name}\n{percentage}",
        },
        interactions: [
            { type: "element-selected" },
            { type: "element-active" },
        ],
    };
    return (
        <>
            <div
                className={styles.container + " " + styles.compliance}
                style={{ pageBreakinside: "avoid", pageBreakAfter: "always" }}
                id="compliance-chart">
                <h1>Severity of cases</h1>
                <Pie {...config} />
            </div>
        </>
    );
}
