/*
 * This is the route, basically when user gets reset link they will be redirected to this page and they can reset their password
 * RESET PASSWORD INPUT AND SUBMIT BUTTOn`
 *
 * */
import { Button, PageHeader, message, Checkbox, Form, Input } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ResetPasswordFinalWithToken } from "../../Adapters/ResetPasswordCalls.js";
import styles from "../Login/Login.module.css";
import { useNavigate } from "react-router";
export default function ResetPassword() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [newPassword, setNewPassword] = useState("");
    const [reEnterPassword, setReEnterPassword] = useState("");
    const [passwordMatchingError, setPasswordMatchingError] = useState(false);
    const [passwordVisiblity, setpasswordVisiblity] = useState(false);
    const [rePasswordVisiblity, setRePasswordVisiblity] = useState(false);
    const navigate = useNavigate();

    const onFinish = (e) => {
        e.preventDefault();
        if (newPassword === reEnterPassword) {
            setPasswordMatchingError(false);
            try {
                ResetPasswordFinalWithToken(
                    searchParams.get("token"),
                    searchParams.get("uidb64"),
                    newPassword
                )
                    .then((res) => {
                        message.success("Password reset complete");
                        navigate("/"); //after password change navigate back to login page
                    })
                    .catch((err) => {
                        message.error(
                            "Invalid Link ! Please try again from login page"
                        );
                    });
            } catch (error) {}
        } else {
            setPasswordMatchingError(true);
        }
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.leftLogin}>
                <form className={styles.loginMain} onSubmit={onFinish}>
                    <div className={styles.mainHeadingWrapper}>
                        <h2>Reset Password</h2>
                    </div>
                    <p>Set new Password</p>
                    <div className={styles.loginDetails}>
                        <div>
                            <label>
                                New Password
                                <div>
                                    <input
                                        required
                                        type={
                                            passwordVisiblity
                                                ? "text"
                                                : "password"
                                        }
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => {
                                            setNewPassword(e.target.value);
                                        }}
                                    />
                                    <span
                                        onClick={() => {
                                            setpasswordVisiblity(
                                                !passwordVisiblity
                                            );
                                        }}>
                                        <img src="/assets/images/LoginPage/eye.svg" />
                                    </span>
                                </div>
                            </label>
                            <label>
                                Re-enter Password
                                <div
                                    id={
                                        passwordMatchingError
                                            ? styles.passwordMatchingError
                                            : ""
                                    }>
                                    <input
                                        required
                                        type={
                                            rePasswordVisiblity
                                                ? "text"
                                                : "password"
                                        }
                                        placeholder="Re-enter password"
                                        value={reEnterPassword}
                                        onChange={(e) => {
                                            setReEnterPassword(e.target.value);
                                        }}
                                    />
                                    <span
                                        onClick={() => {
                                            setRePasswordVisiblity(
                                                !rePasswordVisiblity
                                            );
                                        }}>
                                        <img src="/assets/images/LoginPage/eye.svg" />
                                    </span>
                                </div>
                                {passwordMatchingError ? (
                                    <div
                                        className={styles.errMsgDisplay}
                                        style={{ color: "red" }}>
                                        Both the password doesn't match
                                    </div>
                                ) : null}
                            </label>
                        </div>
                        <div></div>
                    </div>
                    <button className={styles.grey} type="submit">
                        Save
                    </button>
                </form>
            </div>
            <div className={styles.rightLogin}>
                <img
                    width="100%"
                    height="500"
                    src="/assets/images/LoginPage/Frame.png"
                />
            </div>
        </div>
    );
}
