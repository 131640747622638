import React from "react";

import styles from "../IncentiveDashboard/CaseCountList/CaseCountList.module.css";

export default function CaseCountsBox({ caseData }) {
    const caseItems = [
        { caseCount: caseData?.New, text: "New" },
        { caseCount: caseData?.Closed, text: "Closed" },
        {
            caseCount: caseData?.Resolved,
            text: "Resolved",
        },

        { caseCount: caseData?.Inprogress, text: "Inprogress" },
    ];

    return (
        <>
            {caseItems.map((item, index) => {
                return (
                    <div className={styles.item}>
                        <h3>{item.caseCount}</h3>
                        <p>{item.text}</p>
                    </div>
                );
            })}
        </>
    );
}
