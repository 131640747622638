import React, { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const SelectVersionFilter = ({
    selectedVersion,
    setSelectedVersion,
    version,
}) => {
    const handleVersionChange = (value) => {
        setSelectedVersion(value);
    };

    return (
        <div>
            <Select
                id="versionSelect"
                value={selectedVersion}
                onChange={handleVersionChange}
                placeholder="Select Version">
                {[...Array(version - 1)].map((_, index) => (
                    <Option key={index + 1} value={`${index + 1}`}>
                        Version {index + 1}
                    </Option>
                ))}
            </Select>
        </div>
    );
};
export default SelectVersionFilter;
