import React from "react";
import { useState, useEffect } from "react";
import DynamicTable from "../../../Utils/DynamicTable/DynamicTable";
import EditFactoryButton from "../FactoryAction/EditFactoryButton";
import DeleteFactoryButton from "../FactoryAction/DeleteFactoryButton";
import _ from "lodash";
import { Button, Input } from "antd";
import DateTimeFormatter from "../../../Utils/DateTimeFormatter";
import { DatePicker } from "antd";
import { useRecoilState } from "recoil";
import { userState } from "../../../../RecoilState/userState";
import ViewFactoryButton from "../FactoryAction/ViewFactoryButton";

const FactoryTableView = ({ url, data }) => {
    const [visible, setVisible] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [user] = useRecoilState(userState);
    const { RangePicker } = DatePicker;

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item, index) => ({
            text: formatter(item),
            value: formatter(item),
            key: `${formatter(item)}_${index}`,
        }));

    const handleFilter = (value, record) => {
        const timestamp = new Date(record.timestamp).getTime();
        return (
            timestamp >= value[0].startOf("day").valueOf() &&
            timestamp <= value[1].endOf("day").valueOf()
        );
    };

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };

    const columns = [
        {
            title: "Unit No",
            dataIndex: "Code",
            key: "Code",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Code - b.Code,
            filters: filterData(data, (item) => item?.Code),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Code =
                    record?.Code &&
                    record?.Code.toString().toLowerCase().trim();
                return Code.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Division",
            dataIndex: "division",
            key: "division",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.division - b.division,
            filters: filterData(data, (item) => item?.division),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Division =
                    record?.division &&
                    record?.division.toString().toLowerCase().trim();

                return Division ? Division.indexOf(searchValue) !== -1 : false;
            },
            filterDropdown: searchDropdown,

            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Location",
            dataIndex: "Location",
            key: "Location",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a?.Location - b?.Location,
            filters: filterData(data, (item) => item?.Location),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Location =
                    record?.Location &&
                    record?.Location.toString().toLowerCase().trim();
                return Location.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "LastModified",
            dataIndex: "last_modified",
            key: "last_modified",
            sorter: (a, b) => a?.last_modified - b?.last_modified,
            render: (text) => <DateTimeFormatter dateTimeString={text} />,
        },

        {
            title: "Factory Admin",
            dataIndex: "FactoryAdmin",
            key: "FactoryAdmin",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a?.FactoryAdmin - b?.FactoryAdmin,
            filters: filterData(data, (item) => item?.FactoryAdmin),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const FactoryAdmin =
                    record?.FactoryAdmin &&
                    record?.FactoryAdmin.toString().toLowerCase().trim();
                return FactoryAdmin.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Inache No",
            dataIndex: "Number",
            key: "Number",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Number - b.Number,
            filters: filterData(data, (item) => item?.Number),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Number =
                    record?.Number &&
                    record?.Number.toString().toLowerCase().trim();
                return Number.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <div>
                    <ViewFactoryButton props={record} />
                    <EditFactoryButton props={record} />
                    {user?.group_permissions?.includes("delete_factory") && (
                        <DeleteFactoryButton props={record} />
                    )}
                </div>
            ),
        },
    ];

    return <DynamicTable data={data} columns={columns} />;
};

export default FactoryTableView;
