import { Layout } from "antd";

import CollapsableMenu from "../../CollapsableMenu";

import TableContainer from "../../Home/TableContainer";
import React from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import { factoryState } from "../../../RecoilState/FactoryState";

const CaseDashboard = () => {
    const [user] = useRecoilState(userState);
    const [factory] = useRecoilState(factoryState);

    return (
        <div>
            <Layout>
                <CollapsableMenu></CollapsableMenu>
                <TableContainer user={user} selectedFactory={factory} />
            </Layout>
        </div>
    );
};

export default CaseDashboard;
