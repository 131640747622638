import { useState, useEffect } from "react";
import React from "react";
import { Row, Col, Form, Input, Button, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { getFactorydetails } from "../../../../Adapters/FactoryAndCompanyCalls";
import styles from "../../AdminDashboard.module.css";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import AddEditDropdown from "../../../Utils/AddEditDropdown";
import { getDivisionDetails } from "../../../../Adapters/DivisionCalls";
import EditFactoryButton from "./EditFactoryButton";

const ViewFactoryButton = ({ props }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [FactoryDataForm] = Form.useForm();
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [divisionList, setDivisionList] = useState([]);
    const [handleDropdown, setHandleDropdown] = useState({
        open: false,
        edit: false,
        editId: null,
        disableInput: true,
        refreshRegion: true,
    });
    const [fieldValue, setFieldValue] = useState({
        id: null,
        fieldrename: "",
        addField: "",
        newFieldName: "",
    });

    const fetchDivisionFactoryData = () => {
        getDivisionDetails(props.Company_id).then((res) => {
            setDivisionList(res.data.message_body.Division);
        });
        getFactorydetails(props?.id).then((res) => {
            FactoryDataForm.setFieldsValue({
                male_users: res.data.male_users,
                male_workers: res.data.male_workers,
                female_users: res.data.female_users,
                female_workers: res.data.female_workers,
                other_workers: res.data.other_workers,
                other_users: res.data.other_users,
                total_users: res.data.total_users,
                total_workers: res.data.total_workers,
            });
        });
    };

    useEffect(() => {
        if (isModalVisible) {
            fetchDivisionFactoryData();
        }
    }, [isModalVisible]);

    useEffect(() => {
        FactoryDataForm.setFieldsValue({
            Company: props?.Company,
            Code: props?.Code,
            Location: props?.Location,
            division: props?.division_id,
            Number: props?.Number,
        });
        setFieldValue((prev) => ({
            ...prev,
            id: props?.division_id,
        }));
    }, [props]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = (eventName, actionType) => {
        setIsModalVisible(false);
        updateEventMetrics(eventName, actionType);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button
                icon={<EyeOutlined />}
                type="text"
                onClick={showModal}></Button>
            <Modal
                title="Factory Details"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <button
                        key="cancel"
                        onClick={handleCancel}
                        className="secondaryButton">
                        Cancel
                    </button>,
                    <button
                        className={styles.solidButtonFactory}
                        key="ok"
                        onClick={() =>
                            handleOk("Edit Factory", "Edit Factory - page")
                        }>
                        <EditFactoryButton props={props} showText={true} />
                    </button>,
                ]}
                width={1400}
                closeIcon>
                <div>
                    <Form
                        className={styles.scrollableContainer}
                        name="User Detail Form"
                        form={FactoryDataForm}
                        initialValues={props}>
                        <Row
                            gutter={16}
                            style={{ width: "100%", color: "black" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item label="Company" name="Company">
                                    <Input
                                        disabled
                                        className={styles.disableColor}
                                    />
                                </Form.Item>
                                <Form.Item label="Factory Number" name="Code">
                                    <Input
                                        className={styles.disableColor}
                                        disabled></Input>
                                </Form.Item>

                                <Form.Item label="Division" name="Division">
                                    <AddEditDropdown
                                        selectDisabled={true}
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        handleDropdown={handleDropdown}
                                        setHandleDropdown={setHandleDropdown}
                                        fields={divisionList}
                                    />
                                </Form.Item>
                                <Form.Item label="Location" name="Location">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                        value={props?.Location}
                                    />
                                </Form.Item>

                                <Form.Item label="Inache Number" name="Number">
                                    <Input
                                        value={props?.Number}
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Total Worker"
                                    name="total_workers">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Male" name="male_workers">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Female" name="female_workers">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Other" name="other_workers">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                                <div style={{ marginTop: "40px" }}></div>
                                <Form.Item
                                    label="Total User"
                                    name="total_users">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Male " name="male_users">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Female " name="female_users">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item label="Other " name="other_users">
                                    <Input
                                        className={styles.disableColor}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default ViewFactoryButton;
