import React, { useState, useEffect } from "react";
import { Col, Row, Spin } from "antd";
import PdfViewer from "../../../components/Utils/PDFViewer";
import styles from "../Policies.module.css";
import { getPresignedUrlFromFileIDs } from "../../../Adapters/UploadCalls";

const PoliciesTab = ({ policies, loadingData, links, attachments }) => {
    const firstPolicyId =
        policies && Object.values(policies).length > 0
            ? Object.values(policies)[0][0]
            : null;
    const [selectedPolicyId, setSelectedPolicyId] = useState(firstPolicyId);
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (firstPolicyId) {
            setSelectedPolicyId(firstPolicyId);
        }
    }, [firstPolicyId]);

    const handleTitleClick = (policyId) => {
        setSelectedPolicyId(policyId);
    };

    const fetchPresignedUrl = async (policyId) => {
        try {
            const response = await getPresignedUrlFromFileIDs(policyId);
            setPdfUrl(response.data[policyId]?.presigned_url);
        } catch (error) {
            console.error("Error fetching pre-signed URLs:", error);
        }
    };

    useEffect(() => {
        if (selectedPolicyId) {
            fetchPresignedUrl(selectedPolicyId);
        }
    }, [selectedPolicyId]);

    return (
        <Row className={styles.rowStyle}>
            {loadingData ? (
                <div className="centerContent">
                    <Spin />
                </div>
            ) : (
                <Col span={24}>
                    <Row className={styles.innerRowStyle}>
                        {Object.values(policies).length > 0 ||
                        Object.entries(links).length > 0 ? (
                            <>
                                <Col span={16} className={styles.colStyle}>
                                    <PdfViewer pdfUrl={pdfUrl} />
                                </Col>
                                <Col span={8} className={styles.colStyle}>
                                    <Row className={styles.headerRowStyle}>
                                        <h3 className={styles.headerStyle}>
                                            All Policies
                                        </h3>
                                    </Row>
                                    {Object.values(policies).length > 0 && (
                                        <>
                                            <h3 className={styles.textStyle}>
                                                Policies with Documents
                                            </h3>
                                            <ol
                                                className={
                                                    styles.policyListStyle
                                                }>
                                                {Object.entries(
                                                    attachments
                                                ).map(
                                                    (
                                                        [
                                                            policyName,
                                                            policyDocID,
                                                        ],
                                                        index
                                                    ) => (
                                                        <li
                                                            key={index}
                                                            className={
                                                                styles.policyItemStyle
                                                            }>
                                                            <span
                                                                onClick={() =>
                                                                    policyDocID &&
                                                                    policyDocID.length >
                                                                        0 &&
                                                                    handleTitleClick(
                                                                        policyDocID[0]
                                                                    )
                                                                }
                                                                className={
                                                                    selectedPolicyId ===
                                                                    policyDocID[0]
                                                                        ? styles.policyLinkActiveStyle
                                                                        : styles.policyLinkStyle
                                                                }>
                                                                {policyName}
                                                            </span>
                                                        </li>
                                                    )
                                                )}
                                            </ol>
                                        </>
                                    )}
                                    {Object.entries(links).length > 0 && (
                                        <>
                                            <h3 className={styles.textStyle}>
                                                Policies with Links
                                            </h3>
                                            <ol
                                                className={
                                                    styles.policyListStyle
                                                }>
                                                {Object.entries(links).map(
                                                    (
                                                        [
                                                            policyName,
                                                            attachedLinks,
                                                        ],
                                                        index
                                                    ) => (
                                                        <li
                                                            key={index}
                                                            className={
                                                                styles.policyItemStyle
                                                            }>
                                                            <a
                                                                href={
                                                                    attachedLinks
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                {policyName}
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </ol>
                                        </>
                                    )}
                                </Col>
                            </>
                        ) : (
                            <h3>No Policy Added yet</h3>
                        )}
                    </Row>
                </Col>
            )}
        </Row>
    );
};

export default PoliciesTab;
