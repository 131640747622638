import React from "react";
import { useRecoilState } from "recoil";
import { factoryState } from "../../../RecoilState/FactoryState";
import styles from "./CaseCountList.module.css";

export default function CaseCountList({ caseData }) {
    const [factory, setFactory] = useRecoilState(factoryState);
    const caseItems = [
        { caseCount: caseData?.Closed, text: "Closed" },
        {
            caseCount: caseData?.Resolved,
            text: "Resolved",
        },

        { caseCount: caseData?.InProcess, text: "In-Process" },
    ];

    return (
        <>
            {caseItems.map((item, index) => {
                return (
                    <div className={styles.item}>
                        <h3>{item.caseCount}</h3>
                        <p>{item.text}</p>
                    </div>
                );
            })}
        </>
    );
}
