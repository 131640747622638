import { useRecoilState } from "recoil";
import { common_axios } from "../utils/AxiosSettings";
import dayjs from "dayjs";

export async function getAllHolidays(selectedMonthYear, mode, FactorySelected) {
    if (selectedMonthYear && mode) {
        const res = await common_axios.get(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?${mode}=${selectedMonthYear}&Factory=${FactorySelected}  
        `
        );
        return res;
    } else {
        const res = await common_axios.get(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?Factory=${FactorySelected}  
        `
        );

        return res;
    }
}
export async function addHoliday(eventName, duration, factory) {
    const res = common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/
        `,

        {
            eventName: eventName,
            startDate: duration[0],
            endDate: duration[1],
            Factory: factory,
        }
    );

    return res;
}
export async function updateHoliday(id, eventName, duration, factory) {
    const res = common_axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?id=${id}
        `,
        {
            eventName: eventName,
            startDate: `${dayjs(duration[0]).format(
                "YYYY-MM-DD"
            )} 00:00:00.000000+00:00`,

            endDate: `${dayjs(duration[1]).format(
                "YYYY-MM-DD"
            )} 00:00:00.000000+00:00`,
            Factory: factory,
        }
    );
    return res;
}
export async function deleteHoliday(id) {
    const res = common_axios.delete(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?id=${id}
        `
    );

    return res;
}
