import React, { useRef, useState } from "react";
import { Modal, message, Input, Button } from "antd"; // Import Input from antd
import { deleteRole } from "../../../../../../Adapters/AdminCalls";
import styles from "../../../../AdminDashboard.module.css";
import useEventMetricsUpdater from "../../../../../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";

const DeleteRoleModal = ({
    role,
    visible,
    onCancel,
    onDelete,
    fetcher,
    setIsDeleteModalVisible,
}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmationInput, setConfirmationInput] = useState(""); // State for the confirmation input
    const inputRef = useRef(null);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const handleDelete = async (eventName, actionType) => {
        // Check if the confirmation input is "delete"
        if (confirmationInput !== "Delete") {
            message.error("Please type 'Delete' to confirm.");
            return;
        }

        setIsDeleting(true);

        try {
            const res = role.id
                ? await deleteRole(role.id)
                : await deleteRole(role.roleId);
            if (res?.data?.message) {
                message.success(res.data.message);
                fetcher();
                updateEventMetrics(eventName, actionType);
            } else {
                message.error(res?.data?.errorMessage);
            }
        } catch (error) {
            message.error("An error occurred while deleting the role.");
        } finally {
            setIsDeleting(false);
            onDelete();
        }
    };

    const handleCancel = () => {
        setConfirmationInput("");
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        onCancel();
    };
    const debouncehandler = debounce((eventName, actionType) => {
        handleDelete(eventName, actionType);
    }, 500);

    return (
        <Modal
            title="Confirm Deletion"
            visible={visible}
            confirmLoading={isDeleting}
            onCancel={handleCancel}
            onOk={() =>
                debouncehandler(
                    "deleteUser",
                    "Delete User - Confirm Delete User, Confirm"
                )
            }
            footer={[
                <Button
                    key="cancel"
                    onClick={handleCancel}
                    className={styles.outlineButton}>
                    Cancel
                </Button>,
                <Button
                    key="ok"
                    onClick={() =>
                        debouncehandler(
                            "deleteUser",
                            "Delete User - Confirm Delete User, Confirm"
                        )
                    }
                    className={styles.solidButton}>
                    Confirm
                </Button>,
            ]}
            height={220}
            width={425}
            closeIcon>
            <div>
                <h3>Are you sure you want to delete the role {role?.Role}?</h3>
                <p>Type Delete</p>
                <Input
                    label="Type Delete"
                    placeholder="Type here"
                    value={confirmationInput}
                    onChange={(e) => setConfirmationInput(e.target.value)}
                    ref={inputRef}></Input>
            </div>
        </Modal>
    );
};

export default DeleteRoleModal;
