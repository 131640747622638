import React, { useEffect } from "react";
import ButtonCard from "./ButtonCard";
import styles from "./ButtonContainer.module.css";
import Modal from "react-modal";
import CaseUpload from "../../../CaseUpload";
import Generatereport from "../../../Generatereport";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../../../../RecoilState/userState";
import { flagState } from "../../../../RecoilState/flagState";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import FactoryListMenu from "../../../FactoryListMenu";
import { factoryState } from "../../../../RecoilState/FactoryState";
import Dropdown from "../../../../@setproduct-ui/core/Dropdown";
import { dashboardData } from "../../../../RecoilState/dashboardData";

Modal.setAppElement("#root");

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const buttonData = [
    {
        text: "Case Upload",
        color: "purple",
        icon: "/assets/images/ButtonLogos/icon/action/note_add_24px.svg",
        clicker: "openCaseUploadModal",
    },
];

export default function ButtonContainer({ tourRef, setTourData }) {
    const [UploadmodalIsOpen, setUploadIsOpen] = React.useState(false);
    const [GenerateisOpen, setGenerateIsOpen] = React.useState(false);
    const [FactoryDataList, setFactoryDataList] = useRecoilState(FactoryData);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [alldashboardData, setdashboardData] = useRecoilState(dashboardData);

    const [user] = useRecoilState(userState);
    const [flag, setFlagState] = useRecoilState(flagState);

    let navigate = useNavigate();
    const location = useLocation();
    function openUploadModal() {
        setUploadIsOpen(true);
    }

    function openGenerateModal() {
        setGenerateIsOpen(true);
    }

    function closeModal() {
        setUploadIsOpen(false);
        setFlagState(!flag);
    }
    function closeGenerateModal() {
        setGenerateIsOpen(false);
    }

    function handleButtonClick(clicked) {
        if (clicked == "openGenerateReportModal") {
            openGenerateModal();
        } else if (clicked == "openCaseUploadModal") {
            openUploadModal();
        } else {
            navigate(`/BroadcastMsg`);
        }
    }

    const QCbuttonData = [
        {
            text: "Intro",
            image: "/assets/images/QCReport/Play.svg",
            ref: tourRef.introRef,
        },
        {
            text: "Case Reviewed",
            value:
                alldashboardData.length !== 0
                    ? `${alldashboardData.reviewed}/${alldashboardData.total}`
                    : "N/A",
            ref: tourRef.casesRef,
        },
        {
            text: "Due Date",
            value:
                alldashboardData.length !== 0
                    ? alldashboardData.dueDate
                    : "N/A",
            ref: tourRef.dateRef,
        },
    ];
    return (
        <div className={styles.ButtonContainer}>
            <Modal
                isOpen={UploadmodalIsOpen}
                onRequestClose={closeModal}
                // style={customStyles}
                style={{
                    content: {
                        inset: "120px",
                        position: "absolute",
                        border: "1px solid #ccc",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "16px",
                        outline: "none",
                        padding: "0px",
                        height: "63%",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    },
                }}
                contentLabel="Example Modal">
                <CaseUpload modalCloser={closeModal} />
            </Modal>
            <Modal
                isOpen={GenerateisOpen}
                onRequestClose={closeGenerateModal}
                style={customStyles}
                contentLabel="Example Modal">
                <Generatereport modalCloser={closeGenerateModal} />
            </Modal>
            {user.role === "QC" ? (
                <div className={styles.QCButtonContainer}>
                    {QCbuttonData.map((button, index) => (
                        <div
                            key={index}
                            className={styles.ButtonCard}
                            style={{ width: button.text == "Intro" && "auto" }}
                            onClick={() => {
                                button.text == "Intro" &&
                                    setTourData((tour) => ({
                                        ...tour,
                                        open: true,
                                    }));
                            }}
                            ref={button.ref}>
                            {button.image && (
                                <img
                                    src={button.image}
                                    alt="intro icon"
                                    style={{ marginRight: "-20px" }}
                                />
                            )}
                            <span className={styles.value}>{button.value}</span>
                            <span>{button.text}</span>
                        </div>
                    ))}
                </div>
            ) : (
                <div style={{ marginRight: "6em" }}>
                    {user.role !== "SUPER_ADMIN" &&
                    user.role !== "FACTORY_ADMIN" &&
                    user.role !== "REGIONAL_ADMIN" &&
                    user?.role !== "LEAD_SUPERVISOR"
                        ? buttonData.map((button, index) => {
                              return (
                                  <div
                                      key={button.text}
                                      onClick={() => {
                                          handleButtonClick(button.clicker);
                                      }}>
                                      <ButtonCard
                                          id={"CaseUploadButton"}
                                          key={button.text}
                                          text={button.text}
                                          colorLogo={button.color}
                                          icon={button.icon}
                                      />
                                  </div>
                              );
                          })
                        : (user.role === "SUPER_ADMIN" ||
                              user?.role === "LEAD_SUPERVISOR" ||
                              user.role === "REGIONAL_ADMIN") &&
                          location.search === "?critical=false" && (
                              <Dropdown
                                  view="filled"
                                  color="primary"
                                  text={
                                      FactorySelected
                                          ? `${FactorySelected?.Code} - ${FactorySelected?.Location}`
                                          : FactoryDataList &&
                                            FactoryDataList.length > 0
                                          ? `${FactoryDataList[0]?.Code} - ${FactoryDataList[0]?.Location}`
                                          : "No Factory Data"
                                  }
                                  position="bottom-left"
                                  content={
                                      FactoryDataList &&
                                      FactoryDataList.length > 0 ? (
                                          <FactoryListMenu
                                              factoryData={FactoryDataList}
                                          />
                                      ) : (
                                          <div>No Factory Data</div>
                                      )
                                  }
                                  dense="false"
                                  className={styles.dropdown}
                              />
                          )}
                </div>
            )}
        </div>
    );
}
