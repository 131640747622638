import { message, Button, Space } from "antd";

export const success = (messageText) => {
    message.success(messageText);
};

export const error = (messageText) => {
    message.error(messageText);
};

export const warning = (messageText) => {
    message.warning(messageText);
};

export const loadingMessage = (key) => {
    message.loading({ content: "Loading...", key });
};

export const finishLoadingMessage = (messageText, key) => {
    message.success({ content: messageText, key, duration: 2 });
};
