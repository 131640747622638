import React, { useState } from "react";
import { userState } from "../../../RecoilState/userState";
import { useRecoilState } from "recoil";
import { getAllFactoriesOfCompany } from "../../../Adapters/FactoryAndCompanyCalls";
import { FactoryData } from "../../../RecoilState/FactoryData";
import FactoryTableView from "./FactoryTableView/FactoryTableView";
import { useEffect } from "react";
import { tokenState } from "../../../RecoilState/tokenState";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { factoryState } from "../../../RecoilState/FactoryState";
import { useFetchFactories } from "../UserDashboard/UserAction/UserHooks/useFetchFactories";

const FactoryDashboard = () => {
    const [user, setUser] = useRecoilState(userState);
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const [userFactory, setUserFactory] = useRecoilState(factoryState);
    const { fetchFactoryData, isLoading, factories } = useFetchFactories();
    // Factory API call

    return FactoryList === undefined || FactoryList === null ? (
        <Result icon={<SmileOutlined />} title="No Factory data available" />
    ) : (
        <>
            {user?.group_permissions?.includes("add_factory") ? (
                <FactoryTableView data={FactoryList}></FactoryTableView>
            ) : (
                <FactoryTableView data={[userFactory]}></FactoryTableView>
            )}
        </>
    );
};

export default FactoryDashboard;
