import axios from "axios";
import { getNewAccessFromRefresh } from "../Adapters/RefreshTokenCall";
import { Cookies } from "react-cookie";
import { message } from "antd";
const common_axios = axios.create({});
const cookies = new Cookies();
let navigateCallback; // Callback function for navigation
let navigateMatricCallback;
let refreshTokenCallback;

common_axios.interceptors.request.use(
    (Reqconfig) => {
        return Reqconfig;
    },
    (error) => {
        return Promise.reject(error).catch((err) => {
            console.log(
                "Error caught in request interceptor catch block:",
                err
            );
            throw err;
        });
    }
);
common_axios.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if (err.code === "ECONNABORTED") {
            message.error("Request timeout occurred. Please try again later.");
        } else if (err.message === "Network Error") {
            message.error(
                "Network error occurred. Please check your internet connection."
            );
            if (navigateCallback) {
                navigateCallback("/ServerError"); // Invoke the callback for navigation
            }
        } else if (err.response) {
            switch (err.response.status) {
                case 401:
                    if (localStorage.getItem("flag") === "true") {
                        const cookieToken = cookies.get("role-token-cookie");
                        if (cookieToken?.refresh) {
                            try {
                                localStorage.setItem("flag", false); // Set the flag to indicate token refresh is in progress
                                const res = await getNewAccessFromRefresh(
                                    cookieToken.refresh
                                );
                                let newTokenObject = {
                                    refresh: res?.data?.refresh,
                                    access: res?.data?.access,
                                };
                                cookies.set("token", newTokenObject, {
                                    path: "/",
                                });
                                cookies.set(
                                    "user-token-cookie",
                                    newTokenObject,
                                    {
                                        path: "/",
                                    }
                                );
                                cookies.set(
                                    "role-token-cookie",
                                    newTokenObject,
                                    {
                                        path: "/",
                                    }
                                );
                                if (refreshTokenCallback) {
                                    refreshTokenCallback(newTokenObject); // Setting new token in the state
                                } else {
                                    localStorage.setItem("flag", true);
                                }
                                common_axios.defaults.headers.common[
                                    "Authorization"
                                ] = `Bearer ${newTokenObject?.access}`;
                                message.info("Try again!");
                            } catch (error) {
                                message.info(
                                    "Session Expired: Your current session has timed out. Please log in again to continue!"
                                );
                                if (localStorage.getItem("flag") == "false") {
                                    navigateMatricCallback("Session Logout");
                                }
                                console.log(error, "error getting new token");
                            }
                        } else {
                            localStorage.setItem("flag", false);
                            if (
                                localStorage.getItem("flag") == "false" &&
                                navigateMatricCallback
                            ) {
                                navigateMatricCallback("Session Logout");
                            }
                            if (!err.response?.data?.error) {
                                message.error("Try again!");
                            }
                        }
                    }
                    break;
                case 500:
                    console.log("It's 500");
                default:
                    console.log(
                        "No specific handling for this response status code"
                    );
                    break;
            }
        } else {
            message.info("Unexpected error occurred", err.message);
        }
        return Promise.reject(err);
    }
);
function setNavigateCallback(callback) {
    navigateCallback = callback; // Set the callback for navigation
}
function setNavigateMatricCallback(callback) {
    navigateMatricCallback = callback; // Set the callback for navigation
}
function setRefreshTokenCallback(callback) {
    refreshTokenCallback = callback; // Set the callback for navigation
}
export {
    common_axios,
    setNavigateCallback,
    setNavigateMatricCallback,
    setRefreshTokenCallback,
};
