import { Button, Modal } from "antd";
import React from "react";
import styles from "../../AdminDashboard.module.css";

const ChangesConfirmationModal = ({ open, onCancel, onExit }) => {
    return (
        <Modal
            title={"Changes not saved"}
            open={open}
            footer={[
                <Button key="cancel" type="secondary" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="ok"
                    onClick={onExit}
                    className={styles.solidButton}>
                    Exit
                </Button>,
            ]}
            width={300}
            closeIcon>
            <div>
                <p>
                    The changes made will be dismissed off. Please Make sure to
                    save.
                </p>
            </div>
        </Modal>
    );
};

export default ChangesConfirmationModal;
