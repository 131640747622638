/* Custom table display used in broadcast messages and view logs */
import React from "react";
import styles from "../BroadcastMsg.module.css";
export default function TableComponent(props) {
    const tableGrid = props.tableHeadings
        .map(() => {
            return "minmax(150px, 1fr)";
        })
        .join("");
    return (
        <table
            className={styles.tableTwo}
            style={{
                gridTemplateColumns: tableGrid,
            }}>
            <thead>
                <tr className={styles.header}>
                    {props.tableHeadings.map((heading, index) => {
                        return <th key={index}>{heading}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {props.tableData.map((data, index) => {
                    return (
                        <tr
                            key={index}
                            onClick={() => {
                                if (props.handleClickOpen) {
                                    props.handleClickOpen({
                                        Factory: data[2],
                                        Englishmessage: data[4],
                                        Regionalmessage: data[3],
                                    });
                                } else {
                                    return null;
                                }
                            }}>
                            {data.map((data, index) => {
                                return <td key={index}>{data}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
