/* Component with three tabs which are NewMessage, DraftMessage and Sent Messages */
import React, { useState } from "react";
import styles from "./BroadcastMsg.module.css";
import NewMessage from "./NewMessage";
import DraftMessage from "./DraftMessage";
import SentMessage from "./SentMessage";
import { Link } from "react-router-dom";
import AuthenticationComp from "../../containers/AuthComponent/AuthenticationComp";
import LoggedInComponent from "../../containers/LoggedInComponent/LoggedInComponent";
import { common_axios } from "../../utils/AxiosSettings";
import { useRecoilState } from "recoil";
import { tokenState } from "../../RecoilState/tokenState";
export default function BroadcastMsg() {
    const [MessageScreen, setMessageScreen] = useState("NewMessage");
    const [token, setToken] = useRecoilState(tokenState);
    const handleScreenChange = (screen) => {
        setMessageScreen(screen);
    };
    return (
        <LoggedInComponent>
            <div className={styles.BroadcastMsgContainer}>
                <Link to="/home" className="backBtn">
                    <img src="/assets/images/back/keyboard_arrow_left_24px.svg" />
                    <span>Back</span>
                </Link>
                <h1 className={styles.heading}>Broadcast Message</h1>
                <div className={styles.mainContainer}>
                    <div className={styles.left}>
                        <h6
                            className={
                                MessageScreen == "NewMessage"
                                    ? `${styles.active}`
                                    : null
                            }
                            onClick={() => {
                                handleScreenChange("NewMessage");
                            }}>
                            <img src="/assets/images/BroadcastMessage/email_black_24dp 1.svg" />
                            New Message
                        </h6>
                        <h6
                            className={
                                MessageScreen == "DraftMessage"
                                    ? `${styles.active}`
                                    : null
                            }
                            onClick={() => {
                                handleScreenChange("DraftMessage");
                            }}>
                            <img src="/assets/images/BroadcastMessage/Vector.svg" />
                            Draft Message
                        </h6>
                        <h6
                            className={
                                MessageScreen == "SentMessage"
                                    ? `${styles.active}`
                                    : null
                            }
                            onClick={() => {
                                handleScreenChange("SentMessage");
                            }}>
                            <img src="/assets/images/BroadcastMessage/send_black_24dp 1.png" />
                            Sent Message
                        </h6>
                    </div>
                    <div className={styles.right}>
                        {MessageScreen === "NewMessage" ? (
                            <NewMessage />
                        ) : MessageScreen === "DraftMessage" ? (
                            <DraftMessage />
                        ) : (
                            <SentMessage />
                        )}

                        {/* <DraftMessage />
          <SentMessage /> */}
                    </div>
                </div>
            </div>
        </LoggedInComponent>
    );
}
