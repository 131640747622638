import React from "react";

const DateTimeFormatter = ({ dateTimeString, requiredField }) => {
    if (dateTimeString === null || dateTimeString === undefined)
        return <span>Not Available</span>;
    const formattedDate = formatDate(dateTimeString, requiredField);

    return <span>{formattedDate}</span>;
};

export const formatDate = (dateTimeString, requiredField) => {
    const date = new Date(dateTimeString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? " pm" : " am";

    // Convert from 24-hour to 12-hour format
    hours %= 12;
    hours = hours || 12;

    const formattedTime = `${hours}:${minutes
        .toString()
        .padStart(2, "0")}${amPm}`;

    if (requiredField === "date") {
        return `${day}/${month}/${year}`;
    } else {
        return `${day}/${month}/${year} ${formattedTime}`;
    }
};

export default DateTimeFormatter;
