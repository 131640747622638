import React, { useEffect, useState } from "react";
import { Spin } from "antd";

const PdfViewer = ({ pdfUrl, onLoad }) => {
    const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
        pdfUrl
    )}&embedded=true`;
    const [isLoading, setIsLoading] = useState(true); // State to track loading state

    // Function to handle iframe's load event
    const handleLoad = () => {
        setIsLoading(false); // Set loading state to false when iframe is loaded
    };

    useEffect(() => {
        setIsLoading(true);
    }, [pdfUrl]);

    return (
        <>
            {pdfUrl === null ? (
                "No Data to Preview"
            ) : (
                <>
                    {isLoading && (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 10, // Ensuring it's above the iframe visually
                            }}>
                            <Spin />
                        </div>
                    )}
                    <iframe
                        id="pdfViewer"
                        src={googleDocsViewerUrl}
                        width="100%"
                        height="750px"
                        title="PDF Preview"
                        style={
                            isLoading
                                ? { filter: "blur(2px)", border: "none" }
                                : { border: "none" }
                        }
                        onLoad={handleLoad}></iframe>
                </>
            )}
        </>
    );
};

export default PdfViewer;
