/* Main Home component with table, Outlet is nested compoent in react router v6 */
import { Outlet } from "react-router";
import { useState } from "react";
import styles from "./Home.module.css";
import React from "react";
import { useRecoilState } from "recoil";
import { Space, Spin } from "antd";
import { tokenState } from "../../RecoilState/tokenState";
import LoggedInComponent from "../../containers/LoggedInComponent/LoggedInComponent";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import { Layout } from "antd";

export default function Home(props) {
    const [loading, setLoading] = useState(false);

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    if (loading) {
        return (
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <Space>
                    <Spin size="large" />
                </Space>
            </div>
        );
    } else {
        return (
            <Layout>
                <ErrorBoundary>
                    <Layout>
                        <LoggedInComponent>
                            <Outlet />
                        </LoggedInComponent>
                    </Layout>
                </ErrorBoundary>
            </Layout>
        );
    }
}
