import React, { useState } from "react";
import { Select } from "antd";
import SelectVersionFilter from "./SelectVersionFilter";
import VersionCaseReport from "./VersionCaseReport";

const { Option } = Select;

const PreviousTab = ({ selectedVersion, setSelectedVersion, version }) => {
    return (
        <div>
            {selectedVersion !== null ? (
                <VersionCaseReport selectedVersion={selectedVersion} />
            ) : (
                <SelectVersionFilter
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    version={version}
                />
            )}
        </div>
    );
};

export default PreviousTab;
