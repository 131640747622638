import React, { useEffect, useRef, useState } from "react";
import { Image, Spin, message, Typography, Button, Modal } from "antd";
import { common_axios } from "../../utils/AxiosSettings";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Cookies } from "react-cookie";
import PdfViewer from "../Utils/PDFViewer";

const { Text } = Typography;

const DocumentViewer = ({
    documentIds,
    model,
    model_id,
    refresh,
    document_delete_on,
    document_download_on,
    previewHeight,
    previewWidth,
    previewType,
    scrollable,
}) => {
    const [urls, setUrls] = useState([]);
    const [loading, setLoading] = useState(true);
    const cookies = new Cookies();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentPdfUrl, setCurrentPdfUrl] = useState("");

    useEffect(() => {
        const fetchPresignedUrls = async () => {
            try {
                let url = "";
                if (Array.isArray(documentIds)) {
                    url = documentIds.join(",");
                } else {
                    url = documentIds;
                }

                common_axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${cookies.get("role-token-cookie")?.access}`;

                const response = await common_axios.get(
                    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/upload/?file_ids=${url}`
                );

                const data = response.data;
                setUrls(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching pre-signed URLs:", error);
            }
        };
        if (documentIds !== undefined) {
            setLoading(true);
            fetchPresignedUrls();
        }
    }, [documentIds]);

    const showPdfModal = (pdfUrl) => {
        setCurrentPdfUrl(pdfUrl);
        setIsModalVisible(true);
    };

    const DeleteFunction = async (model, model_id, file_id) => {
        try {
            const formData = {
                model: model,
                model_id: model_id,
                file_id: file_id,
            };

            const response = await common_axios.delete(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/upload/`,
                {
                    data: formData,
                }
            );

            const data = response.data.message;
            message.info(data);
            refresh();
        } catch (error) {
            // Handle error
            console.log(error);
        }
    };
    const scrollContainerStyle = {
        display: "flex",
        overflowX: "auto",
        gap: "0.5rem",
    };

    const nonScrollableStyle = {
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
    };

    const renderPreview = (fileType, url) => {
        const defaultSize = "200px";
        const commonContainerStyles = {
            width: previewWidth || defaultSize,
            height: previewHeight || defaultSize,
            borderRadius: previewType === "circular" ? "50%" : "2%", // Circular if previewType is provided, else 5%
            overflow: "hidden", // Clips the inner content
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f2f2f2", // Default greyish color
            cursor: "pointer", // Indicates it's clickable
        };

        const hoverStyles = {
            backgroundColor: "#7A7A7A", // Black background on hover
            color: "#ffffff", // White text on hover
        };
        const commonMediaStyles = {
            width: "100%",
            height: "100%",
            objectFit: "cover", // This ensures the content scales correctly
        };

        if (
            fileType?.includes("jpeg") ||
            fileType?.includes("png") ||
            fileType?.includes("jpg")
        ) {
            return (
                <div style={commonContainerStyles}>
                    <Image src={url} alt="Preview" style={commonMediaStyles} />
                </div>
            );
        } else if (
            fileType?.includes("mp3") ||
            fileType?.includes("mpa") ||
            fileType?.includes("wav") ||
            fileType?.includes("wma")
        ) {
            return (
                <div>
                    <audio
                        onContextMenu={(e) => e.preventDefault()}
                        src={url}
                        controls
                        controlsList="nodownload"
                    />
                </div>
            );
        } else if (
            fileType?.includes("mov") ||
            fileType?.includes("mp4") ||
            fileType?.includes("mpeg") ||
            fileType?.includes("mpg") ||
            fileType?.includes("avi")
        ) {
            return (
                <div>
                    <video src={url} controls style={{ maxWidth: "200px" }}>
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }
        if (fileType?.includes("svg")) {
            return (
                <div style={commonContainerStyles}>
                    <Image
                        src={url}
                        alt="SVG Preview"
                        style={commonMediaStyles}
                    />
                </div>
            );
        }
        if (fileType?.includes("pdf") && previewType === "thumbnail") {
            return (
                <div style={commonContainerStyles}>
                    <button
                        className="secondaryButton"
                        onClick={() => showPdfModal(url)}>
                        View PDF
                    </button>
                </div>
            );
        } else {
            return (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    style={commonContainerStyles}
                    onMouseOver={(e) => {
                        Object.assign(e.currentTarget.style, hoverStyles);
                    }}
                    onMouseOut={(e) => {
                        Object.assign(
                            e.currentTarget.style,
                            commonContainerStyles
                        );
                    }}>
                    <Text style={{ textAlign: "center" }}>
                        Preview not available
                    </Text>
                </a>
            );
        }
    };

    return (
        <div>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <Modal
                        title="PDF Viewer"
                        visible={isModalVisible}
                        onOk={() => setIsModalVisible(false)}
                        onCancel={() => setIsModalVisible(false)}
                        width="80%"
                        style={{ top: 20 }}
                        footer={null}>
                        <PdfViewer pdfUrl={currentPdfUrl} />
                    </Modal>
                    <div
                        style={
                            scrollable
                                ? scrollContainerStyle
                                : nonScrollableStyle
                        }>
                        {Object.entries(urls).map(
                            ([documentId, documentData]) => (
                                <div key={documentId}>
                                    {/* <Text
                                style={{
                                    fontSize: "0.8rem",
                                }}>{`File - ${documentId}`}</Text> */}
                                    <div
                                        style={{
                                            marginTop: "0.3rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "0.3rem",
                                        }}>
                                        {renderPreview(
                                            documentData.file_type,
                                            documentData.presigned_url
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "0.3rem",
                                            }}>
                                            {document_download_on && (
                                                <Button
                                                    type="link"
                                                    href={
                                                        documentData.presigned_url
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download>
                                                    <DownloadOutlined />
                                                </Button>
                                            )}
                                            {document_delete_on && (
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        DeleteFunction(
                                                            model,
                                                            model_id,
                                                            documentId
                                                        );
                                                    }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    download>
                                                    <DeleteOutlined />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default DocumentViewer;
