import React, { useState, useEffect } from "react";
import HelpTab from "./HelpTab";
import { Col, Layout, Row } from "antd";
import CollapsableMenu from "../../components/CollapsableMenu";
import { getPolicies } from "../../Adapters/PolicyDashboard";

const HelpDashboard = () => {
    const [data, setData] = useState(null);
    const [links, setLinks] = useState([]);
    useEffect(() => {
        const callgetPolicies = async () => {
            try {
                const response = await getPolicies("help");
                setData(response.data.message_body.Policies);
                const attachedLinks =
                    response.data.message_body.Policies.reduce(
                        (acc, policy) => {
                            acc[policy.policy_name] = policy.attached_link;
                            return acc;
                        },
                        {}
                    );
                setLinks(attachedLinks);
            } catch (error) {
                console.error("Failed to fetch policies:", error);
            }
        };
        callgetPolicies();
    }, []);
    return (
        <Layout>
            <CollapsableMenu></CollapsableMenu>
            <div style={{ width: "98%" }}>
                <Row
                    justify="space-between"
                    style={{
                        margin: "1rem 0 0 0",
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <Col flex="60%">
                        <h1
                            id="help"
                            style={{
                                textAlign: "start",
                                padding: "1rem 0 0 2rem",
                            }}>
                            Help
                        </h1>
                    </Col>
                </Row>
                <HelpTab linksData={links} />
            </div>
        </Layout>
    );
};

export default HelpDashboard;
