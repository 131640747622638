import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Divider, Form, Row, message } from "antd";
import QCForm from "./QCForm.jsx";
import ConfirmationModal from "../../../components/QualityChecker/Modals/ConfirmationModal.jsx";
import styles from "../CaseReport.module.css";
import { getDraftQCDetails } from "../../../Adapters/QCcalls.js";
import { userState } from "../../../RecoilState/userState.js";
import { caseReportDataState } from "../../../RecoilState/caseReportDataState.js";
import { useRecoilState } from "recoil";
import { QCCaseReportDataState } from "../../../RecoilState/QCCaseReportDataState.js";
export default function FeedbackReport({
    status,
    setStatus,
    openCompleteAction,
    setOpenCompleteAction,
    setOpenConfirmAction,
}) {
    const [user] = useRecoilState(userState);
    const [caseData] = useRecoilState(QCCaseReportDataState);
    const [caseReportData] = useRecoilState(caseReportDataState);

    const [readMode, setReadMode] = useState();
    useEffect(() => {
        let read =
            user.role == "QC" &&
            caseReportData?.CaseStatus != "Assigned to Quality Checker";
        !read &&
            status == "Pending" &&
            getDraftQCDetails(user?.id, caseData?.case?.id)
                .then((res) => {
                    if (res.status != "Pending") {
                        setStatus(res.status);
                    }
                })
                .catch((err) => {
                    if (err.response?.data?.errorMessage) {
                        message.error(err.response.data.errorMessage);
                    }
                });
        setReadMode(read);
    }, []);
    return (
        <>
            <div>
                <Row gutter={[16, 32]} align="top" style={{ fontSize: "15px" }}>
                    <Col span={3}>Audit Action Taken: </Col>
                    <Col span={3}>
                        <Row>
                            <b>{status}</b>{" "}
                        </Row>
                        <Row>
                            {!readMode && (
                                <Link
                                    onClick={() => setOpenConfirmAction(true)}
                                    style={{
                                        textDecoration: "underline",
                                        color: "#868CFF",
                                    }}>
                                    Change Response
                                </Link>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Divider className={styles.caseRepDivider} />{" "}
                {readMode != null && (
                    <QCForm
                        readOnly={readMode}
                        status={status}
                        openCompleteAction={openCompleteAction}
                        setOpenCompleteAction={setOpenCompleteAction}
                        QCcaseData={caseData?.qcReport}
                        generalReport={false}
                    />
                )}
            </div>
        </>
    );
}
