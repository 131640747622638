import React, { useState } from "react";
import styles from "../../pages/CaseReport/CaseReport.module.css";
import MultiTranslationDynamicVariable from "../Utils/MultiTranslationDynamicVariable";
const VariableSendMessage = ({
    variableData,
    setTabState,
    setActiveTab,
    getPreviewScreensDataHandler,
}) => {
    const [translatedDataObj, setTranslatedDataObj] = useState({});
    const [count, setCount] = useState(0);
    const [inputChangeFlag, setInputChangeFlag] = useState(true);
    const [isTemplateValue, setIsTemplateValue] = useState(false);
    const [countTraslatedMsg, setCountTranslatedMsg] = useState(0);
    return (
        <div>
            <div className={styles.mainWrapper}>
                {variableData[0] === null
                    ? null
                    : Object.keys(variableData).length === 0
                    ? "There are no variable entries for this template"
                    : variableData?.map((item, i) => {
                          return (
                              <>
                                  <MultiTranslationDynamicVariable
                                      key={i}
                                      item={item.var}
                                      language={item.language}
                                      userInputVar={variableData}
                                      setTranslatedDataObj={
                                          setTranslatedDataObj
                                      }
                                      translatedDataObj={translatedDataObj}
                                      prefilledInputValue={item.InputValue}
                                      isTemplateValue={isTemplateValue}
                                      setCountTranslatedMsg={
                                          setCountTranslatedMsg
                                      }
                                      setCount={setCount}
                                      count={count}
                                      setInputChangeFlag={setInputChangeFlag}
                                      inputChangeFlag={inputChangeFlag}
                                  />
                              </>
                          );
                      })}
            </div>
            <div className={styles.footerWrapper}>
                <button
                    className={styles.secondaryButton}
                    onClick={() => {
                        setActiveTab("1");
                    }}>
                    Back
                </button>
                <button
                    className={styles.solidButton}
                    onClick={() => {
                        getPreviewScreensDataHandler(translatedDataObj);
                    }}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default VariableSendMessage;
