import React, { useEffect, useState } from "react";
import { Modal, Form, Radio, Button } from "antd";
import SplitCaseOfUser from "./SplitCaseOfUser";
import MergeCaseOfUser from "./MergeCaseOfUser";
import AssignUser from "./AssignUser";
import { useFetchUserData } from "../../../UserHooks/useFechUserDataAdmin";
import { usersList } from "../../../../../../../RecoilState/usersList";
import { useRecoilState } from "recoil";

function groupUsersByRoles(data) {
    let groupedData = {};

    data.forEach((item) => {
        if (!groupedData[item.userId]) {
            groupedData[item.userId] = {
                username: item.username,
                Email: item.Email,
                roles: [],
            };
        }
        groupedData[item.userId].roles.push({
            id: item.roleId,
            factory_fk: item.FactoryId,
            role: item.Role,
        });
    });

    return Object.values(groupedData);
}

const TransferRoleModal = ({
    user,
    role,
    visible,
    onCancel,
    onTransfer,
    fetcher,
}) => {
    const [state, setState] = useState({
        selectedOption: null,
        currentScreen: "selection",
        nextScreen: null,
        selectedUser: null,
    });
    const [modalFooter, setModalFooter] = useState(null);
    const [users, setUsers] = useRecoilState(usersList);
    const [RoleClubbedUsers, setRoleClubbedUsers] = useState([]);
    const { fetchUserData } = useFetchUserData();
    const handleOptionChange = (e) => {
        const option = e.target.value;
        const newModalFooter =
            state.currentScreen === "selection" ? defaultFooter() : modalFooter;
        setState((prevState) => ({
            ...prevState,
            selectedOption: option,
            nextScreen: option,
        }));
        setModalFooter(newModalFooter);
    };
    const handleConfirm = () => {
        if (state.currentScreen === "selection") {
            setState((prevState) => ({
                ...prevState,
                currentScreen: prevState.nextScreen,
            }));
        } else {
            onTransfer(state.selectedOption, state.selectedUser);
        }
    };
    const navigateToSelection = () => {
        setState((prevState) => ({
            ...prevState,
            currentScreen: "selection",
            selectedOption: null,
        }));
        setModalFooter(defaultFooter());
    };
    useEffect(() => {
        const fetchDataAndUpdate = async () => {
            let mergedData;
            if (
                !users ||
                users.length === 0 ||
                users === "" ||
                users === undefined
            ) {
                const fetchedData = await fetchUserData();
                mergedData = groupUsersByRoles(fetchedData); //move this to fetchUserData hook rather than here
            } else {
                mergedData = groupUsersByRoles(users);
            }

            const matchingUsers = mergedData.filter((user) => {
                return user.roles.some(
                    (userRole) =>
                        userRole.role === role.role &&
                        userRole.factory_fk === role.factory_fk
                );
            });
            const filteredUsers = matchingUsers
                ? matchingUsers.filter((u) => u.Email !== user.email)
                : [];
            setRoleClubbedUsers(filteredUsers);
        };

        fetchDataAndUpdate(); // Call the async function
    }, []);

    const defaultFooter = () => {
        return [
            <Button
                key="cancel"
                onClick={() => {
                    defaultFooter();
                    onCancel();
                }}>
                Cancel
            </Button>,
            <Button key="next" type="primary" onClick={handleConfirm}>
                Next
            </Button>,
        ];
    };
    const renderContent = () => {
        switch (state.currentScreen) {
            case "selection":
                return (
                    <Form.Item label="Transfer Option">
                        <Radio.Group
                            onChange={handleOptionChange}
                            value={state.selectedOption}>
                            <Radio
                                disabled={
                                    role.role ===
                                        ("REGIONAL_ADMIN" || "FACTORY_ADMIN") ||
                                    RoleClubbedUsers.length < 2
                                }
                                value="split">
                                Split Among All Equally
                            </Radio>
                            <Radio
                                disabled={
                                    role.role ===
                                        ("REGIONAL_ADMIN" || "FACTORY_ADMIN") ||
                                    RoleClubbedUsers.length == 0
                                }
                                value="mergeConfirm">
                                Transfer (same role, same factory)
                            </Radio>
                            <Radio value="assign">Assign Another User</Radio>
                        </Radio.Group>
                    </Form.Item>
                );
            case "split":
                return (
                    <SplitCaseOfUser
                        fromRole={role?.id}
                        roleName={role?.role}
                        user={user}
                        fetcher={fetcher}
                        setModalFooter={setModalFooter}
                        navigateToSelection={navigateToSelection}
                        onCancel={onCancel}
                    />
                );
            case "mergeConfirm":
                return (
                    <MergeCaseOfUser
                        user={user}
                        role={role}
                        onMergeConfirmed={() => {
                            setState((prevState) => ({
                                ...prevState,
                                currentScreen: "selection",
                                selectedUser: null,
                            }));
                        }}
                        onCancel={() => {
                            setState((prevState) => ({
                                ...prevState,
                                selectedUser: null,
                            }));
                        }}
                        disabled={state.selectedOption !== "mergeConfirm"}
                        setModalFooter={setModalFooter}
                        navigateToSelection={navigateToSelection}
                        RoleClubbedUsers={RoleClubbedUsers}
                    />
                );
            case "assign":
                return (
                    <AssignUser
                        role={role}
                        user={user}
                        fromRole={role?.id}
                        fetcher={fetcher}
                        setModalFooter={setModalFooter}
                        navigateToSelection={navigateToSelection}
                        onCancel={onCancel}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <Modal
            title="Replace Access"
            visible={visible}
            onCancel={() => {
                onCancel();
                navigateToSelection();
            }}
            footer={modalFooter}>
            {renderContent()}
        </Modal>
    );
};
export default TransferRoleModal;
