import React, { useState } from "react";
import { Button, Modal, Input, message, Alert } from "antd";
import styles from "../../AdminDashboard.module.css";
import { regionState } from "../../../../RecoilState/regionState";
import { useRecoilState } from "recoil";
import { deleteRegion } from "../../../../Adapters/RegionCalls";
import { useNavigate } from "react-router";

const DeleteRegionButton = ({ region, icon, label, disable }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [confirmInput, setConfirmInput] = useState("");
    const [regions, setRegions] = useRecoilState(regionState);
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        handleConfirm();
        setIsModalVisible(false);
        setConfirmInput("");
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setConfirmInput("");
    };

    function handleConfirm() {
        if (confirmInput === "Delete") {
            deleteRegion(region)
                .then((response) => {
                    if (response?.status === 200) {
                        message.success(response?.data?.message);
                        setIsModalVisible(false);
                        setRegions((prevRegions) =>
                            prevRegions.filter((r) => r.id !== region.id)
                        );
                        navigate("/AdminTableView?key=1");
                    } else {
                        message.warning(
                            "There was a problem deleting the Region."
                        );
                    }
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        message.error(error?.response?.data?.errorMessage);
                    } else {
                        message.error(error?.response?.data?.errorMessage);
                    }
                });
        } else {
            message.error("Incorrect Input");
        }
    }

    function handleAssignOK() {
        setIsModalVisible(false);
    }

    const handleAfterClose = () => {
        setConfirmInput(""); // Reset the input when the modal is closed
    };

    return (
        <>
            {icon ? (
                <Button icon={icon} type="text" onClick={showModal}></Button>
            ) : (
                <Button
                    type="secondary"
                    className="secondaryButton"
                    onClick={() =>
                        disable
                            ? message.error({
                                  key: "DeleteError",
                                  content: (
                                      <Alert
                                          message="Can't Delete Region with Factories"
                                          description="Please make sure to Move all the Factories to Another Existing or New Region to Delete this."
                                          type="error"
                                          showIcon
                                          closable
                                      />
                                  ),
                                  icon: <></>,
                                  onClick: () => message.destroy("DeleteError"),
                              })
                            : showModal()
                    }
                    style={{
                        width: "100%",
                        height: 40,
                    }}>
                    {label}
                </Button>
            )}
            <Modal
                title="Delete Region"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                afterClose={handleAfterClose}
                footer={[
                    <Button
                        key="cancel"
                        onClick={handleCancel}
                        className={styles.outlineButton}>
                        Cancel
                    </Button>,
                    <Button
                        key="ok"
                        onClick={handleOk}
                        className={styles.solidButton}>
                        Yes
                    </Button>,
                ]}
                width={300}
                closeIcon>
                <div>
                    <h3>
                        ALL THE DATA OF {region?.Name} WILL BE DELETED
                        PERMANENTLY.
                    </h3>
                    <p>Type Delete</p>
                    <Input
                        label="Type Delete"
                        placeholder="Type here"
                        value={confirmInput}
                        onChange={(e) =>
                            setConfirmInput(e.target.value)
                        }></Input>
                </div>
            </Modal>
        </>
    );
};

export default DeleteRegionButton;
