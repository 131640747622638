import { Table, Tooltip, Space } from "antd";
import React from "react";
import { EditOutlined, SwapOutlined, DeleteOutlined } from "@ant-design/icons";

const UserRoleTable = ({
    roles,
    onEdit,
    onTransfer,
    onDelete,
    showActionCol,
}) => {
    let rolesArray;
    if (typeof roles === "object" && roles !== null) {
        rolesArray = Object.keys(roles).map((key) => {
            return {
                keyName: key,
                ...roles[key],
            };
        });
    } else {
        return <p>Error: Invalid roles data format!</p>;
    }
    const columns = [
        {
            title: "S.no",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
        },
        {
            title: "Factory",
            dataIndex: "Code",
            key: "Code",
        },
        {
            title: "Broadcast Message",
            key: "broadcastMessage",
            render: (text, record) =>
                (record.user_permissions &&
                    record.user_permissions.includes("add_broadcastmessage")) ||
                (record.group_permissions &&
                    record.group_permissions.includes("add_broadcastmessage"))
                    ? "Yes"
                    : "No",
        },
        {
            title: "Awareness Program",
            key: "awarenessProgram",
            render: (text, record) =>
                (record.user_permissions &&
                    record.user_permissions.includes("add_awarenessprogram")) ||
                (record.group_permissions &&
                    record.group_permissions.includes("add_awarenessprogram"))
                    ? "Yes"
                    : "No",
        },
        {
            title: "Upload Holiday Calendar",
            key: "holidayCalendar",
            render: (text, record) =>
                (record.user_permissions &&
                    record.user_permissions.includes("add_holidaycalendar")) ||
                (record.group_permissions &&
                    record.group_permissions.includes("add_holidaycalendar"))
                    ? "Yes"
                    : "No",
        },
    ];
    if (showActionCol) {
        columns.push({
            title: "Action",
            key: "actions",
            render: (text, record) => (
                <Space>
                    <Tooltip title="Edit">
                        <span onClick={() => onEdit(record)}>
                            <EditOutlined style={{ fontSize: "18px" }} />
                        </span>
                    </Tooltip>
                    <Tooltip title="Transfer">
                        <span onClick={() => onTransfer(record)}>
                            <SwapOutlined style={{ fontSize: "18px" }} />
                        </span>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <span onClick={() => onDelete(record)}>
                            <DeleteOutlined style={{ fontSize: "18px" }} />
                        </span>
                    </Tooltip>
                </Space>
            ),
        });
    }

    return <Table dataSource={rolesArray} columns={columns} />;
};

export default UserRoleTable;
