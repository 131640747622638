import { Table, Tooltip, Space, Input, Button, Checkbox } from "antd";
import React, { useState } from "react";
import _, { set } from "lodash";
import "./RegionTable.css";
import { userState } from "../../../../RecoilState/userState";
import { useRecoilState } from "recoil";

const RegionFactoryTable = ({
    factories,
    id,
    regionmap,
    defaultFactory,
    selectedFactory,
    setSelectedFactory,
}) => {
    const [filterTable, setFilterTable] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [user] = useRecoilState(userState);

    let factoryArray;
    if (typeof factories === "object" && factories !== null) {
        factoryArray = Object.keys(factories)
            .map((key) => {
                return {
                    ...factories[key],
                };
            })
            .sort((a, b) => {
                return Math.abs(a.region - id) < Math.abs(b.region - id)
                    ? -1
                    : 1;
            });

        if (!user?.group_permissions?.includes("change_factoryregion")) {
            factoryArray = factoryArray.filter((factory) =>
                defaultFactory?.includes(factory.id)
            );
        }
    }

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    function filterFactories(factory) {
        return !defaultFactory.includes(factory);
    }

    const onSelectChange = (newSelectedRowKeys) => {
        newSelectedRowKeys = [...new Set(newSelectedRowKeys)];
        setSelectedKeys(newSelectedRowKeys);
        setSelectedFactory(newSelectedRowKeys.filter(filterFactories));
    };

    const rowSelection = {
        selectedRowKeys: selectedFactory.concat(defaultFactory),
        onChange: onSelectChange,
        hideSelectAll: true,
        columnWidth: 50,
        getCheckboxProps: (record) => ({
            disabled: defaultFactory?.includes(record.id),
        }),
    };

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item, index) => ({
            text: formatter(item),
            value: formatter(item),
            key: `${formatter(item)}_${index}`,
        }));

    const onChange = (pagination, filters, sorter) => {
        const filterTable = [...factoryArray];
        if (sorter.order === "ascend") {
            filterTable.sort((a, b) => {
                return a[sorter.field] > b[sorter.field] ? 1 : -1;
            });
        } else if (sorter.order === "descend") {
            filterTable.sort((a, b) => {
                return a[sorter.field] < b[sorter.field] ? 1 : -1;
            });
        }
        if (_.isEqual(filterTable, factoryArray)) setFilterTable([]);
        else setFilterTable(filterTable);
    };
    const columns = [
        {
            title: "Factory Number",
            dataIndex: "Code",
            key: "Code",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Code - b.Code,
            filters: filterData(factoryArray, (item) => item.Name),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Code = record.Code.toLowerCase().trim();
                return Code.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Location",
            dataIndex: "Location",
            key: "Location",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Location - b.Location,
            filters: filterData(factoryArray, (item) => item.Location),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Code = record.Location.toLowerCase().trim();
                return Code.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Region",
            dataIndex: "region",
            key: "region",
            filterMode: "tree",
            filterSearch: true,
            render: (region) => {
                return region;
            },
            sorter: (a, b) => regionmap.get(a.region) - regionmap.get(b.region),
            filters: filterData(factoryArray, (item) => item.Name),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Code = regionmap.get(record.region).toLowerCase().trim();
                return Code.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
    ];

    return (
        <div style={{ overflow: "auto" }}>
            <Table
                rowSelection={rowSelection}
                dataSource={filterTable.length > 0 ? filterTable : factoryArray}
                columns={columns}
                pagination={false}
                onChange={onChange}
                scroll={{ y: `450px`, scrollToFirstRowOnChange: true }}
            />
        </div>
    );
};

export default RegionFactoryTable;
