import React from "react";

import styles from "../IncentiveDashboard/CaseCountList/CaseCountList.module.css";

export default function TotalFactoriesBox({ caseData }) {
    const caseItems = [
        { caseCount: caseData?.Eligible, text: "Eligible Factories" },
        { caseCount: caseData?.Ineligible, text: "Ineligible Factories" },
    ];

    return (
        <>
            {caseItems.map((item, index) => {
                return (
                    <div className={styles.item}>
                        <h3>{item.caseCount}</h3>
                        <p>{item.text}</p>
                    </div>
                );
            })}
        </>
    );
}
