import React from "react";
import { getQCReportTabStatus } from "../../../hooks/getQCReportTabStatus";
import { Button } from "antd";
import { useNavigate } from "react-router";
import TimerButton from "../Buttons/TimerButton";
import styles from "../Buttons/TimerButton.module.css";
import { useRecoilState } from "recoil";
import { caseReportDataState } from "../../../RecoilState/caseReportDataState";
import { getCounterFromCaseStatus } from "../../../hooks/getCounterFromCaseStatus";

const RenderQCFooter = ({
    tab,
    audioHeard,
    currentTab,
    visitedTabs,
    handleTabChange,
    updateStatus,
    setShowAudioError,
    setOpenCompleteAction,
    setOpenConfirmAction,
}) => {
    const navigate = useNavigate();
    const [caseDataState] = useRecoilState(caseReportDataState);

    const checkAudio = async () => {
        if (!audioHeard && caseDataState?.ReportingMedium == "Call") {
            setShowAudioError(true);
        } else {
            const tabPresent = getQCReportTabStatus(
                tab,
                parseInt(currentTab) + 1
            );
            await updateStatus(
                {
                    audio_flag: audioHeard,
                    progress: tabPresent,
                },
                parseInt(currentTab) + 1
            );
        }
    };
    const navigatePrevTab = () => {
        if (currentTab == "1") {
            navigate(-1);
        } else {
            const tab = parseInt(currentTab) - 1;
            handleTabChange(tab.toString());
        }
    };

    const navigateNextTab = () => {
        const tabCurrent = getQCReportTabStatus(tab, parseInt(currentTab));
        if (tabCurrent == "Qc Report") {
            navigate(-1);
        } else {
            const tabPresent = getQCReportTabStatus(
                tab,
                parseInt(currentTab) + 1
            );
            updateStatus(
                {
                    audio_flag: audioHeard,
                    progress: tabPresent,
                },
                parseInt(currentTab) + 1
            );
        }
    };
    const renderFooterButtons = () => {
        let buttonKey;
        const tabPresent = getQCReportTabStatus(tab, parseInt(currentTab));
        switch (tabPresent) {
            case "Qc Prev Report":
                buttonKey = getQCReportTabStatus(tab, "Qc Prev Report");
                return (
                    <>
                        <Button
                            className={`secondaryButton ${styles.button}`}
                            onClick={navigatePrevTab}>
                            Cancel
                        </Button>
                        {visitedTabs > buttonKey ? (
                            <Button
                                className={`primaryButton ${styles.button}`}
                                onClick={navigateNextTab}>
                                Next
                            </Button>
                        ) : (
                            <TimerButton
                                onClick={navigateNextTab}
                                key={buttonKey}
                                text="Next"
                            />
                        )}
                    </>
                );
            case "General":
                buttonKey = getQCReportTabStatus(tab, "General");
                return (
                    <>
                        <Button
                            className={`secondaryButton ${styles.button}`}
                            onClick={navigatePrevTab}>
                            {buttonKey == 1 ? "Cancel" : "Previous"}
                        </Button>
                        {visitedTabs > buttonKey ? (
                            <Button
                                className={`primaryButton ${styles.button}`}
                                onClick={() => checkAudio()}>
                                Next
                            </Button>
                        ) : (
                            <TimerButton
                                onClick={() => checkAudio()}
                                key={buttonKey}
                                text="Next"
                            />
                        )}
                    </>
                );

            case "Details":
                buttonKey = getQCReportTabStatus(tab, "Details");
                return (
                    <>
                        <Button
                            onClick={navigatePrevTab}
                            className={`secondaryButton ${styles.button}`}>
                            Previous
                        </Button>
                        {visitedTabs > buttonKey ? (
                            <Button
                                onClick={navigateNextTab}
                                className={`primaryButton ${styles.button}`}>
                                Next
                            </Button>
                        ) : (
                            <TimerButton
                                onClick={navigateNextTab}
                                key={buttonKey}
                                text="Next"
                            />
                        )}
                    </>
                );
            case "Closing Report":
                buttonKey = getQCReportTabStatus(tab, "Closing Report");
                return (
                    <>
                        <Button
                            onClick={navigatePrevTab}
                            className={`secondaryButton ${styles.button}`}>
                            Previous
                        </Button>
                        {visitedTabs > buttonKey ? (
                            <Button
                                onClick={navigateNextTab}
                                className={`primaryButton ${styles.button}`}>
                                Next
                            </Button>
                        ) : (
                            <TimerButton
                                onClick={navigateNextTab}
                                key={buttonKey}
                                text="Next"
                            />
                        )}
                    </>
                );
            case "Reply":
                buttonKey = getQCReportTabStatus(tab, "Reply");
                return (
                    <>
                        <Button
                            onClick={navigatePrevTab}
                            className={`secondaryButton ${styles.button}`}>
                            Previous
                        </Button>
                        {visitedTabs > buttonKey ? (
                            <Button
                                onClick={navigateNextTab}
                                className={`primaryButton ${styles.button}`}>
                                Next
                            </Button>
                        ) : (
                            <TimerButton
                                onClick={navigateNextTab}
                                key={buttonKey}
                                text="Next"
                            />
                        )}
                    </>
                );
            case "View Logs":
                buttonKey = getQCReportTabStatus(tab, "View Logs");

                return (
                    <>
                        <Button
                            onClick={navigatePrevTab}
                            className={`secondaryButton ${styles.button}`}>
                            Previous
                        </Button>
                        {getCounterFromCaseStatus(caseDataState?.CaseStatus) &&
                        getCounterFromCaseStatus(caseDataState?.CaseStatus) ==
                            8 ? (
                            visitedTabs > buttonKey ? (
                                <Button
                                    onClick={() => setOpenConfirmAction(true)}
                                    className={`primaryButton ${styles.button}`}>
                                    Reviewed
                                </Button>
                            ) : (
                                <TimerButton
                                    onClick={() => setOpenConfirmAction(true)}
                                    key={buttonKey}
                                    text="Reviewed"
                                />
                            )
                        ) : visitedTabs > buttonKey ? (
                            <Button
                                onClick={navigateNextTab}
                                className={`primaryButton ${styles.button}`}>
                                Next
                            </Button>
                        ) : (
                            <TimerButton
                                onClick={navigateNextTab}
                                key={buttonKey}
                                text="Next"
                            />
                        )}
                    </>
                );

            default:
                return getCounterFromCaseStatus(caseDataState?.CaseStatus) ==
                    8 ? (
                    <>
                        <Button
                            onClick={() => navigate(-1)}
                            className={`secondaryButton ${styles.button}`}>
                            Cancel
                        </Button>
                        <Button
                            className={`primaryButton ${styles.button}`}
                            onClick={() => {
                                setOpenCompleteAction(true);
                            }}>
                            Submit
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={navigatePrevTab}
                            className={`secondaryButton ${styles.button}`}>
                            Previous
                        </Button>
                        <Button
                            onClick={() => navigate(-1)}
                            className={`primaryButton ${styles.button}`}>
                            Done
                        </Button>
                    </>
                );
        }
    };
    return <div>{renderFooterButtons()}</div>;
};
export default RenderQCFooter;
