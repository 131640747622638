import { Button, Modal } from "antd";
import React from "react";
import styles from "../../AdminDashboard.module.css";

const ConfirmationModal = ({
    title,
    open,
    onCancel,
    onClickConfirm,
    desription,
}) => {
    return (
        <Modal
            title={title}
            open={open}
            footer={[
                <Button key="cancel" type="secondary" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="ok"
                    onClick={onClickConfirm}
                    className={styles.solidButton}>
                    Confirm
                </Button>,
            ]}
            width={300}
            closeIcon>
            <div>
                <p>{desription}</p>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
