import { atom } from "recoil";

const currentDate = new Date();
const defaultMonthYear = currentDate
    .toLocaleDateString("en-US", { year: "numeric", month: "2-digit" })
    .split("/")
    .reverse()
    .join("-");

export const selectedMonthYearRecoil = atom({
    key: "selectedMonthYearRecoil",
    default: defaultMonthYear,
});
