import { Col, Row, Radio } from "antd";
import React from "react";
import CaseReportBlock from "../../../components/Utils/CaseReportBlock";

const ResponseOfTheWorker = ({
    selectedValue,
    setSelectedValue,
    workerResponse,
    readOnly,
}) => {
    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };

    return (
        <div>
            <Row
                gutter={[16, 64]}
                style={{ marginBottom: "20px", paddingLeft: "10px" }}>
                {!readOnly ? (
                    <>
                        <CaseReportBlock
                            label="Response of the worker"
                            displayMessage={workerResponse}
                        />
                    </>
                ) : (
                    <>
                        <Col span={3} style={{ fontWeight: "1000" }}>
                            <h3>Response of the worker</h3>
                        </Col>
                        <Col span={3}>
                            <Row style={{ marginBottom: "10px" }}>
                                <Radio
                                    value="1"
                                    checked={selectedValue === "1"}
                                    onChange={handleChange}>
                                    Satisfied
                                </Radio>
                            </Row>
                            <Row>
                                <Radio
                                    value="2"
                                    checked={selectedValue === "2"}
                                    onChange={handleChange}>
                                    Unsatisfied
                                </Radio>
                            </Row>
                        </Col>
                    </>
                )}
            </Row>
        </div>
    );
};

export default ResponseOfTheWorker;
