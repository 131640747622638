import { Form, Input, Button } from "antd";
import React, { useEffect } from "react";
import { DatePicker, Space, message } from "antd";
import { useState } from "react";
import {
    addHoliday,
    updateHoliday,
} from "../../../../Adapters/HolidayCalendar";
import dayjs from "dayjs";
import { userState } from "../../../../RecoilState/userState";
import { useRecoilState } from "recoil";
import DateTimeFormatter from "../../../../components/Utils/DateTimeFormatter";
import { useForm } from "antd/lib/form/Form";
const { RangePicker } = DatePicker;

const AddEditModal = ({
    selectedEvent,
    selectedDate,
    setModalVisible,
    dataUpdateFlag,
    setDataUpdateFlag,
    factory,
}) => {
    const [dateString, setDateString] = useState("");
    const [user, setUser] = useRecoilState(userState);
    const [modalForm] = Form.useForm();

    const onFinish = async (values) => {
        try {
            const startDate = new Date(dateString[0]);
            const endDate = new Date(dateString[1]);

            const timeDifference = Math.abs(
                endDate.getTime() - startDate.getTime()
            );
            const timeFrame = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            if (selectedEvent) {
                const res = await updateHoliday(
                    selectedEvent?.id,
                    values.eventname,
                    values.Date,
                    factory
                    // timeFrame
                );
                message.success(res.data?.message);
                setModalVisible(false);
                setDataUpdateFlag(!dataUpdateFlag);
            } else {
                const res = await addHoliday(
                    values.eventname,
                    dateString,
                    // timeFrame,
                    factory
                );
                message.success(res.data?.message);
                setModalVisible(false);
                setDataUpdateFlag(!dataUpdateFlag);
            }
        } catch (error) {
            message.error(error?.response?.data?.errorMessage);
        }
    };

    const [startDate, setStartDate] = useState(
        selectedDate ? dayjs(selectedDate).startOf("day") : null
    );

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const onChange = (dates, dateStrings) => {
        const formattedDates = dateStrings.map((dateString) => {
            const formattedDate = `${dateString} 00:00:00.000000+00:00`;
            return formattedDate;
        });
        setDateString(formattedDates);

        if (dates && dates.length > 0) {
            const selectedStartDate = dayjs(dates[0]).startOf("day");
            setStartDate(selectedStartDate);
        } else {
            setStartDate(null);
        }
    };

    return (
        <>
            {" "}
            <Form
                form={modalForm}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    eventname: selectedEvent?.eventName,
                    Date: selectedEvent
                        ? [
                              dayjs(selectedEvent.startDate),
                              dayjs(selectedEvent.endDate),
                          ]
                        : undefined,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Form.Item
                    label="Event Name"
                    name="eventname"
                    rules={[
                        {
                            required: true,
                            message: "Please input Event Name!",
                        },
                    ]}>
                    {user?.user_permissions.some(
                        (val) =>
                            val === "add_holidaycalendar" ||
                            val === "change_holidaycalendar"
                    ) ||
                    user?.group_permissions.some(
                        (val) =>
                            val === "add_holidaycalendar" ||
                            val === "change_holidaycalendar"
                    ) ? (
                        <Input maxLength={20} />
                    ) : (
                        selectedEvent?.eventName
                    )}
                </Form.Item>

                <Form.Item
                    label="Date Range"
                    name="Date"
                    rules={[
                        {
                            required: true,
                            message: "Please input Date!",
                        },
                    ]}>
                    {user?.user_permissions.some(
                        (val) =>
                            val === "add_holidaycalendar" ||
                            val === "change_holidaycalendar"
                    ) ||
                    user?.group_permissions.some(
                        (val) =>
                            val === "add_holidaycalendar" ||
                            val === "change_holidaycalendar"
                    ) ? (
                        <RangePicker
                            bordered={false}
                            onChange={onChange}
                            //setting selected datecell date as default start date
                            value={startDate ? [startDate, null] : null}
                            defaultValue={startDate ? [startDate, null] : null}
                            disabledDate={(current) =>
                                current && current < dayjs().startOf("day")
                            }
                        />
                    ) : (
                        <>
                            {
                                <DateTimeFormatter
                                    dateTimeString={selectedEvent?.startDate}
                                />
                            }
                            -{" "}
                            {
                                <DateTimeFormatter
                                    dateTimeString={selectedEvent?.endDate}
                                />
                            }
                        </>
                    )}
                </Form.Item>
                {user?.user_permissions.some(
                    (val) =>
                        val === "add_holidaycalendar" ||
                        val === "change_holidaycalendar"
                ) ||
                user?.group_permissions.some(
                    (val) =>
                        val === "add_holidaycalendar" ||
                        val === "change_holidaycalendar"
                ) ? (
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                ) : null}
            </Form>
        </>
    );
};

export default AddEditModal;
