/* Component where email id is entered to get reset link*/
import React, { useState } from "react";
import { ResetLinkSendByEmail } from "../../../Adapters/ResetPasswordCalls";
import styles from "../Login.module.css"; //for main css structure
import resetStyles from "./ResetPage.module.css"; //for some external styles
import { message } from "antd";
import { Link } from "react-router-dom";
export default function ResetLink() {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailSentSuccess, setEmailSentSuccess] = useState(false);

    function handleSubmitLink(e) {
        e.preventDefault();
        try {
            ResetLinkSendByEmail(email)
                .then((res) => {
                    setEmailSentSuccess(true);
                })
                .catch((err) => {
                    setEmailError("Invalid Email");
                });
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className={styles.loginContainer}>
            <div className={styles.leftLogin}>
                {emailSentSuccess ? (
                    <>
                        <div className={styles.loginMain}>
                            <div className={resetStyles.backBtn}>
                                <Link to="/">
                                    <img
                                        src="/assets/images/back/Group4495.svg"
                                        alt="back-logo"
                                    />
                                </Link>
                            </div>
                            <div className={styles.mainHeadingWrapper}>
                                <h2>Check your Mail </h2>
                            </div>
                            <p className={resetStyles.subHeading}>
                                Link to reset your password has been sent to{" "}
                                {email}
                            </p>
                            <a href="https://mail.google.com/" target="_blank">
                                <button className={styles.grey}>
                                    Open Email App
                                </button>
                            </a>
                        </div>
                    </>
                ) : (
                    <>
                        <form
                            className={styles.loginMain}
                            onSubmit={handleSubmitLink}>
                            <div className={resetStyles.backBtn}>
                                <Link to="/">
                                    <img
                                        src="/assets/images/back/Group4495.svg"
                                        alt="back-logo"
                                    />
                                </Link>
                            </div>
                            <div className={styles.mainHeadingWrapper}>
                                <h2>Reset Password</h2>
                            </div>
                            <p className={resetStyles.subHeading}>
                                Enter the email associated with your account and
                                we’ll send an email with instructions to reset
                                your password.
                            </p>
                            <div className={styles.loginDetails}>
                                <div>
                                    <label>
                                        Registered Email
                                        <div
                                            id={
                                                emailError
                                                    ? resetStyles.emailError
                                                    : ""
                                            }>
                                            <input
                                                required
                                                type="email"
                                                placeholder="Enter Registered Email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                            <span
                                                onClick={() => {
                                                    setEmail("");
                                                }}>
                                                <img src="/assets/images/LoginPage/cross.svg" />
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <div></div>
                            </div>
                            <button className={styles.grey} type="submit">
                                Send Link
                            </button>
                        </form>
                    </>
                )}
            </div>
            <div className={styles.rightLogin}>
                <img
                    width="100%"
                    height="500"
                    src="/assets/images/LoginPage/Frame.png"
                />
            </div>
        </div>
    );
}
