import { Spin } from "antd";
import React from "react";
import Dropdown from "../../../@setproduct-ui/core/Dropdown";
import MenuItem from "../../../@setproduct-ui/core/Menu/MenuItem";
import Menu from "../../../@setproduct-ui/core/Menu";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";

const RegionDropdown = ({ RegionData, region, setRegion, setRegionId }) => {
    const [user, setUser] = useRecoilState(userState);
    return (
        <>
            {region ? (
                <Dropdown
                    view="outlined"
                    color="primary"
                    text={`${region} Region` || ""}
                    disabled={user.role == "REGIONAL_ADMIN"}
                    content={
                        <Menu
                            type="def"
                            view="smooth"
                            color="primary"
                            style={{
                                maxHeight: "500px",
                                overflow: "auto",
                            }}>
                            {RegionData.map((region, index) => {
                                return (
                                    <MenuItem
                                        type="def"
                                        view="smooth"
                                        color="primary"
                                        text={`${region.Name} Region`}
                                        key={index}
                                        onClick={() => {
                                            setRegion(region.Name);
                                            setRegionId(region.id);
                                        }}
                                    />
                                );
                            })}
                        </Menu>
                    }
                    dense="false"
                />
            ) : (
                <Spin />
            )}
        </>
    );
};

export default RegionDropdown;
