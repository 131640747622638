/* Generate report component (Under construction for now)*/
import React from "react";
import Dropdown from "../../@setproduct-ui/core/Dropdown";

import styles from "./Generatereport.module.css";
import { useEffect } from "react";
import Menu from "../../@setproduct-ui/core/Menu";
import MenuItem from "../../@setproduct-ui/core/Menu/MenuItem";
import { DatePicker } from "antd";
import { getAllFactoriesOfCompany } from "../../Adapters/FactoryAndCompanyCalls";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";
import { FactoryData } from "../../RecoilState/FactoryData";
import { tokenState } from "../../RecoilState/tokenState";

export default function Generatereport({ modalCloser }) {
    const [Factory, setFactory] = React.useState("");
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    function onStartChange(date, dateString) {}
    function onEndChange(date, dateString) {}
    // Factory API call
    const handleFactorySelection = (key, value) => {
        setFactory(value);
    };
    return (
        <div className={styles.genrepcontainer}>
            <div className={styles.crossIcon} onClick={modalCloser}>
                X
            </div>
            <h1 className={styles.heading}>Generate Report</h1>
            <div className={styles.divider}></div>
            <div className={styles.innerContainer}>
                <div>
                    <h5>Select Factory</h5>
                    <Dropdown
                        view="outlined"
                        color="primary"
                        text={
                            Factory?.Location
                                ? Factory.Location
                                : "Select Factory"
                        }
                        position="bottom-left"
                        content={
                            <DynamicDropMenuFac
                                Lkey="Factory"
                                Ddata={FactoryList}
                                handleStateChange={handleFactorySelection}
                            />
                        }
                        dense="false"
                    />
                </div>
                <div className={styles.dateRangeBox}>
                    <div className={styles.flexRow}>
                        <div className={styles.datePick}>
                            <h5> Start Date :</h5>
                            <DatePicker onChange={onStartChange} />
                        </div>
                        <div className={styles.datePick}>
                            <h5>End Date:</h5>
                            <DatePicker onChange={onEndChange} />
                        </div>
                    </div>
                </div>
                <button onClick={() => {}} className={styles.button}>
                    Generate Report
                </button>
            </div>
        </div>
    );
}
function DynamicDropMenuFac({ Ddata, Lkey, handleStateChange }) {
    return (
        <Menu type="def" view="smooth" color="primary">
            {Ddata.map((item, index) => {
                return (
                    <MenuItem
                        type="def"
                        view="smooth"
                        color="primary"
                        key={index}
                        text={item.Location}
                        onClick={() => handleStateChange(Lkey, item)}
                    />
                );
            })}
        </Menu>
    );
}
