import React, { useState, useEffect } from "react";
import styles from "./TimerButton.module.css"; // Path to your CSS file for this component

const TimerButton = ({ onClick, text }) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        // Start a timer that runs for 5 seconds
        const interval = setInterval(() => {
            setPercentage((oldPercentage) => {
                const newPercentage = oldPercentage + 1; // 20% for each second
                if (newPercentage >= 100) {
                    clearInterval(interval); // Clear the interval when 100% is reached
                    setIsEnabled(true); // Enable the button
                }
                return newPercentage;
            });
        }, 100); // Run this interval every second

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, []);

    return (
        <button
            className={styles.timerButton}
            onClick={onClick}
            disabled={!isEnabled}
            style={{
                background: `linear-gradient(to right, #6641fd ${percentage}%, transparent ${percentage}%)`,
            }}>
            {text}
        </button>
    );
};

export default TimerButton;
