import axios from "axios";
import { common_axios } from "../utils/AxiosSettings";

export function sendMessage(templateID, caseID, message, userRole, companyID) {
    const res = common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/case/sendmsg/`,
        {
            templateID: templateID,
            Case: caseID,
            message: message,
            userRole: userRole,
            Company: companyID,
        }
    );

    return res;
}

//new api for getting the template based on company ID
export async function getTemplateData(queryUrl) {
    let url = queryUrl?.href;
    if (url) {
        const res = await common_axios.get(url);

        return res;
    }
}
export async function postTemplateData(queryUrl, data) {
    let url = queryUrl?.href;
    if (url) {
        const res = await common_axios.post(url, { departments: data });
        return res;
    }
}
export async function getSentMessagePreviewData(queryUrl) {
    let url = queryUrl?.href;
    if (url) {
        const res = await common_axios.get(queryUrl);

        return res;
    }
}
