import React from "react";
import { useState, useEffect } from "react";
import DynamicTable from "../../../Utils/DynamicTable/DynamicTable";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { Spin, Space, Tooltip } from "antd";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../../RecoilState/tokenState";
import { userState } from "../../../../RecoilState/userState";
import { getAllFactoriesOfCompany } from "../../../../Adapters/FactoryAndCompanyCalls";
import { Result, Button, Input } from "antd";
import {
    EditOutlined,
    SmileOutlined,
    DeleteOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import DateTimeFormatter from "../../../Utils/DateTimeFormatter";
import { usersList } from "../../../../RecoilState/usersList";
import { useFetchUserData } from "../UserAction/UserHooks/useFechUserDataAdmin";
import { useNavigate } from "react-router";
import DeleteRoleModal from "../UserAction/EditUser/Components/DeleteRoleModal";

const UsersTableView = ({}) => {
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [user, setUser] = useRecoilState(userState);
    const [usersListData, setUsersListData] = useRecoilState(usersList);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const { fetchUserData, isLoading } = useFetchUserData();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    // Factory API call

    const showDeleteModal = (role) => {
        setSelectedRole(role);
        setIsDeleteModalVisible(true);
    };

    const handleDeleteModalCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedRole(null);
    };

    const handleRoleDelete = () => {
        setIsDeleteModalVisible(false);
    };

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };

    useEffect(() => {
        if (
            (!usersListData ||
                usersListData.length === 0 ||
                usersListData === "" ||
                usersListData === undefined) &&
            user.company_fk
        ) {
            fetchUserData();
        }
    }, [user]);

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item) => ({
            text: formatter(item),
            value: formatter(item),
        }));

    const columns = [
        {
            title: "Email",
            dataIndex: "Email",
            key: "Email",
            filterMode: "tree",
            filterSearch: true,
            width: "25%",
            sorter: (a, b) => a.Email - b.Email,
            filters: filterData(usersListData, (item) => item.Email),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Email = record.Email.toString().toLowerCase().trim();
                return Email.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Unit Number",
            dataIndex: "Factory",
            key: "Factory",
            filterMode: "tree",
            width: "8%",
            filterSearch: true,
            sorter: (a, b) => a.Factory - b.Factory,
            filters: filterData(usersListData, (item) => item.Factory),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                return (
                    record.Factory &&
                    record.Factory.toLowerCase().trim().indexOf(searchValue) !==
                        -1
                );
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Location",
            dataIndex: "Location",
            key: "Location",
            filterMode: "menu",
            width: "13%",
            filterSearch: true,
            sorter: (a, b) => a.Location - b.Location,
            filters: filterData(usersListData, (item) => item.Location),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                return (
                    record.Location &&
                    record.Location.toLowerCase()
                        .trim()
                        .indexOf(searchValue) !== -1
                );
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Role",
            dataIndex: "Role",
            key: "Role",
            width: "8%",
            filterMode: "menu",
            sorter: (a, b) => a.Role.localeCompare(b.Role),
            filters: filterData(usersListData, (item) => item.Role),
            onFilter: (value, record) =>
                record.Role.toLowerCase() === value.toLowerCase(),
        },

        {
            title: "LastModified",
            dataIndex: "LastModified",
            key: "LastModified",
            width: "13%",
            render: (text) => <DateTimeFormatter dateTimeString={text} />,
            sorter: (a, b) => a.LastModified - b.LastModified,
        },
        {
            title: "Creator",
            dataIndex: "Creator",
            key: "Creator",
            width: "12%",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Creator - b.Creator,
            filters: filterData(usersListData, (item) => item.Creator),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const Creator = record.Creator
                    ? record.Creator.toString().toLowerCase().trim()
                    : "";
                return Creator.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Action",
            key: "action",

            render: (text, record) => (
                <Space>
                    <Tooltip
                        title={
                            user.role == "LEAD_SUPERVISOR" ? "View" : "Edit"
                        }>
                        <span
                            onClick={() =>
                                navigate(`/EditUser/${record.userId}`)
                            }>
                            {user.role == "LEAD_SUPERVISOR" ? (
                                <EyeOutlined style={{ fontSize: "18px" }} />
                            ) : (
                                <EditOutlined style={{ fontSize: "18px" }} />
                            )}
                        </span>
                    </Tooltip>
                    {user?.group_permissions?.includes(
                        "change_baseusermodel"
                    ) && (
                        <Tooltip title="Delete">
                            <span onClick={() => showDeleteModal(record)}>
                                <DeleteOutlined style={{ fontSize: "18px" }} />
                            </span>
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <div>
            {isLoading ? (
                <Spin />
            ) : tableData === undefined ? (
                <Result
                    icon={<SmileOutlined />}
                    title="No user data available"
                />
            ) : (
                <div>
                    <DynamicTable data={usersListData} columns={columns} />
                    {isDeleteModalVisible && (
                        <DeleteRoleModal
                            role={selectedRole}
                            visible={isDeleteModalVisible}
                            onCancel={handleDeleteModalCancel}
                            onDelete={handleRoleDelete}
                            fetcher={fetchUserData}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
export default UsersTableView;
