import { common_axios } from "../utils/AxiosSettings";
export async function saveBroadcastMessage(dataObject, save = true) {
    let saveorsend = save ? "save" : "send";

    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/message/${saveorsend}/`,
        dataObject
    );

    return res;
}

export async function getDraftMessages() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/message/view/saved`
    );

    return res;
}

export async function getSentMessages() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/message/view/sent`
    );

    return res;
}

export async function getPreviewMessageData(queryUrl) {
    let url = queryUrl?.href;
    if (url) {
        try {
            const res = await common_axios.get(url);
            return res;
        } catch (status) {
            //refactor later
            alert("Fill all the values");
            // return "error";
        }
    }
}
export async function saveDraftBroadcastMessage() {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/savedraft/`
    );

    return res;
}
export async function saveDraftEditBroadcastMessage({ draftID }) {
    if (draftID) {
        const res = await common_axios.patch(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/savedraft/?draftID=${draftID}`
        );
        return res;
    }
}

export async function getSentMessagesData() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/savedraft/?category=Sent`
    );

    return res;
}

export async function getDraftOfBroadcastMessage() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/savedraft/?category=Draft`
    );

    return res;
}

export async function getDraftDataByID(draftID) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/savedraft/?draftID=${draftID}`
    );

    return res;
}

export async function editDraft(draftID) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/savedraft/?draftID=${draftID}&action=edit`
    );

    const draftStatus = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/draftstatus/?draftID=${draftID}&action=edit`
    );
    return res;
}
export async function discardDraft(draftID) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/draftstatus/?draftID=${draftID}&action=discard`
    );
    return res;
}
export async function getMessagePreviewData(queryUrl) {
    let url = queryUrl?.href;
    if (url) {
        const res = await common_axios.get(queryUrl);

        return res;
    }
}

export async function sendMessageBroadcast() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/broadcast/
        `
    );

    return res;
}
export async function sendMessageEditBroadcast({ draftID }) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/broadcast/?draftID=${draftID}
        `
    );

    return res;
}

export async function getDepartmentData(factoriesID) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/departments/?factories=${factoriesID}
        `
    );

    return res;
}
