import { Col, Layout, Row, Button, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import AddPolicy from "./AddPolicy";
import CollapsableMenu from "../../components/CollapsableMenu";
import { getAllPolicies } from "../../Adapters/PolicyDashboard";
import _ from "lodash";
import styles from "./Policy.module.css";
import TablePolicy from "./TablePolicy";
import { userState } from "../../RecoilState/userState";
import { useRecoilState } from "recoil";

const PolicyDashboard = () => {
    const [showAddEditUser, setShowAddEditUser] = useState(false);
    const [policyData, setPolicyData] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const canChangePolicy = user?.group_permissions?.includes("change_policy");

    useEffect(() => {
        !isEditMode && setSelectedRecord([]);
    }, [isEditMode]);

    const callgetPolicies = useCallback(async () => {
        try {
            const response = await getAllPolicies("policy", "dashboard");
            const policies = response.data.message_body.Policies;
            setPolicyData(policies);

            if (isEditMode && selectedRecord) {
                const updatedRecord = policies.find(
                    (policy) => policy.id === selectedRecord.id
                );
                setSelectedRecord(updatedRecord || null); // Update or clear the selected record
            }
        } catch (error) {
            console.error("Failed to fetch policies:", error);
        }
    }, [isEditMode, selectedRecord]);

    useEffect(() => {
        callgetPolicies();
    }, [isEditMode]);

    return (
        <Layout>
            <CollapsableMenu></CollapsableMenu>
            <div style={{ width: "90%" }}>
                <Row>
                    <Col span={24}>
                        {showAddEditUser ? (
                            <AddPolicy
                                setShowAddEditUser={setShowAddEditUser}
                                isEditMode={isEditMode} // Pass isEditMode as a prop
                                setIsEditMode={setIsEditMode}
                                recordData={selectedRecord} // Pass selectedRecord as a prop when in "edit" mode
                                refreshFile={callgetPolicies}
                            />
                        ) : (
                            <>
                                <Row
                                    justify="space-between"
                                    style={{
                                        margin: "1rem 0 0 0",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                    <Col flex="60%">
                                        <h1
                                            style={{
                                                textAlign: "start",
                                                padding: "1rem 0 0 2rem",
                                            }}>
                                            Policies
                                        </h1>
                                    </Col>
                                    {user?.group_permissions?.includes(
                                        "add_policy"
                                    ) && (
                                        <button
                                            onClick={() =>
                                                setShowAddEditUser(true)
                                            }
                                            className="primaryButton">
                                            Add Policy
                                        </button>
                                    )}
                                </Row>
                                <div className={styles.TableContainer}>
                                    <TablePolicy
                                        onRowClick={(record) => {
                                            if (canChangePolicy) {
                                                setSelectedRecord(record);
                                                setShowAddEditUser(true);
                                                setIsEditMode(true);
                                            }
                                        }}
                                        policyDataState={policyData}
                                    />
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default PolicyDashboard;
