import React, { useRef, useEffect } from "react";
import { useState } from "react";
import styles from "./FilterDropDown.module.css";
const MultiSelectDropDown = ({
    labelData,
    dropdownShow,
    setDropdownShow,
    disabled,
    selectedData,
    setSelectedData,
    showConfirmation,
    setUpdateFlag,
    updateFlag,
}) => {
    const selectAllRef = useRef();
    const [newSelectedData, setNewSelectedData] = useState([...selectedData]);

    useEffect(() => {
        setNewSelectedData(selectedData);
    }, [selectedData]);

    const checkedHandler = (e, item) => {
        if (showConfirmation) {
            if (e.target.checked && !newSelectedData?.includes(item)) {
                setNewSelectedData((prev) => [...prev, item]);
            }
            //checking the condition if the selected label is unchecked and we have the item present in our selected Data array then filterout that item and add updated array in selected Data
            else if (!e.target.checked && newSelectedData?.includes(item)) {
                const newData = newSelectedData?.filter((val) => val !== item);
                setNewSelectedData(newData);
            }
        } else {
            //checking the condition if the selected label is checked and we do not have the item present in our selected Data array, then add that item in selected data
            if (e.target.checked && !selectedData?.includes(item)) {
                setSelectedData((prev) => [...prev, item]);
            }
            //checking the condition if the selected label is unchecked and we have the item present in our selected Data array then filterout that item and add updated array in selected Data
            else if (!e.target.checked && selectedData?.includes(item)) {
                const newData = selectedData?.filter((val) => val !== item);
                setSelectedData(newData);
            }
        }
    };

    const selectAllHandler = (e) => {
        if (showConfirmation) {
            if (e.target.checked) {
                setNewSelectedData(labelData.map((item) => item));
            } else {
                newSelectedData.includes("English")
                    ? setNewSelectedData(["English"])
                    : setNewSelectedData([]);
            }
        } else {
            if (e.target.checked) {
                setSelectedData(labelData.map((item) => item));
            } else {
                selectedData.includes("English")
                    ? setSelectedData(["English"])
                    : setSelectedData([]);
            }
        }
    };
    const handleOk = () => {
        setSelectedData(newSelectedData);
        setDropdownShow(false);
        setUpdateFlag(!updateFlag);
    };

    const handleCancel = () => {
        setNewSelectedData(selectedData);
        setDropdownShow(false);
    };
    useEffect(() => {
        //checking if selectedData array lenght is equal to given lable data array length then changing the propery of checked respectively
        if (selectAllRef.current) {
            if (showConfirmation) {
                if (newSelectedData?.length === labelData.length)
                    selectAllRef.current.checked = true;
                else selectAllRef.current.checked = false;
            } else {
                if (selectedData?.length === labelData.length)
                    selectAllRef.current.checked = true;
                else selectAllRef.current.checked = false;
            }
        }
    }, [selectedData, newSelectedData, dropdownShow, labelData]);

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <div
                style={{ padding: "3px 0 3px" }}
                className={`${
                    styles[
                        disabled === true ? "disabledField" : "dropdownSelect"
                    ]
                } ${styles.dropdownSelect}`}
                onClick={() => setDropdownShow(!dropdownShow)}>
                {showConfirmation
                    ? newSelectedData?.map((item, index) =>
                          index === newSelectedData.length - 1
                              ? ` ${item} `
                              : ` ${item},`
                      )
                    : selectedData?.map((item, index) =>
                          index === selectedData.length - 1
                              ? ` ${item} `
                              : ` ${item},`
                      )}
                <div className={styles.dropSelect}>
                    <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="down"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true">
                        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                    </svg>
                </div>
            </div>
            {dropdownShow && disabled === false && (
                <div className={styles.dropdownBox}>
                    {labelData.length > 0 ? (
                        <div className="">
                            <div className={styles.innerDropdownBox}>
                                {labelData.length === 1 &&
                                labelData.includes("English") ? (
                                    <input
                                        type="checkbox"
                                        id="Select All"
                                        ref={selectAllRef}
                                        checked={true}
                                        disabled
                                    />
                                ) : (
                                    <input
                                        type="checkbox"
                                        id="Select All"
                                        ref={selectAllRef}
                                        onChange={(e) => selectAllHandler(e)}
                                    />
                                )}
                                <label htmlFor="Select All"> Select All</label>
                            </div>
                            <hr />
                            <div className={styles.parentItem}>
                                {labelData.map((item) => (
                                    <div
                                        className={styles.itemDataMain}
                                        key={item}>
                                        <div>
                                            {item === "English" ? (
                                                <input
                                                    disabled
                                                    type="checkbox"
                                                    checked={true}
                                                    name={item}
                                                    id={item}
                                                />
                                            ) : (
                                                <input
                                                    onChange={(e) =>
                                                        checkedHandler(e, item)
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        showConfirmation
                                                            ? newSelectedData?.includes(
                                                                  item
                                                              )
                                                            : selectedData?.includes(
                                                                  item
                                                              )
                                                    }
                                                    name={item}
                                                    id={item}
                                                />
                                            )}
                                            <label
                                                style={{ marginLeft: "6px" }}
                                                htmlFor={item}>
                                                {item}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {showConfirmation && (
                                <div className={styles.buttonContainer}>
                                    <button
                                        className="secondaryButton"
                                        onClick={handleCancel}>
                                        <img
                                            src="/assets/images/BroadcastMessage/cross.svg"
                                            style={{
                                                height: "auto",
                                                width: "20px",
                                            }}
                                        />
                                    </button>
                                    <button
                                        className="primaryButton"
                                        onClick={handleOk}>
                                        <img
                                            src="/assets/images/BroadcastMessage/check.svg"
                                            style={{
                                                height: "auto",
                                                width: "20px",
                                            }}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>No Data Found</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropDown;
