import axios from "axios";

export async function postUserMetricData(data) {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/uploadUserMetrics/`,
            data
        );
        return res;
    } catch (error) {
        console.error("Error during API call:", error);
        throw error; // Re-throw the error to propagate it to the caller
    }
}
