import { common_axios } from "../utils/AxiosSettings";

export async function getCases(critical, activeTab, factory, company) {
    if (activeTab) {
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/company/${company}/factory/${factory}/cases/`
        );
        //adding param based on type of data need to show
        queryUrl.searchParams.append("case_type", activeTab);
        queryUrl.searchParams.append("critical", critical);

        const res = await common_axios.get(queryUrl);
        return res;
    }
}
