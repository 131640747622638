// Gets username from userID

import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getUserNameforDisplay } from "../../Adapters/userCalls";
import { useRecoilState } from "recoil";
import { tokenState } from "../../RecoilState/tokenState";

export default function UserDisplayComp({ role, userid }) {
    const [name, setName] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useRecoilState(tokenState);
    let finalName = name;
    useEffect(() => {
        if (userid && token) {
            setLoading(true);
            getUserNameforDisplay(userid).then((res) => {
                if (Object.values(res.data.roleData)[0].user.name) {
                    setName(Object.values(res.data.roleData)[0].user.name);
                    setLoading(false);
                } else {
                    setName(Object.values(res.data.roleData)[0].user.email);
                    setLoading(false);
                }
            });
        }
        return () => {};
    }, [userid]);
    if (name.includes("@")) {
        finalName = name.split("@")[0];
    }
    if (loading) {
        return <></>;
    } else {
        return <>{finalName}</>;
    }
}

UserDisplayComp.propTypes = {
    role: PropTypes.string,
    userid: PropTypes.number,
};
