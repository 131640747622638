import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { fetchRegions } from "../../../../Adapters/RegionCalls";
import { regionState } from "../../../../RecoilState/regionState";

const useFetchRegion = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [regions, setRegions] = useRecoilState(regionState);

    const fetchRegionData = async () => {
        try {
            const response = await fetchRegions();
            setRegions(response.data.message_body.Regions);
            setData(response.data.message_body.Regions); // Set data from the response
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { fetchRegionData, data, loading, error };
};

export default useFetchRegion;
