import React from "react";
import styles from "../../pages/CaseReport/CaseReport.module.css";
const PreviewSendMessage = ({ sendMessageCall, previewScreenData }) => {
    return (
        <>
            <div className={styles.mainWrapper}>{previewScreenData?.body}</div>
            <div className={styles.footerWrapper}>
                <button
                    className={styles.solidButton}
                    onClick={() =>
                        sendMessageCall(
                            "caseDashboardSendMessage",
                            "Case Dashboard- Send Message"
                        )
                    }>
                    Send message
                </button>
            </div>
        </>
    );
};

export default PreviewSendMessage;
