import { Col, Row } from "antd";
import React from "react";

const HelpTab = ({ linksData }) => {
    return (
        <Row
            style={{
                backgroundColor: "white",
                margin: "0 2rem 2rem",
                padding: "1rem",
                borderRadius: "16px",
                minHeight: "70vh",
            }}>
            <Col span={24}>
                <Row
                    style={{
                        backgroundColor: "#E2E5ED",
                        borderRadius: "16px 16px 0 0",
                        height: "48px",
                        borderTop: "1px solid black",
                        padding: " 1rem 0rem 1rem 1rem",
                        textAlign: "start",
                    }}>
                    <Col span={24}>
                        <h4> Video Links</h4>
                    </Col>
                </Row>
                <Row
                    style={{
                        height: "90%",
                        overflowY: "scroll",
                        padding: "10px",
                        textAlign: "start",
                    }}>
                    <ol style={{ paddingLeft: "20px" }}>
                        {Object.entries(linksData).map(
                            ([policyName, attachedLink], index) => (
                                <li
                                    key={index}
                                    style={{ marginBottom: "10px" }}>
                                    <a
                                        href={attachedLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}>
                                        {policyName}
                                    </a>
                                </li>
                            )
                        )}
                    </ol>
                </Row>
            </Col>
        </Row>
    );
};

export default HelpTab;
