import { Button } from "antd";
import React from "react";

const ServerError = () => {
    return (
        <div>
            <div>
                <img src="/assets/images/ErrorPage/500error.svg" />
            </div>
            <h2>Try again after some time</h2>
        </div>
    );
};

export default ServerError;
