export const title = [
    ["Dashboard Introduction"],
    ["Stay informed about the overall progress"],
    ["Never miss a deadline"],
    ["Efficiently Manage cases for Audit"],
    ["Monitor Approved cases effortlessly"],
    ["Monitor Reopened cases effortlessly"],
    ["User Management"],
    ["Start Auditing"],
];
export const description = [
    [
        [
            "Welcome to Inache's Quality Checker Dashboard – Effortlessly conduct audits with our intuitive dashboard.",
        ],
        ["Ready for the rundown?"],
    ],
    [
        [
            "Quickly assess your workload with the Total Cases vs. Reviewed Cases display.",
        ],
    ],
    [
        [
            "The Due Date feature provides a clear timeline, ensuring timely completion and organized task management.",
        ],
    ],
    [
        [
            "The table provides all the cases that need to be audited. Prioritize and streamline your workflow for swift and effective case review.",
        ],
    ],
    [
        [
            "Effortlessly monitor successful outcomes with the Approved section, summarising cases that have passed auditing standards.",
        ],
    ],
    [
        [
            "Stay informed about cases flagged for reevaluation in the Reopened section, ensuring prompt action and thorough auditing procedures.",
        ],
    ],
    [
        [
            "Switch back to other roles as per your convenience. Ensuring a smooth transition of responsibilities for uninterrupted workflow.",
        ],
    ],
    [
        ["Ready to begin?"],

        [
            "Click on the Highlighted Case column or Begin button to start auditing the first case. ",
        ],
    ],
    [["Congratulations! You have audited all the cases."]],
];
