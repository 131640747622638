export function convertroleToText(role) {
    let userRole;
    if (role == "CR") {
        userRole = "Case Reporter";
    } else if (role == "CT") {
        userRole = "Case Troubleshooter";
    } else if (role == "CM") {
        userRole = "Case Manager";
    } else if (role == "FACTORY_ADMIN") {
        userRole = "Factory Admin";
    } else if (role == "QC") {
        userRole = "Quality Checker";
    } else if (role == "SUPER_ADMIN") {
        userRole = "Super Admin";
    }
    return userRole;
}
