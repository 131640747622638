import React from "react";
import { Divider } from "antd";
import CaseReportBlock from "../../../../components/Utils/CaseReportBlock";
import { SendMessageVisibility } from "../../../../RecoilState/SendMessageVisibility";
import { useRecoilState } from "recoil";
import styles from "../../CaseReport.module.css";
import DateTimeFormatter from "../../../../components/Utils/DateTimeFormatter";
const AcknowledgementMessage = ({ QCcaseDatastate }) => {
    const [visible, setVisible] = useRecoilState(SendMessageVisibility);

    return (
        <div>
            {QCcaseDatastate?.timestamp ? (
                <>
                    <div className={styles.caseReportHeading}>
                        Acknowledgement Message
                    </div>
                    <div style={{ marginTop: "1rem", width: "50%" }}>
                        <CaseReportBlock
                            label="Message"
                            displayMessage={QCcaseDatastate?.message}
                        />
                        <CaseReportBlock
                            label="Time Stamp"
                            displayMessage={
                                <DateTimeFormatter
                                    dateTimeString={QCcaseDatastate?.timestamp}
                                />
                            }
                        />
                    </div>
                </>
            ) : (
                <div style={{ textAlign: "center" }}>
                    <button
                        htmltype="submit"
                        className="primaryButton"
                        onClick={() => {
                            setVisible(true);
                        }}>
                        Send Acknowledgement Message
                    </button>
                </div>
            )}
        </div>
    );
};

export default AcknowledgementMessage;
