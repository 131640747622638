import React from "react";
import { Row, Col, Divider } from "antd";
import { Typography } from "antd";

const { Title } = Typography;
const CaseReportBlock = ({ label, displayMessage }) => {
    return (
        <>
            <Row
                gutter={[8, 0]}
                style={{ marginBottom: " 2rem", flexFlow: "unset" }}>
                <Col flex="180px">
                    <Title
                        level={5}
                        style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: "16px",
                            fontStyle: "normal",
                            color: "#1b2559",
                        }}>
                        {label}
                    </Title>
                </Col>
                <Col flex="auto">
                    {displayMessage || typeof displayMessage === "number" ? (
                        <>
                            {displayMessage}
                            <Divider
                                style={{
                                    margin: "0",
                                    borderTop: "1px solid #BEBEBE",
                                }}
                            />
                        </>
                    ) : (
                        "-"
                    )}
                </Col>
            </Row>
        </>
    );
};

export default CaseReportBlock;
