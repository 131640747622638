import { Modal } from "antd";
import React, { useState } from "react";
const GuidelinesModal = ({ modalOpen, setModalOpen, metricData }) => {
    return (
        <>
            <Modal
                title="Guidelines"
                centered
                visible={modalOpen}
                footer={null}
                onCancel={() => setModalOpen(false)}
                className="guidelineModal">
                {/* css comming from antd-overrid.scss file */}
                <p>
                    The incentive is calculated for the complete team based on
                    the compliance criteria as below :
                    <ol>
                        <li>
                            Team should close at least {metricData?.caseClosure}{" "}
                            cases in a month.
                        </li>
                        <li>
                            All compliance variables(T0, T1, T2, T3) should be
                            followed {metricData?.complianceRate}%.
                        </li>
                        <li>
                            Team should conduct at least{" "}
                            {metricData?.awarenessProgram} Inache awareness
                            programs per month.
                        </li>
                        <li>
                            Reopened cases by QC should be less than or equals
                            to {metricData?.reopenRate}.
                        </li>
                    </ol>
                </p>
            </Modal>
        </>
    );
};

export { GuidelinesModal };
