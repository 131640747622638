import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
const PageNotfound = () => {
    const navigate = useNavigate();

    const handleReturnToHome = () => {
        navigate("/home");
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    textAlign: "center",
                    flexDirection: "column",
                }}>
                <img src="/assets/images/ErrorPage/error404.svg" />
            </div>
            <Button className="primaryButton" onClick={handleReturnToHome}>
                Return To Home
            </Button>
        </>
    );
};

export default PageNotfound;
