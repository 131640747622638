import Operation from "antd/lib/transfer/operation";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { getAllFactoriesOfCompany } from "../../../../../Adapters/FactoryAndCompanyCalls";
import { FactoryData } from "../../../../../RecoilState/FactoryData";
import { tokenState } from "../../../../../RecoilState/tokenState";
import { userState } from "../../../../../RecoilState/userState";
export const useFetchFactories = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [token] = useRecoilState(tokenState);
    const [user] = useRecoilState(userState);
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);

    async function fetchFactoryData() {
        setIsLoading(true);

        try {
            const response = await getAllFactoriesOfCompany(
                user.company_fk,
                token.access,
                user.role === "REGIONAL_ADMIN" && "region"
            );
            const factories = response?.data?.message_body?.Factories;
            setFactoryList(factories);
            setIsLoading(false);
            return factories;
        } catch (error) {
            // Handle the error as needed
            setIsLoading(false);
            throw error;
        }
    }

    return {
        fetchFactoryData,
        isLoading,
        factories: FactoryList,
    };
};
