import React, { useEffect, useState } from "react";
import styles from "../../IncentiveDashboard/Charts/Charts.module.css";
import { Line } from "@ant-design/plots";
import { Row, Col } from "antd";
export default function UtilizationChart({ caseData }) {
    const [data, setData] = useState([]);
    const [showFormula, setshowFormula] = useState(false);
    useEffect(() => {
        caseData && setData(caseData);
    }, [caseData]);
    const COLOR_PLATE_10 = [
        "#5B8FF9",
        "#5AD8A6",
        "#5D7092",
        "#F6BD16",
        "#E8684A",
        "#6DC8EC",
        "#9270CA",
        "#FF9D4D",
        "#269A99",
        "#FF99C3",
    ];

    const config = {
        data,
        xField: "month",
        yField: "value",
        seriesField: "factory",
        legend: { position: "top-right" },
        yAxis: {
            label: {
                formatter: (v) =>
                    `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        color: COLOR_PLATE_10,
        yAxis: {
            title: {
                text: "---------- Utilisation Rate ----------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                autoHide: false,
            },
        },
        xAxis: {
            title: {
                text: " ----------------------------- Months -----------------------------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                autoHide: false,
            },
        },
    };
    return (
        <>
            <div
                className={styles.container + " " + styles.compliance}
                style={{ pageBreakinside: "avoid", pageBreakAfter: "always" }}
                id="compliance-chart">
                <div className={styles.flexAlign}>
                    <h1>Utilisation Rate</h1>
                    <p
                        className={styles.functionBox}
                        onMouseEnter={() => setshowFormula(true)}
                        onMouseLeave={() => setshowFormula(false)}>
                        <img src="/assets/images/Incentive/function.svg" />
                        {showFormula ? (
                            <span className={styles.formulaBox}>
                                Formula = (No. of Cases received in a month or
                                year or week/ No. of Worker in a company) * 100
                            </span>
                        ) : null}
                    </p>
                </div>

                <Line {...config} />
            </div>
        </>
    );
}
