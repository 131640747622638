import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Divider } from "antd";
import {
    getEligibilityCriteria,
    postEligibilityCriteria,
} from "../../../Adapters/IncentiveCalls";
import { userState } from "../../../RecoilState/userState";
import { useRecoilState } from "recoil";

const EligibilityCriterias = ({
    criteriaModalOpen,
    setCriteriaModalOpen,
    setUpdateMerticFlag,
    updateMerticFlag,
}) => {
    const [user] = useRecoilState(userState);
    const [eligibilityCriterias, setEligibilityCriterias] = useState(null);
    const [formData, setFormData] = useState({
        awarenessPrograms: "",
        caseClosure: "",
        complianceRate: "",
        reopenRate: "",
    });
    const fetchEligibilityCriteria = async () => {
        const res = await getEligibilityCriteria(user?.company_fk);
        setEligibilityCriterias(res?.data.message_body);
        setFormData({
            awarenessPrograms: res?.data.message_body?.awarenessPrograms || "",
            caseClosure: res?.data.message_body?.caseClosure || "",
            complianceRate: res?.data.message_body?.complianceRate || "",
            reopenRate: res?.data.message_body?.reopenRate || "",
        });
    };
    useEffect(() => {
        fetchEligibilityCriteria();
    }, [user?.company_fk]);

    const handleSave = async () => {
        try {
            if (user?.company_fk !== null && user?.company_fk !== undefined) {
                const dataWithCompany = {
                    ...formData,
                    company: user.company_fk,
                };

                await postEligibilityCriteria(dataWithCompany);
                setCriteriaModalOpen(false);
                fetchEligibilityCriteria();
                setUpdateMerticFlag(!updateMerticFlag);
            } else {
                console.error("User company information is not available.");
            }
        } catch (error) {
            console.error("Error saving eligibility criteria:", error);
        }
    };

    const handleInputChange = (key, value) => {
        const floatValue = parseFloat(value);
        const newValue = isNaN(floatValue) ? "" : floatValue;

        setFormData({
            ...formData,
            [key]: newValue,
        });
    };

    return (
        <div>
            <Modal
                title={
                    <span style={{ fontSize: "1.2rem" }}>
                        Edit Eligibility Criteria
                    </span>
                }
                open={criteriaModalOpen}
                onCancel={() => setCriteriaModalOpen(false)}
                style={{ textAlign: "start" }}
                footer={[
                    <button
                        key="cancel"
                        onClick={() => setCriteriaModalOpen(false)}
                        className="secondaryButton">
                        Cancel
                    </button>,
                    <button
                        key="save"
                        onClick={handleSave}
                        className="primaryButton">
                        Save
                    </button>,
                ]}>
                <p>
                    Set the criteria on which the factories will be eligible for
                    the Incentive cycle.
                </p>
                <Form layout="horizontal">
                    <Form.Item
                        label="Mandatory Awareness Program"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}>
                        <Input
                            data-testid="awarenessProgramsInput"
                            value={formData.awarenessPrograms}
                            onChange={(e) =>
                                handleInputChange(
                                    "awarenessPrograms",
                                    e.target.value
                                )
                            }
                            style={{ width: "90%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Minimum Case close count"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}>
                        <Input
                            data-testid="caseClosureInput"
                            value={formData.caseClosure}
                            onChange={(e) =>
                                handleInputChange("caseClosure", e.target.value)
                            }
                            style={{ width: "90%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Compliance Rate"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}>
                        <Input
                            data-testid="complianceRateInput"
                            value={formData.complianceRate}
                            onChange={(e) =>
                                handleInputChange(
                                    "complianceRate",
                                    e.target.value
                                )
                            }
                            style={{ width: "90%" }}
                            suffix="%"
                        />
                    </Form.Item>
                    <Form.Item
                        label="QC Case Reopen Rate"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}>
                        <Input
                            data-testid="reopenRateInput"
                            value={formData.reopenRate}
                            onChange={(e) =>
                                handleInputChange("reopenRate", e.target.value)
                            }
                            style={{ width: "90%" }}
                            suffix="%"
                        />
                    </Form.Item>
                </Form>
                <Divider style={{ margin: "0px" }} />
            </Modal>
        </div>
    );
};

export default EligibilityCriterias;
