import { Button, Input, Spin, Table } from "antd";
import React from "react";
import DateTimeFormatter from "../../Utils/DateTimeFormatter";
import _ from "lodash";

const QCTableData = ({ tableData, loading, filterTable, setFilterTable }) => {
    const onChange = (pagination, filters, sorter) => {
        const filterTable = [...tableData];
        if (sorter.order === "ascend") {
            filterTable.sort((a, b) => {
                return a[sorter.field] > b[sorter.field] ? 1 : -1;
            });
        } else if (sorter.order === "descend") {
            filterTable.sort((a, b) => {
                return a[sorter.field] < b[sorter.field] ? 1 : -1;
            });
        }
        setFilterTable(filterTable);
    };
    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item, index) => ({
            text: formatter(item),
            value: formatter(item),
            key: `${formatter(item)}_${index}`,
        }));
    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "Name",
            key: "Name",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Name - b.Name,
            filters: filterData(tableData, (item) => item.Name),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const UserName =
                    record.Name && record.Name.toString().toLowerCase().trim();
                return UserName.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
        },
        {
            title: "Email Id",
            dataIndex: "Email_id",
            key: "Email_id",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Email_id - b.Email_id,
            filters: filterData(tableData, (item) => item.Email_id),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const email =
                    record.Email_id &&
                    record.Email_id?.toString().toLowerCase().trim();
                return email.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
        },
        {
            title: "Factory",
            dataIndex: "Factory",
            key: "Factory",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.Factory - b.Factory,
            filters: filterData(tableData, (item) => item?.Factory),
            onFilter: (value, record) => {
                const searchValue = value?.toLowerCase().trim();
                const factory =
                    record.Factory &&
                    record?.Factory?.toString().toLowerCase().trim();
                return factory.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
        },
        {
            title: "Date",
            dataIndex: "Date",
            key: "Date",
            sorter: (a, b) => a.Date - b.Date,
            render: (text) => <DateTimeFormatter dateTimeString={text} />,
        },

        {
            title: "No. of cases",
            dataIndex: "cases",
            key: "cases",
            sorter: (a, b) => a.cases - b.cases,
        },
        {
            title: "Reopened",
            dataIndex: "reopened",
            key: "reopened",
            sorter: (a, b) => a.reopened - b.reopened,
        },
        {
            title: "Progress",
            dataIndex: ["Progress", "cases"],
            key: "Progress",
            sorter: (a, b) => a.Progress - b.Progress,
            render: (text, row) => (
                <div>{`${row["Progress"]}/${row["cases"]}`}</div>
            ),
        },
    ];

    return (
        <>
            {loading ? (
                <Spin />
            ) : (
                <Table
                    onChange={onChange}
                    dataSource={
                        filterTable.length > 0 ? filterTable : tableData
                    }
                    columns={columns}
                    pagination={true}
                    scroll={{
                        y: `450px`,
                        scrollToFirstRowOnChange: true,
                    }}
                />
            )}
        </>
    );
};

export default QCTableData;
