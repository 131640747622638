import { Spin, Table } from "antd";
import React from "react";
import NoCasesTable from "../NoCasesTable";
import styles from "../TableData.module.css";

const ResolvedCaseTable = ({
    loading,
    cases,
    columns,
    handleRowClick,
    filterTableData,
}) => {
    return (
        <div>
            {loading ? (
                <Spin />
            ) : cases.length == 0 ? (
                <NoCasesTable />
            ) : (
                <Table
                    pagination={{
                        showSizeChanger: false,
                        pageSize: 10,
                    }}
                    scroll={{ y: 400 }}
                    columns={columns}
                    dataSource={
                        filterTableData.length > 0 ? filterTableData : cases
                    }
                    size="middle"
                    rowKey="CaseNumber"
                    className={styles.tableContainer}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record.id),
                    })}
                    bordered
                />
            )}
        </div>
    );
};

export default ResolvedCaseTable;
