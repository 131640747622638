import { Col, Layout, Row, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import incstyles from "../IncentiveDashboard/IncentiveDashboard.module.css";
import CaseCountsBox from "./CaseCountsBox";
import ComplainceChartRe from "./Charts/ComplainceChartRe";
import ComplaintsChart from "./Charts/ComplaintsChart";
import QualifiedCharts from "./Charts/QualifiedCharts";
import SeverityChart from "./Charts/SeverityChart";
import styles from "./ReportsDashboard.module.css";
import TotalFactoriesBox from "./TotalFactoriesBox";
import { CalendarOutlined } from "@ant-design/icons";
import { DatePicker, Space } from "antd";
import { getReportingData } from "../../Adapters/ReportingCalls";
import UtilizationChart from "./Charts/UtilizationChart";
import { useScreenshot, createFileName } from "use-react-screenshot";
import FilterDropDown from "../Utils/FilterDropDown";
import { userState } from "../../RecoilState/userState";
import { tokenState } from "../../RecoilState/tokenState";
import CollapsableMenu from "../CollapsableMenu";
import { AnalyticDataFalg } from "../../RecoilState/AnalyticDataFlag";
import useFetchRegion from "../AdminDashboards/RegionDashboard/RegionHooks/FetchRegionHook";
import { FactoryData } from "../../RecoilState/FactoryData";
import { getAllFactoriesOfCompany } from "../../Adapters/FactoryAndCompanyCalls";
import { debounce } from "lodash";
import { getDivisionDetails } from "../../Adapters/DivisionCalls";
const { RangePicker } = DatePicker;
const lodash = require("lodash");
const dateFormat = "YYYY-MM-DD";
export default function ReportsDashboard() {
    const printRef = React.useRef();
    const [allFactoryData, setAllFactoryData] = useRecoilState(FactoryData);
    const [user] = useRecoilState(userState);
    const [analyticDataFlag, setAnalyticDataFlag] =
        useRecoilState(AnalyticDataFalg);
    const [token] = useRecoilState(tokenState);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [regionDropdownShow, setRegionDropdownShow] = useState(false);
    const [factoryDropdownShow, setFactoryDropdownShow] = useState(false);
    const [divisionDropdownShow, setDivisionDropdownShow] = useState(false);
    const [factorySelectedData, setFactorySelectedData] = useState([]);

    const [regionSelectedData, setRegionSelectedData] = useState([]);
    const [divisionSelectedData, setDivisionSelectedData] = useState([]);

    const [reportingData, setReportingData] = useState([]);
    const [allRegion, setAllRegion] = useState([]);
    const [allFactories, setAllFactories] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const { fetchRegionData, data, loading, error } = useFetchRegion();
    const [regionSelectedId, setRegionSelectedId] = useState([]);
    const [regionMap] = useState(new Map());
    const [divisionMap] = useState(new Map());

    useEffect(() => {
        if (user.role !== "SUPER_ADMIN" || user.role !== "LEAD_SUPERVISOR") {
            if (user.role !== "REGIONAL_ADMIN") {
                if (allFactoryData.length > 0) {
                    const divisionData = allFactoryData
                        .filter((val) => val.Code === user.code)
                        .map((val) => val.division);
                    setDivisionSelectedData(divisionData);
                    setDivisionList(divisionData);
                }
            }
        }
    }, [allFactoryData]);

    useEffect(() => {
        if (data) {
            setAllRegion(data);
            data.forEach((val) => {
                regionMap.set(val.Name, val.id);
            });
            if (
                user.role !== "SUPER_ADMIN" ||
                user.role !== "LEAD_SUPERVISOR"
            ) {
                if (user.role !== "REGIONAL_ADMIN") {
                    setRegionSelectedData([user.region]);
                    setFactorySelectedData([user.code]);
                } else {
                    setRegionSelectedId([user.region_fk]);
                    const regionSelected = data.filter((val) => {
                        return val.id === user.region_fk;
                    });
                    setRegionSelectedData([regionSelected[0]?.Name]);
                }
            }
        }
    }, [data]);

    const handleDateChange = (value) => {
        if (value) {
            setStartDate(value[0].format(dateFormat));
            setEndDate(value[1].format(dateFormat));
        }
    };
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0,
    });
    const download = (
        image,
        { name = "Incentive report", extension = "jpg" } = {}
    ) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };
    const downloadScreenshot = () =>
        takeScreenShot(printRef.current).then(download);
    useEffect(() => {
        (async () => {
            try {
                const res = await getReportingData();
                setReportingData(res.data);
            } catch (error) {}
        })();
    }, []);

    useEffect(() => {
        let selectedRegion = [];
        if (regionSelectedData.length > 0) {
            regionSelectedData.forEach((region) => {
                selectedRegion.push(regionMap.get(region));
            });
            setRegionSelectedId(selectedRegion);
        }
        let divisions = [];
        if (allFactoryData.length > 0) {
            const newData = allFactoryData?.filter((val) =>
                regionSelectedData.length > 0
                    ? selectedRegion.includes(val.region_id)
                    : true
            );
            setAllFactories(newData);
            const divisionData = newData.filter((val) => val.division !== null);
            divisionMap.clear();
            divisionData.forEach((val) => {
                if (!divisionMap.has(val.division_id)) {
                    divisionMap.set(val.division_id, {
                        id: val.division_id,
                        name: val.division,
                    });
                    divisions.push({ id: val.division_id, name: val.division });
                }
            });
            setDivisionList(divisions);
        }
        if (
            ["SUPER_ADMIN", "REGIONAL_ADMIN", "LEAD_SUPERVISOR"].includes(
                user.role
            )
        )
            setDivisionSelectedData([]);
    }, [regionSelectedData, allFactoryData]);
    useEffect(() => {
        if (allFactoryData.length > 0) {
            if (
                divisionSelectedData.length == 0 &&
                regionSelectedData.length == 0
            ) {
                setAllFactories(allFactoryData);
                return;
            }
            if (divisionSelectedData.length > 0) {
                const factoryDataList =
                    allFactories.length > 0
                        ? allFactoryData?.filter((val) =>
                              regionSelectedData.length > 0
                                  ? divisionSelectedData.length > 0
                                      ? regionSelectedId.includes(
                                            val.region_id
                                        ) &&
                                        divisionSelectedData.includes(
                                            val.division
                                        )
                                      : regionSelectedId.includes(val.region_id)
                                  : divisionSelectedData.length > 0
                                  ? divisionSelectedData.includes(val.division)
                                  : true
                          )
                        : [];
                setAllFactories(factoryDataList);
            } else {
                const factoryData = allFactoryData?.filter((val) =>
                    regionSelectedId.length > 0
                        ? regionSelectedId.includes(val.region_id)
                        : true
                );
                setAllFactories(factoryData);
            }
        }
    }, [divisionSelectedData]);

    useEffect(() => {
        if (token && user.company_fk != undefined) {
            getAllFactoriesOfCompany(
                user.company_fk,
                token.access,
                user.role === "REGIONAL_ADMIN" && "region"
            ).then((res) => {
                setAllFactoryData(res.data.message_body.Factories);
            });
            fetchRegionData();
        }
    }, [user]);
    useEffect(() => {
        if (!loading) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/cases/fil`
            );
            queryUrl.searchParams.append("company", user.company_fk);
            if (startDate && endDate) {
                queryUrl.searchParams.append("from", startDate);
                queryUrl.searchParams.append("to", endDate);
            }
            if (factorySelectedData.length > 0)
                queryUrl.searchParams.append("factory", factorySelectedData);
            if (regionSelectedData.length > 0) {
                queryUrl.searchParams.append("region", regionSelectedId);
            }
            if (divisionSelectedData.length > 0) {
                const factoryDataList =
                    divisionList.length <= 0 ? allFactoryData : divisionList;
                let selectedDivisionIDs = [];
                divisionSelectedData.forEach((division) => {
                    divisionList
                        .filter((val) => val.name === division)
                        .map((val) => selectedDivisionIDs.push(val.id));
                });
                queryUrl.searchParams.append("division", selectedDivisionIDs);
            }
            try {
                {
                    user.company_fk &&
                        getReportingData(queryUrl).then((res) =>
                            setReportingData(res.data)
                        );
                }
            } catch (error) {
                console.log("error in res");
            }
        }
    }, [analyticDataFlag]);
    //to avoid multiple api call based on dependency changed
    useEffect(() => {
        setAnalyticDataFlag(!analyticDataFlag);
    }, [
        startDate,
        endDate,
        factorySelectedData,
        regionSelectedId,
        divisionSelectedData,
    ]);
    const debouncehandler = debounce(downloadScreenshot, 500);
    return (
        <>
            <Layout>
                <CollapsableMenu></CollapsableMenu>
                <div
                    className={styles.reportesContainer}
                    onClick={() => (
                        setRegionDropdownShow(false),
                        setFactoryDropdownShow(false)
                    )}
                    ref={printRef}>
                    <Row align="middle">
                        <Col span={12} className={styles.textLeft}>
                            <h1 id="AnalyticGeneral">Analytic General</h1>
                        </Col>
                        <Col span={12}>
                            {" "}
                            <button
                                onClick={debouncehandler}
                                className={styles.download}
                                id="button-download">
                                <img src="/assets/images/Incentive/download.svg" />
                                <span> Download report</span>
                            </button>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24, 24]}>
                        <Col span={24}>{loading && <Spin />}</Col>
                        <Col span={24}>
                            <Row gutter={[24, 24]}>
                                <Col span={6}>
                                    {/* common component for specific filter 
                                @props: 
                                - labelData - array (the list of data need to be shown in dropdown
                                - value - string (to fetch that attribute data array)  
                                -  placeholder-  string (showing the placeholder on the filter)
                                - imUrl- string (the path of image))
                                 */}
                                    <FilterDropDown
                                        labelData={allRegion}
                                        value="Name"
                                        dropdownShow={regionDropdownShow}
                                        setDropdownShow={setRegionDropdownShow}
                                        placeholder="Select Region"
                                        imgUrl="map.svg"
                                        selectedData={regionSelectedData}
                                        setSelectedData={setRegionSelectedData}
                                        editable={
                                            user.role === "SUPER_ADMIN" ||
                                            user.role === "LEAD_SUPERVISOR"
                                                ? true
                                                : false
                                        }
                                    />
                                </Col>
                                <Col span={6}>
                                    {/* common component for specific filter 
                                @props: 
                                - labelData - array (the list of data need to be shown in dropdown
                                - value - string (to fetch that attribute data array)  
                                - placeholder-  string (showing the placeholder on the filter)
                                - imUrl- string (the path of image))
                                 */}

                                    <FilterDropDown
                                        labelData={divisionList}
                                        value="name"
                                        dropdownShow={divisionDropdownShow}
                                        setDropdownShow={
                                            setDivisionDropdownShow
                                        }
                                        placeholder="Select Division"
                                        imgUrl="mappin.svg"
                                        selectedData={divisionSelectedData}
                                        setSelectedData={
                                            setDivisionSelectedData
                                        }
                                        editable={
                                            [
                                                "SUPER_ADMIN",
                                                "REGIONAL_ADMIN",
                                                "LEAD_SUPERVISOR",
                                            ].includes(user?.role)
                                                ? true
                                                : false
                                        }
                                    />
                                </Col>
                                <Col span={6}>
                                    {/* common component for specific filter 
                                @props: 
                                - labelData - array (the list of data need to be shown in dropdown
                                - value - string (to fetch that attribute data array)  
                                - placeholder-  string (showing the placeholder on the filter)
                                - imUrl- string (the path of image))
                                 */}

                                    <FilterDropDown
                                        labelData={allFactories}
                                        value="Code"
                                        dropdownShow={factoryDropdownShow}
                                        setDropdownShow={setFactoryDropdownShow}
                                        placeholder="Select Factory"
                                        imgUrl="mappin.svg"
                                        selectedData={factorySelectedData}
                                        setSelectedData={setFactorySelectedData}
                                        editable={
                                            [
                                                "SUPER_ADMIN",
                                                "REGIONAL_ADMIN",
                                                "LEAD_SUPERVISOR",
                                            ].includes(user?.role)
                                                ? true
                                                : false
                                        }
                                    />
                                </Col>
                                <Col span={6}>
                                    {" "}
                                    <div className={incstyles.dateBox2}>
                                        <div
                                            className={
                                                incstyles.calenderSymbol
                                            }>
                                            <CalendarOutlined
                                                style={{ fontSize: "28px" }}
                                            />
                                        </div>
                                        <div
                                            className={styles.rangePickerStyle}>
                                            <RangePicker
                                                onChange={handleDateChange}
                                                bordered={false}
                                                size="large"
                                                suffixIcon={false}
                                                className="antd-calendar"
                                                dropdownClassName="antd-dropDate"
                                                dateFormat={dateFormat}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={styles.container} justify="space-between">
                        <Col span={11} className={styles.caseCountBox}>
                            <Row>
                                {" "}
                                {reportingData?.Cases?.Total} Total cases
                            </Row>
                            <Row justify="end">
                                <CaseCountsBox
                                    caseData={reportingData?.Cases}
                                />
                            </Row>
                        </Col>
                        <Col span={11} className={styles.caseCountBox}>
                            <Row>
                                {reportingData?.factory?.Eligible +
                                    reportingData?.factory?.Ineligible}{" "}
                                Total factories
                            </Row>
                            <Row justify="end">
                                <TotalFactoriesBox
                                    caseData={reportingData?.factory}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row className={styles.container}>
                        <Col flex="100%">
                            <QualifiedCharts
                                caseData={reportingData?.payload}
                            />
                        </Col>
                    </Row>
                    <Row className={styles.container}>
                        <ComplainceChartRe caseData={reportingData?.typeT} />
                    </Row>
                    <Row className={styles.container} justify="space-between">
                        <Col span={11}>
                            <SeverityChart caseData={reportingData?.sevCases} />
                        </Col>
                        <Col span={12}>
                            <ComplaintsChart
                                caseData={reportingData?.compCases}
                            />
                        </Col>
                    </Row>
                    <Row className={styles.container}>
                        <Col flex="100%">
                            <UtilizationChart
                                caseData={reportingData?.UtilisationRate}
                            />
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
}
