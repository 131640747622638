import { common_axios } from "../utils/AxiosSettings";

export async function fetchRegions() {
    const response = common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/region/`
    );

    return response;
}

export async function deleteRegion(region) {
    const res = common_axios({
        url: `${process.env.REACT_APP_BASE_URL_API}/api/accounts/region/?id=${region.id}`,
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    });
    return res;
}

export async function getFactoriesByRegion(region) {
    const response = common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/region/?id=${region}`
    );
    return response;
}

export async function setFactoryToRegion(regionId, factoryList) {
    const res = await common_axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/region/?id=${regionId}&operation=transfer`,
        { factories: factoryList }
    );

    return res;
}

export async function createRegion(regionName) {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/region/`,
        { Name: regionName }
    );

    return res;
}

export async function renameRegion(regionId, regionName) {
    const res = await common_axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/region/?id=${regionId}&operation=name`,
        {
            Name: regionName,
        }
    );
    return res;
}
