import React from "react";
import styles from "../../CaseReport.module.css";
import { formatDate } from "../../../../components/Utils/DateTimeFormatter";

export default function CaseReportTabItem({ tabName, tabValue }) {
    let newTabValue = tabValue;
    let newTabName = tabName;

    if (tabName === "Date") {
        newTabValue = formatDate(tabValue).split(" ")[0];
    } else if (tabName === "Time") {
        newTabValue = formatDate(tabValue).substring(
            formatDate(tabValue).indexOf(" ") + 1
        );
        newTabName = "Time";
    } else if (tabName === "CaseStatus") {
        newTabName = "Status";
        newTabValue = tabValue;
    } else if (tabName === "CaseNumber") {
        newTabName = "Case Number";
    } else if (tabName === "SMS_Data") {
        newTabName = "SMS Content";
    } else if (tabName === "ReportingMedium") {
        newTabName = "Medium";
    }
    return (
        <div className={styles.tabItem}>
            <h4>{newTabName}</h4>
            <p>{newTabValue}</p>
        </div>
    );
}
