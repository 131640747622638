import { Button, Modal, message } from "antd";
import React from "react";
import "../../../common.css";
import { updateDraftQCStatus } from "../../../Adapters/QCcalls";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import { caseReportDataState } from "../../../RecoilState/caseReportDataState";
import { QCCaseReportDataState } from "../../../RecoilState/QCCaseReportDataState";
import { getQCReportTabStatus } from "../../../hooks/getQCReportTabStatus";

const ConfirmationModal = ({ open, setOpen, setStatus, handleTab, tab }) => {
    const [user] = useRecoilState(userState);
    const [caseData] = useRecoilState(QCCaseReportDataState);
    const onChangeAction = async (action) => {
        try {
            await handleTab(
                {
                    progress: "Qc Report",
                    status: action,
                },
                getQCReportTabStatus(tab, "Qc Report")
            );

            setOpen(false);
            setStatus(action);
        } catch (err) {
            setOpen(false);
        }
    };
    return (
        <Modal
            title={"Complete Case Audit"}
            open={open}
            footer={[
                <button
                    key="cancel"
                    className="secondaryButton"
                    onClick={() => onChangeAction("Reopen")}>
                    Reopen
                </button>,
                <button
                    key="ok"
                    className="primaryButton"
                    onClick={() => onChangeAction("Approve")}>
                    Approve
                </button>,
            ]}
            width={350}
            onCancel={() => setOpen(false)}>
            <div>
                <p>
                    Based on your review criteria, should this case file be
                    approved or re-opened for further processing? Please select
                    your option.
                </p>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
