import { atom } from "recoil";
export const ClosingState = atom({
    key: "ClosingState", // unique ID (with respect to other atoms/selectors)
    default: {
        when: "",
        where: "",
        who: "",
        what: "",
        remarks: "",
        template: "",
        language: "",
        message: "",
        templateID: "",
    }, // default value (aka initial value)
});
