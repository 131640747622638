import { Col, Row, Button } from "antd";
import React from "react";
import DeleteRegionButton from "./DeleteRegion";

export function EditRegionFooter({ region, disable }) {
    return (
        <>
            <Col>
                <DeleteRegionButton
                    region={region}
                    label={"Delete Region"}
                    disable={disable}
                />
            </Col>
            <Col>
                <Button
                    type="primary"
                    className="primaryButton"
                    htmlType="submit"
                    style={{
                        borderRadius: "4px",
                        width: "100%",
                        height: 40,
                    }}>
                    Save Changes
                </Button>
            </Col>
        </>
    );
}

export function AddRegionFooter() {
    return (
        <Col>
            <Button
                type="primary"
                className="primaryButton"
                htmlType="submit"
                style={{
                    borderRadius: "4px",
                    width: "100%",
                    height: 40,
                }}>
                Create Region
            </Button>
        </Col>
    );
}
