import { Col, DatePicker, Layout, Row, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import LoggedInComponent from "../../../containers/LoggedInComponent/LoggedInComponent";
import CollapsableMenu from "../../CollapsableMenu";
import { useRecoilState } from "recoil";
import { regionState } from "../../../RecoilState/regionState";
import useFetchRegion from "../RegionDashboard/RegionHooks/FetchRegionHook";
import _, { set } from "lodash";
import { userState } from "../../../RecoilState/userState";
import dayjs from "dayjs";
import { getQualityInspectionData } from "../../../Adapters/QCcalls";
import QCTableData from "./QCTableData";
import RegionDropdown from "./RegionDropdown";

const QuaityInspectionDashboard = () => {
    const [region, setRegion] = React.useState(null);
    const [RegionData] = useRecoilState(regionState);
    const { fetchRegionData } = useFetchRegion();
    const [user] = useRecoilState(userState);
    const [duration] = useState("month");
    const [dateString, setdateString] = useState(dayjs().format("YYYY-MM"));
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterTable, setFilterTable] = useState([]);
    const [regionId, setRegionId] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        if (user?.role == "SUPER_ADMIN" || user?.role == "LEAD_SUPERVISOR") {
            (!RegionData || RegionData?.length == 0) && fetchRegionData();
        } else {
            setRegion(user?.region);
            setRegionId(user?.region_fk);
        }
    }, [user]);
    useEffect(() => {
        if (
            RegionData?.length > 0 &&
            (user.role == "SUPER_ADMIN" || user.role == "LEAD_SUPERVISOR")
        ) {
            setRegion(RegionData[0].Name);
            setRegionId(RegionData[0].id);
        }
    }, [RegionData]);
    useEffect(() => {
        if (region && dateString) {
            setLoading(true);
            let time = dateString.split("-");
            getQualityInspectionData(
                parseInt(time[1]),
                parseInt(time[0]),
                regionId
            )
                .then((res) => {
                    setTableData(res.data);
                })
                .catch((err) => {
                    setTableData([]);
                    message.error(err.response.data.errorMessage);
                })
                .finally(() => {
                    setFilterTable([]);
                    setLoading(false);
                });
        }
    }, [region, dateString]);

    function prevMonth() {
        let newDate = dayjs(dateString).subtract(1, "months");
        setdateString(newDate.format("YYYY-MM"));
    }
    function nextMonth() {
        let newDate = dayjs(dateString).add(1, "months");
        if (!newDate.isAfter(dayjs().endOf("day"))) {
            setdateString(newDate.format("YYYY-MM"));
        }
    }
    function handleDateChange(date) {
        if (date) {
            setdateString(date.format("YYYY-MM"));
            setShowDatePicker(false);
        }
    }
    return (
        <Layout>
            <LoggedInComponent>
                <CollapsableMenu></CollapsableMenu>
                <div style={{ width: "98%" }}>
                    <Row
                        justify="space-between"
                        style={{
                            margin: "1rem 0 0 0",
                            display: "flex",
                            alignItems: "center",
                        }}>
                        <Col flex="60%">
                            <h1
                                style={{
                                    textAlign: "start",
                                    padding: "1rem 0 0 2rem",
                                }}>
                                Quality Inspection
                            </h1>
                        </Col>

                        <Col flex="15%">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: "10%",
                                }}>
                                <RegionDropdown
                                    RegionData={RegionData}
                                    region={region}
                                    setRegion={setRegion}
                                    setRegionId={setRegionId}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row
                                style={{
                                    backgroundColor: "white",
                                    margin: "0 2rem 2rem",
                                    padding: "1rem",
                                    borderRadius: "12px",
                                    minHeight: "70vh",
                                }}>
                                <Col span={24}>
                                    <Row
                                        justify="space-between"
                                        style={{
                                            borderBottom: "1px solid #BEBEBE",
                                            padding: "0 0 1rem 0",
                                            marginBottom: "1rem",
                                        }}>
                                        <Col>
                                            {duration === "month" && (
                                                <>
                                                    <img
                                                        src="assets/images/MonthFilter/PrevArrow.svg"
                                                        alt="prev"
                                                        onClick={prevMonth}
                                                    />
                                                    <span
                                                        style={{
                                                            margin: "10px 20px",
                                                            color: "#000",
                                                            fontSize: "20px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setShowDatePicker(
                                                                true
                                                            )
                                                        }>
                                                        {dayjs(
                                                            dateString
                                                        ).format("MMMM")}{" "}
                                                        {dayjs(
                                                            dateString
                                                        ).format("YYYY")}
                                                    </span>
                                                    <img
                                                        src="assets/images/MonthFilter/NextArrow.svg"
                                                        alt="next"
                                                        onClick={nextMonth}
                                                    />
                                                    {showDatePicker && (
                                                        <div
                                                            className="hidden-date-picker"
                                                            style={{
                                                                position:
                                                                    "relative",
                                                            }}>
                                                            <DatePicker
                                                                open={
                                                                    showDatePicker
                                                                }
                                                                onChange={
                                                                    handleDateChange
                                                                }
                                                                picker="month"
                                                                value={dayjs(
                                                                    dateString
                                                                )}
                                                                onOpenChange={(
                                                                    open
                                                                ) =>
                                                                    setShowDatePicker(
                                                                        open
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Col>
                                        <Col>
                                            <Select
                                                defaultValue="Monthly"
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{ overflow: "auto" }}>
                                                <QCTableData
                                                    tableData={tableData}
                                                    loading={loading}
                                                    filterTable={filterTable}
                                                    setFilterTable={
                                                        setFilterTable
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </LoggedInComponent>
        </Layout>
    );
};

export default QuaityInspectionDashboard;
