import { common_axios } from "../utils/AxiosSettings";
export async function getAllFactoriesOfCompany(company, token, operation) {
    let queryUrl = new URL(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factories/?Company=${company}`
    );
    operation && queryUrl.searchParams.append("operation", operation);
    const res = await common_axios.get(queryUrl);
    return res;
}
export async function getFactoryDataByID(factory) {
    const res = common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factory/${factory}`
    );

    return res;
}
export async function getFactorydetails(factory) {
    const res = common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factorydetails/?factory=${factory}`
    );

    return res;
}
