import React from "react";
import styles from "./WelcomeHeading.module.css";
export default function WelcomeHeading({ children, role }) {
    return (
        <div className={styles.WelcomeHeading}>
            <h2>Welcome to Inache!</h2>
            <h1>Hi, {children}</h1>
        </div>
    );
}
