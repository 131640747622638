import React, { useState, useEffect } from "react";
import { useRef } from "react";
import styles from "./FilterDropDown.module.css";

const FilterDropDown = ({
    labelData,
    value,
    dropdownShow,
    setDropdownShow,
    placeholder,
    imgUrl,
    selectedData,
    setSelectedData,
    editable,
}) => {
    const selectAllRef = useRef();
    const checkedHandler = (e, item) => {
        //checking the condition if the selected label is checked and we do not have the item present in our selected Data array, then add that item in selected data
        if (e.target.checked && !selectedData.includes(item)) {
            setSelectedData((prev) => [...prev, item]);
        }
        //checking the condition if the selected label is unchecked and we have the item present in our selected Data array then filterout that item and add updated array in selected Data
        else if (!e.target.checked && selectedData.includes(item)) {
            const newData = selectedData.filter((val) => val !== item);
            setSelectedData(newData);
        }
    };

    //this handler set data from lable data for select all and empty array for uncheck of select all
    const selectAllHandler = (e) => {
        if (e.target.checked && labelData?.length > 0) {
            setSelectedData(labelData?.map((item) => item[`${value}`]));
        } else {
            setSelectedData([]);
        }
    };
    useEffect(() => {
        //checking if selectedData array lenght is equal to given lable data array length then changing the propery of checked respectively
        if (selectAllRef.current) {
            if (selectedData.length === labelData.length)
                selectAllRef.current.checked = true;
            else selectAllRef.current.checked = false;
        }
    }, [selectedData, dropdownShow, labelData]);

    useEffect(() => {
        if (editable && labelData.length > 0) {
            const newLabelData = labelData.map((item) => item[`${value}`]);
            let newData = selectedData.filter((val) =>
                newLabelData.includes(val)
            );
            setSelectedData(newData);
        }
        return () => {};
    }, [labelData.length, selectedData.length]);

    return (
        <div>
            <div
                className={styles.dropdownMainBox}
                onClick={(e) => e.stopPropagation()}>
                <div className={styles.innerDropdownMainBox}>
                    <div className={styles.imageWrapper}>
                        <img src={`/assets/images/Incentive/${imgUrl}`} />
                    </div>
                    {selectedData.length > 0 ? (
                        <div
                            className={`${styles.truncate} ${styles.placeholder}`}
                            style={{ cursor: editable ? "pointer" : "default" }}
                            onClick={() => setDropdownShow(!dropdownShow)}>
                            <div className={styles.placeholderWrapper}>
                                {selectedData.map((item, index) =>
                                    index === selectedData.length - 1
                                        ? ` ${item} `
                                        : ` ${item},`
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            onClick={() => setDropdownShow(!dropdownShow)}
                            className={styles.placeholder}>
                            {placeholder}
                        </div>
                    )}
                </div>
                {editable && dropdownShow ? (
                    <div className={styles.dropdownBox}>
                        <div className={styles.innerDropdownBox}>
                            <input
                                type="checkbox"
                                id="Select All"
                                ref={selectAllRef}
                                onChange={(e) => selectAllHandler(e)}
                            />
                            <label htmlFor="Select All"> Select All</label>
                        </div>
                        <hr />
                        <div className={`${styles.parentItem} ${styles.flex} `}>
                            {labelData.map((item) => (
                                <div
                                    className={styles.itemData}
                                    key={item[`${value}`]}>
                                    <input
                                        onChange={(e) =>
                                            checkedHandler(e, item[`${value}`])
                                        }
                                        type="checkbox"
                                        checked={selectedData.includes(
                                            item[`${value}`]
                                        )}
                                        name={item[`${value}`]}
                                        id={item[`${value}`]}
                                    />
                                    <label htmlFor={item[`${value}`]}>
                                        {item[`${value}`]}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FilterDropDown;
