import React, { useState, useEffect } from "react";
import { Col, Layout, Row } from "antd";
import CollapsableMenu from "../../components/CollapsableMenu";
import PoliciesTab from "./PoliciesTab";
import { getPolicies } from "../../Adapters/PolicyDashboard";

const PoliciesForAllUser = () => {
    const [attachments, setAttachments] = useState({});
    const [links, setLinks] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingData(true);
            try {
                const response = await getPolicies("policy");
                const policies = response.data.message_body.Policies;
                const attachments = {};
                const links = {};

                setLoadingData(false);
                policies.forEach((policy) => {
                    if (policy.attachment && policy.attachment.length > 0) {
                        attachments[policy.policy_name] = policy.attachment;
                    }
                });
                policies.forEach((policy) => {
                    if (
                        policy.attached_link &&
                        policy.attached_link.length > 0
                    ) {
                        links[policy.policy_name] = policy.attached_link;
                    }
                });
                setAttachments(attachments);
                setLinks(links);
            } catch (error) {
                setLoadingData(false);
                console.error("Failed to fetch policies:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <Layout>
            <CollapsableMenu />
            <div style={{ width: "98%" }}>
                <Row
                    justify="space-between"
                    style={{
                        margin: "1rem 0 0 0",
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <Col flex="60%">
                        <h1
                            id="Policies"
                            style={{
                                textAlign: "start",
                                padding: "1rem 0 0 2rem",
                            }}>
                            Policies
                        </h1>
                    </Col>
                </Row>

                <PoliciesTab
                    attachments={attachments}
                    policies={attachments}
                    loadingData={loadingData}
                    links={links}
                />
            </div>
        </Layout>
    );
};

export default PoliciesForAllUser;
