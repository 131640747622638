/* Three components inside renders based on tabOpen value */
import React, { useEffect, useState } from "react";
import { getLanguageList } from "../../Adapters/CaseReportCalls";
import styles from "./CaseUpload.module.css";
import InPerson from "./InPerson";
import SuggestionBox from "./SuggestionBox";
import WorkerCommittee from "./WorkerCommitee";
export default function CaseUpload({ modalCloser }) {
    const [tabOpen, setTabOpen] = React.useState("IPC");
    const [languageList, setLanguageList] = useState("");

    const handleTabOpen = (e) => {
        setTabOpen(e);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getLanguageList();

                const filteredLanguages = res.data.languages.filter(
                    (lang) => lang.trim() !== ""
                );

                // Update resdata with the filtered list
                const updatedResdata = {
                    ...res.data,
                    languages: filteredLanguages,
                };
                setLanguageList(updatedResdata);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className={styles.CaseUploadContainer} id="CaseUploadModal">
            <div className={styles.heading} id="CaseUploadCloseModal">
                <h1>Case Upload</h1>
                <button className={styles.crossIcon} onClick={modalCloser}>
                    <img
                        src="assets/images/CrossClear/clear_24px.svg"
                        alt="Close"
                    />
                </button>
            </div>

            <div className={styles.divider}></div>
            <div className={styles.tabs}>
                <h4
                    id="inPersonComplaintTab"
                    value="IPC"
                    className={tabOpen === "IPC" ? styles.active : null}
                    onClick={() => {
                        handleTabOpen("IPC");
                    }}>
                    IN Person Complaint
                </h4>
                <h4
                    id="workerCommitteeTab"
                    value="WC"
                    className={tabOpen == "WC" ? `${styles.active}` : null}
                    onClick={() => {
                        handleTabOpen("WC");
                    }}>
                    Worker Committee
                </h4>
                <h4
                    id="suggestionBoxTab"
                    value="SB"
                    className={tabOpen === "SB" ? styles.active : null}
                    onClick={() => {
                        handleTabOpen("SB");
                    }}>
                    Suggestion Box
                </h4>
            </div>
            <div className={styles.dividerBlue}></div>
            <div className={styles.tabContent}>
                {
                    //Ternanry based on tabOpen show the components
                    tabOpen === "SB" ? (
                        <SuggestionBox
                            dialogClassName="containerDialog"
                            modalCloser={modalCloser}
                            languageList={languageList}
                        />
                    ) : tabOpen === "IPC" ? (
                        <InPerson
                            dialogClassName="containerDialog"
                            modalCloser={modalCloser}
                            languageList={languageList}
                        />
                    ) : tabOpen === "WC" ? (
                        <WorkerCommittee
                            dialogClassName="containerDialog"
                            modalCloser={modalCloser}
                            languageList={languageList}
                        />
                    ) : null
                }
            </div>
        </div>
    );
}
