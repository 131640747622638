import React from "react";
import styles from "../TableData.module.css";
export default function NoCasesTable(props) {
    return (
        <div className={styles.NocasesDiv} style={{ height: 400 }}>
            <img src="/assets/images/NoCases/1.png" alt="No Cases" />
            <h3>Great Job !</h3>
            <p>You have no cases in the queue</p>
        </div>
    );
}
