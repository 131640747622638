/* Will display New message and is also used in draft message to edit the message*/
import React, { useEffect } from "react";
import Dropdown from "../../../@setproduct-ui/core/Dropdown";

import styles from "../BroadcastMsg.module.css";
import MenuItem from "../../../@setproduct-ui/core/Menu/MenuItem";
import Menu from "../../../@setproduct-ui/core/Menu/Menu";
import axios from "axios";
import { saveBroadcastMessage } from "../../../Adapters/BroadcastmessageCalls";
import FactoryListMenu from "../../../components/FactoryListMenu";
import notifier from "../../../utils/notifier";
import {
    success,
    loadingMessage,
    finishLoadingMessage,
} from "../../../utils/messager";
import { getAllFactoriesOfCompany } from "../../../Adapters/FactoryAndCompanyCalls";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import { message } from "antd";
import { tokenState } from "../../../RecoilState/tokenState";
import { TranslateLanguage } from "../../../Adapters/translationAPICalls";
import LoggedInComponent from "../../../containers/LoggedInComponent/LoggedInComponent";
function DynamicDropMenu({ Ddata, Lkey, handleStateChange }) {
    return (
        <Menu type="def" view="smooth" color="primary">
            {Ddata.map((item, index) => {
                return (
                    <MenuItem
                        type="def"
                        view="smooth"
                        color="primary"
                        key={index}
                        text={item}
                        onClick={() => handleStateChange(Lkey, item)}
                    />
                );
            })}
        </Menu>
    );
}
function DynamicDropMenuFac({ Ddata, Lkey, handleStateChange }) {
    return (
        <Menu type="def" view="smooth" color="primary">
            {Ddata.map((item, index) => {
                return (
                    <MenuItem
                        type="def"
                        view="smooth"
                        color="primary"
                        key={index}
                        text={item.Location}
                        onClick={() => handleStateChange(Lkey, item)}
                    />
                );
            })}
        </Menu>
    );
}
export default function NewMessage({ openedMessagedata }) {
    const [selectedLanguage, setSelectedLanguage] = React.useState(
        openedMessagedata ? openedMessagedata.Language : "English"
    );
    const [EnglishMessage, setEnglishMessage] = React.useState(
        openedMessagedata ? openedMessagedata.Englishmessage : ""
    );
    const [RegionalMessage, setRegionalMessage] = React.useState(
        openedMessagedata ? openedMessagedata.Regionalmessage : ""
    );
    const [selectedFactory, setSelectedFactory] = React.useState("");
    const [FactoryList, setFactoryList] = React.useState([]);
    const [user, setUser] = useRecoilState(userState);

    const [token, setToken] = useRecoilState(tokenState);
    function handleStateChangeinBMessage(Lkey, item) {
        if (Lkey === "Language") {
            setSelectedLanguage(item);
        } else if (Lkey === "Factory") {
            setSelectedFactory(item);
        }
    }
    function handleConvertLanguage() {
        let target;
        switch (selectedLanguage) {
            case "English":
                target = "en";
                break;
            case "Hindi":
                target = "hi";
                break;
            case "Kannada":
                target = "kn";
                break;
            default:
                target = "en";
        }
        if (target == "en") {
            setRegionalMessage(EnglishMessage);
        } else {
            TranslateLanguage(EnglishMessage, "en", target)
                .then((res) => {
                    console.log(res, "res of translation");

                    setRegionalMessage(
                        res?.data?.data?.translations[0].translatedText
                    );
                })
                .catch((err) => {
                    console.log(err, "error in translation");
                });
        }
    }
    function sendMessage() {
        saveBroadcastMessage(
            {
                RegionalLanguage: selectedLanguage,
                Englishmessage: EnglishMessage,
                Regionalmessage: RegionalMessage,
                Factory: selectedFactory.id,
            },
            false
        )
            .then((res) => {
                message.success("Message sent successfully");
            })
            .catch((err) => {
                message.error(
                    "Please try again, fill all the fields to save",
                    "Error"
                );
            });
    }

    // factory API call

    function HandleSubmit() {
        loadingMessage("updatable");
        saveBroadcastMessage({
            RegionalLanguage: selectedLanguage,
            Englishmessage: EnglishMessage,
            Regionalmessage: RegionalMessage,
            Factory: selectedFactory.id,
        })
            .then((res) => {
                finishLoadingMessage("Message Saved", "updatable");
            })
            .catch((err) => {
                message.error(
                    "Please try again, fill all the fields to save",
                    "Error"
                );
            });
    }
    return (
        <div className={styles.rightContainer}>
            <div className={styles.InputBox}>
                <h5>Select Regional Language</h5>
                <Dropdown
                    view="outlined"
                    color="primary"
                    text={selectedLanguage ? selectedLanguage : "English"}
                    content={
                        <DynamicDropMenu
                            Lkey="Language"
                            Ddata={["English", "Hindi", "Kannada"]}
                            handleStateChange={handleStateChangeinBMessage}
                        />
                    }
                    dense="false"
                />
            </div>
            <div className={styles.textArea}>
                <h5>
                    Type Message in English ( maximum of 150 Roman letters )
                </h5>
                <textarea
                    value={EnglishMessage}
                    onChange={(e) => setEnglishMessage(e.target.value)}
                    rows="5"
                    cols="50"
                />
            </div>
            <button
                type="button"
                onClick={handleConvertLanguage}
                className={styles.reverseBtn}>
                Convert Language
            </button>
            <div className={styles.textArea}>
                <h5>
                    Type Message in regional ( maximum of 150 Roman letters )
                </h5>
                <textarea
                    value={RegionalMessage}
                    onChange={(e) => setRegionalMessage(e.target.value)}
                    rows="5"
                    cols="50"
                />
            </div>
            <div className={styles.InputBox}>
                <h5>Select Factory</h5>
                <Dropdown
                    view="outlined"
                    color="primary"
                    text={
                        selectedFactory
                            ? selectedFactory.Location
                            : "Select Factory"
                    }
                    content={
                        <DynamicDropMenuFac
                            Lkey="Factory"
                            Ddata={FactoryList}
                            handleStateChange={handleStateChangeinBMessage}
                        />
                    }
                    dense="false"
                />
            </div>
            <div className={styles.finalBtns}>
                <button
                    type="button"
                    className={styles.rightBtn}
                    onClick={HandleSubmit}>
                    Save message
                </button>
                <button
                    type="button"
                    className={styles.button}
                    onClick={sendMessage}>
                    Send message
                </button>
            </div>
        </div>
    );
}
