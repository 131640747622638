import React, { useEffect, useState } from "react";
import styles from "../../IncentiveDashboard/Charts/Charts.module.css";
import { Bar } from "@ant-design/plots";
export default function ComplaintsChart({ caseData }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        caseData && setData(caseData);
    }, [caseData]);

    const config = {
        data: data,
        isStack: true,
        xField: "value",
        yField: "type",
        seriesField: "type",
        legend: { position: "top-right", marker: "9" },
        label: {
            position: "middle",
            offsetX: 5,
            layout: [
                { type: "interval-adjust-position" },

                { type: "interval-hide-overlap" },

                { type: "adjust-color" },
            ],
        },
        yAxis: {
            title: {
                text: "---------- Mode of Complaints ----------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                autoHide: false,
            },
        },
        xAxis: {
            title: {
                text: " ----------------------------- Count -----------------------------",
                style: { fontSize: 16, fill: "#868CFF" },
            },
            label: {
                autoHide: false,
            },
        },
    };
    return (
        <>
            <div
                className={styles.container + " " + styles.compliance}
                style={{ pageBreakinside: "avoid", pageBreakAfter: "always" }}
                id="compliance-chart">
                <h1>Types of Complaints</h1>
                <Bar {...config} />
            </div>
        </>
    );
}
