import { useState } from "react";
import { useRecoilState } from "recoil";
import { getUserDataAdmin } from "../../../../../Adapters/AdminCalls";
import { message } from "antd";
import { tokenState } from "../../../../../RecoilState/tokenState";
import { userState } from "../../../../../RecoilState/userState";
import { usersList } from "../../../../../RecoilState/usersList";

function groupUsersByRoles(data) {
    let groupedData = {};

    data.forEach((item) => {
        if (!groupedData[item.userId]) {
            groupedData[item.userId] = {
                username: item.username,
                Email: item.Email,
                roles: [],
            };
        }
        groupedData[item.userId].roles.push({
            id: item.roleId,
            factory_fk: item.FactoryId,
            role: item.Role,
            Code: item.Factory,
        });
    });

    return Object.values(groupedData);
}

export const useFetchUserData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [user] = useRecoilState(userState);
    const [usersListData, setUsersListData] = useRecoilState(usersList);

    function fetchUserData(isGroupByParam) {
        setIsLoading(true);
        const shouldGroupBy =
            isGroupByParam !== undefined ? isGroupByParam : false;
        // Here we are returning the promise.
        return getUserDataAdmin(
            user.role,
            user.company_fk,
            user.factory_fk,
            user.region_fk
        )
            .then((response) => {
                const jsonData = response?.data?.message_body;
                let userData = [];

                Object.values(jsonData.users).forEach((userObj) => {
                    const user = userObj.user;
                    Object.values(userObj.roles).forEach((role) => {
                        userData.push({
                            key: role.id + "-" + user.id,
                            userId: user.id,
                            roleId: role.id,
                            Factory: role.FactoryCode,
                            FactoryId: role.factory_fk,
                            Location: role.Location,
                            Role: role.role,
                            user_permissions: role.user_permissions,
                            LastModified: role.updated_at,
                            Email: user.email,
                            "Date Joined": user.date_joined,
                            username: user.user_name,
                            Creator: user.created_by,
                            Region: role.Region,
                        });
                    });
                });
                userData.sort(
                    (a, b) =>
                        new Date(b.LastModified) - new Date(a.LastModified)
                );

                setUsersListData(userData);
                setIsLoading(false);

                if (shouldGroupBy === true) {
                    const finalData = groupUsersByRoles(userData);
                    return finalData;
                } else {
                    return userData;
                }
            })
            .catch((error) => {
                message.error(error?.response?.data?.errorMessage);
                setIsLoading(false);
                throw error;
            });
    }

    return {
        fetchUserData,
        isLoading,
        userData: usersListData,
    };
};
