/* Will display Attachments of case */

import React from "react";
import DocumentViewer from "../../../components/UploadComponent/ShowDocHeaderFile";
import { Divider } from "antd";
import styles from "../CaseReport.module.css";
export default function ATTACHMENTcomponent({ caseData }) {
    const [fileList, setFileList] = React.useState(caseData?.File);
    return (
        <>
            {Object.keys(fileList)
                .reverse()
                .map((file, index) => {
                    return (
                        <div className={styles.versionAttachment}>
                            <h3> Version {file < 10 ? `0${file}` : file}</h3>
                            <div className={styles.flexRow}>
                                {fileList[file].length == 0 ? (
                                    "No Attachments available"
                                ) : (
                                    <DocumentViewer
                                        document_delete_on={false}
                                        document_download_on={false}
                                        documentIds={fileList[file]}
                                        model="case"
                                    />
                                )}
                            </div>
                            {file > 1 && (
                                <Divider className={styles.caseRepDivider} />
                            )}
                        </div>
                    );
                })}
        </>
    );
}
